<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>

  <mat-toolbar color="primary" class="app-bar">
    <img src="assets/icons/home.svg" width="24px" height="24px" style="padding-right: 8px;padding-left:8px">
    <span>Maptology</span>
    <div flexLayout="row" fxLayoutAlign="end center" fxFlex="1 1 auto">
        <app-toolbar-button [title]="res.strings('button_register')" icon="mode_edit" link="/register"></app-toolbar-button>
        <app-toolbar-button [title]="res.strings('button_login')" color="accent" icon="lock_open" link="/login"></app-toolbar-button>
    </div>
  </mat-toolbar>

  <div style="flex:1;">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

</div>