<mat-card class="mat-elevation-z0">
    <mat-card-header>
        <mat-card-title><mat-icon style="font-size: 18px;">edit</mat-icon>{{ res.strings('nickname_dialog_title') }}</mat-card-title>
        <mat-card-subtitle>{{ res.strings('nickname_dialog_subtitle') }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form #nameForm="ngForm">
            <mat-form-field [hintLabel]="res.strings('nickname_dialog_hint')" fxFlex="1 1 100%" appUseParentForm>
                <input matInput #nameField="ngModel" name="name" maxlength="140" [placeholder]="res.strings('nickname_dialog_placeholder')" (keyup.enter)="nameForm.valid && onAccept()" [(ngModel)]="name" pattern="(\w|[\u00C0-\u00FF]| ){0,140}"
                    [disabled]="isLoading()">
                <mat-hint align="end">{{nameField.value?.length || 0}} / 140</mat-hint>
                <mat-error *ngIf="nameField.invalid">
                    <ng-container [ngSwitch]="encode(nameField.errors)">
                        <ng-container *ngSwitchCase="FormErrorCode.PATTERN">{{ res.strings('nickname_dialog_pattern') }}</ng-container>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>
    <app-decision-panel 
        [positiveName]="res.strings('button_save')" 
        positiveIcon="save" 
        [negativeName]="res.strings('button_cancel')"
        negativeIcon="close"
        [processing]="isLoading()"
        [disabled]="nameForm.invalid"
        (positive)="onAccept()"
        (negative)="onClose()"></app-decision-panel>
</mat-card>