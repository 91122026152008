import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DefaultService, BuildVersion } from '../api';

/**
 * A service to download the current version
 * exactly once.
 */
@Injectable()
export class VersionService {

  private version = new BehaviorSubject<BuildVersion>(null);
  private loading = false;

  constructor(private apiClientService: DefaultService) { }

  subscribe(handler: (version: BuildVersion) => void): Subscription {
    if (!this.loading && this.version.getValue() == null) {
      this.loading = true;
      this.apiClientService.getBuildVersion().subscribe((v) => {
        this.version.next(v);
        this.loading = false;
      }, (e) => {
        this.loading = false;
      });
    }
    return this.version.subscribe(handler);
  }

}
