<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>subject</mat-icon>
            <span>Logs</span>
        </div>
        <div class="header-actions">
            <button mat-icon-button 
                [matTooltip]="res.strings('manage_logs_connect_tooltip')" 
                (click)="onConnect()" 
                [disabled]="connected || (connecting | async)">
                    <mat-icon>play_circle_outline</mat-icon>
            </button>
            <button mat-icon-button 
                [matTooltip]="res.strings('manage_logs_disconnect_tooltip')" 
                (click)="onDisconnect()" 
                [disabled]="!connected || (connecting | async)">
                    <mat-icon>pause_circle_outline</mat-icon>
            </button>
            <button mat-icon-button 
                [matTooltip]="res.strings('manage_logs_clear_tooltip')" 
                (click)="onClear()" 
                [disabled]="(connecting | async)">
                    <mat-icon>not_interested</mat-icon>
            </button>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="connecting | async">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('id')">{{ res.strings('manage_logs_id') }}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('id')"> {{element.id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('time')"> {{ res.strings('manage_logs_time') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('time')"> {{ element.time | date:'shortDate' }} {{ element.time | date:'H:mm:ss.SSS' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="thread" >
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('thread')"> {{ res.strings('manage_logs_thread') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('thread')"> {{element.thread}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="level">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('level')"> {{ res.strings('manage_logs_level') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('level')"><mat-icon [color]="getColor(element.level)" [matTooltip]="element.level">{{ getLevel(element.level) }}</mat-icon></mat-cell>
            </ng-container>
            <ng-container matColumnDef="className">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('className')"> {{ res.strings('manage_logs_class') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('className')"><div [matTooltip]="element.methodName">{{ getClassName(element.className) }}</div></mat-cell>
            </ng-container>
            <ng-container matColumnDef="methodName">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('methodName')"> {{ res.strings('manage_logs_method') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('methodName')"> {{ element.methodName }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('message')"> {{ res.strings('manage_logs_message') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('message')">{{element.message}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="trace">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('trace')"> {{ res.strings('manage_logs_trace') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"[ngStyle]="getStyle('trace')">
                        <ng-container *ngIf="element.trace != null">
                            <mat-icon color="accent" [matTooltip]="res.strings('manage_logs_trace_tooltip')">error</mat-icon>
                        </ng-container>
                    </mat-cell>
                </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('actions')" class="action-cell"> {{ res.strings('manage_logs_actions') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('actions')" class="action-cell">
                    <button mat-icon-button [matTooltip]="res.strings('manage_logs_details_tooltip')" (click)="onShow(element)" [disabled]="connecting | async">
                        <mat-icon>open_in_browser</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>
    </main>
</app-content-layout>