/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a message in a particular language.
 */
export interface Message { 
    /**
     * The message title.
     */
    title: string;
    /**
     *  The message body.
     */
    body: string;
    /**
     * The two letter language code in lower case.
     */
    language?: Message.LanguageEnum;
}
export namespace Message {
    export type LanguageEnum = 'de' | 'en' | 'fr' | 'it' | 'es';
    export const LanguageEnum = {
        De: 'de' as LanguageEnum,
        En: 'en' as LanguageEnum,
        Fr: 'fr' as LanguageEnum,
        It: 'it' as LanguageEnum,
        Es: 'es' as LanguageEnum
    };
}
