<svg [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox">

    <!-- X-Axis -->
    <g *ngIf="xAxis">
      <polyline class="axis" [attr.id]="xAxis.id" [attr.points]="xAxis.axisPolyline" />

      <polyline class="grid" *ngFor="let points of xAxis.gridPolylines" [attr.points]="points"/>

      <text *ngFor="let label of xAxis.axisLineLabels" 
            [attr.x]="label.x" [attr.y]="label.y" 
            [attr.font-size]="label.fontSize" 
            [attr.fill]="label.fill"
            [attr.text-anchor]="xtextAnchor">{{ label }}</text>

      <text [attr.x]="xAxis.axisLabel.x" [attr.y]="xAxis.axisLabel.y" 
            [attr.font-size]="xAxis.axisLabel.fontSize" 
            [attr.fill]="xAxis.axisLabel.fill"
            text-anchor="end">{{ xAxis.axisLabel }}</text>
    </g>

    <!-- Y-Axis -->
    <g *ngIf="yAxis">
      <polyline class="axis" [attr.id]="yAxis.id" [attr.points]="yAxis.axisPolyline" />

      <polyline class="grid" *ngFor="let points of yAxis.gridPolylines" [attr.points]="points"/>

      <text *ngFor="let label of yAxis.axisLineLabels" 
            [attr.x]="label.x" [attr.y]="label.y" 
            [attr.font-size]="label.fontSize" 
            [attr.fill]="label.fill"
            [attr.text-anchor]="ytextAnchor">{{ label }}</text>

      <text [attr.x]="yAxis.axisLabel.x" [attr.y]="yAxis.axisLabel.y" 
            [attr.font-size]="yAxis.axisLabel.fontSize" 
            [attr.fill]="yAxis.axisLabel.fill"
            text-anchor="middle">{{ yAxis.axisLabel }}</text>
    </g>

    <!-- ChartLines -->
    <polyline *ngFor="let line of chartLines" [attr.id]="line.id" [attr.points]="line" [ngStyle]="line.pointsStyle"/>
</svg>
<div *ngIf="chartLines.length > 0"  class="flex-container" [ngStyle]="getLegendWidth()">
      <p *ngFor="let line of chartLines" [ngStyle]="line.labelStyle">{{ line.label }}</p>
</div>