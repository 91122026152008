import {Component, ViewChild, OnInit, OnDestroy, ElementRef, HostListener, ContentChild, DoCheck} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notice, NoticePage } from '../api';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { PaginationHandler } from '../utils/pagination';
import { Snackbars } from '../utils/snackbars';
import { FormErrorEncoder } from '../utils/formcodes';
import { ManageNoticeService } from '../services/manage-notice.service';
import { TableHandler, TableColumn } from '../utils/tables';
import { ResourceService, Language } from '../services/resource.service';

@Component({
  selector: 'app-manage-notices',
  templateUrl: 'manage-notices.component.html'
})
export class ManageNoticesComponent extends FormErrorEncoder implements OnInit, OnDestroy, DoCheck {


  private static COLUMNS: TableColumn[] = [
    { name: 'id', dropPriority: 6, width: 120, fixedWidth: true},
    { name: 'published', dropPriority: 1, width: 55, fixedWidth: true},
    { name: 'announced', dropPriority: 1, width: 110, fixedWidth: true},
    { name: 'languages', dropPriority: 2, width: 140, fixedWidth: true},
    { name: 'title', dropPriority: 0, width: 350},
    { name: 'creationTime', dropPriority: 4, width: 130, fixedWidth: true},
    { name: 'expirationTime', dropPriority: 3, width: 130 , fixedWidth: true},
    { name: 'modificationTime', dropPriority: 5, width: 130 , fixedWidth: true},
    { name: 'actions', dropPriority: 0, width: 90, fixedWidth: true }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;

  language: string;

  readonly languages: Language[];
  readonly dataSource: DataSource<Notice>;
  readonly loading: BehaviorSubject<boolean>;

  private tableHandler: TableHandler;
  private pageHandler: PaginationHandler<Notice, NoticePage>;

  constructor(
      public res: ResourceService,
      private mangeNoticeService: ManageNoticeService,
      private snackbar: MatSnackBar) {
        super();
    this.dataSource = mangeNoticeService;
    this.loading = mangeNoticeService.loading;
    this.languages = res.languages;
    this.language = res.strings('language');
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  ngDoCheck(): void {
    this.tableHandler.onCheck();
  }

  ngOnInit() {
    this.tableHandler = new TableHandler(this.table, ManageNoticesComponent.COLUMNS, null);
    this.pageHandler = new PaginationHandler(this.paginator, this.mangeNoticeService);
    this.mangeNoticeService.reload();
  }

  ngOnDestroy() {
    this.pageHandler.destroy();
  }

  getTitle(notice: Notice): string {
    for (const m of notice.messages) {
      if (m.language === this.language) {
        return m.title;
      }
    }
    return '';
  }

  hasLanguage(notice: Notice, lang: string) {
    for (const m of notice.messages) {
      if (m.language === lang) { return true; }
    }
    return false;
  }


  onDelete(notice: Notice): void {
    if (this.loading.getValue()) { return; }
    notice.deleted = true;
    this.updateNotice(notice);
  }

  onRestore(notice: Notice): void {
    if (this.loading.getValue()) { return; }
    notice.deleted = false;
    this.updateNotice(notice);
  }

  private updateNotice(notice: Notice) {
    this.mangeNoticeService.setNotice(notice).subscribe((result) => {
      if (result != null) {
        Snackbars.showMessage(this.snackbar, this.res.strings('manage_notices_change_success') , Snackbars.DURATION_SHORT);
      } else {
        Snackbars.showError(this.snackbar, this.res.strings('manage_notices_change_error'));
      }
    });

  }

}
