<app-content-layout *ngIf="idle | async;else progressBar">
    <app-content-header [headerClass]="'header-transparent'">
        <div class="header-title">
            <mat-icon>perm_identity</mat-icon>
            <span>{{ res.strings('user_title') }}</span>
        </div>
    </app-content-header>

    <main *ngIf="user | async as u;else progressBar" class="flex-row-wrap justify-space-evenly">
        <mat-card class="mat-typography" class="user-card">
            <mat-card-title-group>
                <mat-card-title>{{ res.strings('user_change_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('user_change_subtitle') }}</mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                <form #userForm="ngForm">
                    <app-name-field [name]="u.name" (keyup.enter)="userForm.valid && onChangeUser()"></app-name-field>
                    <mat-form-field style="width: 100%;">
                        <mat-select [placeholder]="res.strings('user_change_primary')" name="email" [(ngModel)]="correspondence" style="width: 100%;">
                            <mat-select-trigger>
                                {{ correspondence.address }}
                            </mat-select-trigger>
                            <ng-container *ngFor="let email of emails">
                                <mat-option [value]="email" [disabled]="!email.verified">
                                    <mat-icon class="icon-button">{{ email.verified?"mail":"send" }}</mat-icon>{{email.address}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions class="flex-vrt-center">
                <button #loginButton mat-raised-button color="primary" [disabled]="userForm.pristine || userForm.invalid" (click)="onChangeUser()">
                    <mat-icon class="icon-button">save</mat-icon>
                    <span>{{ res.strings('button_save') }}</span>
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="mat-typography" class="user-card">
            <mat-card-title>{{ res.strings('user_password_title') }}</mat-card-title>
            <mat-card-subtitle>{{ res.strings('user_password_subtitle') }}</mat-card-subtitle>
            <mat-card-content class="caption" style="flex:1">
                <form #passwordForm="ngForm">
                    <app-password-field #oldPassword name="oldPassword" [label]="res.strings('user_password_old')" [hint]="res.strings('user_password_old_hint')"></app-password-field>
                    <app-password-field #newPassword name="newPassword" [label]="res.strings('user_password_new')" [hint]="res.strings('user_password_new_hint')" (keyup.enter)="passwordForm.valid && onChangePassword()"></app-password-field>
                </form>
            </mat-card-content>
            <mat-card-actions class="flex-vrt-center">
                <button mat-raised-button color="primary" (click)="onChangePassword()" [disabled]="passwordForm.pristine || passwordForm.invalid">
                    <mat-icon class="icon-button">lock_outline</mat-icon>
                    <span>{{ res.strings('button_change') }}</span>
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="mat-typography" class="user-card">
            <mat-card-title>{{ res.strings('user_add_email_title') }}</mat-card-title>
            <mat-card-subtitle>{{ res.strings('user_add_email_subtitle') }}</mat-card-subtitle>
            <mat-card-content class="caption" style="flex:1">
                <form #emailForm="ngForm">
                    <app-email-field (keyup.enter)="emailForm.valid && onAddEmail()"></app-email-field>
                </form>
                <div style="height:70px"></div>
            </mat-card-content>
            <mat-card-actions class="flex-vrt-center">
                <button mat-raised-button color="primary" (click)="onAddEmail()" [disabled]="emailForm.pristine || emailForm.invalid">
                    <mat-icon class="icon-button">add</mat-icon>
                    <span>{{ res.strings('button_add') }}</span>
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="mat-typography" class="user-card">
            <mat-card-title>{{ res.strings('user_del_email_title') }}</mat-card-title>
            <mat-card-subtitle>{{ res.strings('user_del_email_subtitle') }}</mat-card-subtitle>
            <mat-card-content class="caption" style="flex:1">
                <mat-list class="mat-typography">
                    <ng-container *ngFor="let e of emails">
                        <ng-container *ngIf="!e.correspondence">
                            <mat-list-item>
                                <button mat-icon-button [matTooltip]="res.strings('user_del_email_tooltip')" (click)="onDeleteEmail(e.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>{{ e.address }}</mat-list-item>
                        </ng-container>
                    </ng-container>
                </mat-list>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-typography" class="user-card">
            <mat-card-title>{{ res.strings('user_delete_title') }}</mat-card-title>
            <mat-card-subtitle>{{ res.strings('user_delete_subtitle') }}</mat-card-subtitle>
            <mat-card-content class="caption" style="flex:1">
                <form #deleteForm="ngForm">
                    <app-password-field #deletePassword name="deletePassword" [label]="res.strings('user_delete_password')" [hint]="res.strings('user_delete_password_hint')" (keyup.enter)="deleteForm.valid && onDeleteUser()"></app-password-field>
                </form>
                <div style="height:70px"></div>
            </mat-card-content>
            <mat-card-actions class="flex-vrt-center">
                <button mat-raised-button color="primary" (click)="onDeleteUser()" [disabled]="deleteForm.pristine || deleteForm.invalid">
                    <mat-icon class="icon-button">delete</mat-icon>
                    <span>{{ res.strings('button_delete') }}</span>
                </button>
            </mat-card-actions>
        </mat-card>
    </main>
</app-content-layout>
<ng-template #progressBar>
    <div fxFlexFill fxLayoutAlign="space-around center">
        <mat-spinner [diameter]="100"></mat-spinner>
    </div>
</ng-template>