import { Component, Input } from '@angular/core';
import { FormErrorEncoder } from '../utils/formcodes';
import { ResourceService } from '../services/resource.service';

/**
 * A validated field that enables a user to enter the
 * login name. The name will be available through the
 * components login which also acts as an input.
 */
@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html'
})
export class PasswordFieldComponent extends FormErrorEncoder {

  /**
   * Hide or show the password.
   */
  hide = true;

  /**
   * The password bound to the component.
   */
  @Input() password: string;
  /**
   * Disables the field.
   */
  @Input() disabled: boolean;

  /**
   * The name of the field
   */
  @Input() name = 'password';

  /**
   * The label for the field.
   */
  @Input() label: string;

  /**
   * The hint for the field.
   */
  @Input() hint: string;


  constructor(public res: ResourceService) {
    super();
    this.label = res.strings('password_field_placeholder');
    this.hint = res.strings('password_field_hint');
  }

}
