import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ResourceService, Language } from '../services/resource.service';

@Component({
    selector: 'app-language-select',
    templateUrl: 'language-select.component.html',
  })
export class LanguageSelectComponent {

    @Input() languages: Language[];

    @Input() value: string;

    @Output() change: EventEmitter<string> = new EventEmitter();

    constructor(public res: ResourceService) {
        this.languages = res.languages;
        this.value = res.strings('language');
    }
}
