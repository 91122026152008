import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Snackbars } from '../utils/snackbars';
import { FormErrorEncoder } from '../utils/formcodes';
import { DefaultService, Place } from '../api';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '../utils/httpcodes';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-nickname-dialog',
    templateUrl: 'nickname-dialog.component.html',
  })
  export class NicknameDialogComponent extends FormErrorEncoder {

    @ViewChild('nameForm', { static: true }) nameForm: NgForm;

    name: String;
    private place: Place;
    private subscription: Subscription;

    public static open(dialog: MatDialog, data: Place, w?: string): Observable<boolean> {
      if (w == null) { w = '450px'; }
      const ref = dialog.open(NicknameDialogComponent, {
          data: data,
          width: w
      });
      return ref.afterClosed();
    }

    constructor(
      public snackbar: MatSnackBar,
      public apiClientService: DefaultService,
      public dialog: MatDialogRef<any, boolean>,
      @Inject(MAT_DIALOG_DATA) data: Place,
      public res: ResourceService
    ) {
      super();
      this.place = data;
      this.name = data.nickname;
     }

    isLoading(): boolean {
      return this.subscription != null;
    }

    onClose(): void {
      this.dialog.close(false);
    }

    onAccept(): void {
      if (this.nameForm.invalid || this.subscription) { return; }
      this.place.nickname = this.name.trim();
      this.subscription = this.apiClientService.setPlace(this.place.id, this.place).subscribe((result) => {
        this.subscription = null;
        this.dialog.close(true);
        Snackbars.showMessage(this.snackbar, this.res.strings('nickname_dialog_success'), Snackbars.DURATION_SHORT);
      }, (error) => {
        this.subscription = null;
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.CONFLICT && error.error) {
            this.place = JSON.parse(error.error) as Place;
            this.name = this.place.nickname;
            Snackbars.showError(this.snackbar, this.res.strings('nickname_dialog_conflict'));
            return;
          }
        }
        Snackbars.showError(this.snackbar, this.res.strings('nickname_dialog_failure'));
        this.dialog.close(true);
      });
    }

  }
