import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import * as L from 'leaflet';
import { TileMapComponent } from '../widgets/tile-map.component';

@Component({
    selector: 'app-marker-map',
    templateUrl: './marker-map.component.html'
})
export class MarkerMapComponent implements AfterViewInit {

    @ViewChild(TileMapComponent, { static: true }) map: TileMapComponent;
    @ViewChild('overlay', { read: ElementRef, static: true }) overlaySpace: ElementRef;
    @ViewChild('tile', { read: ElementRef, static: true }) tileSpace: ElementRef;

    private marker: L.Marker;
    private markerPosition: { latitude: number, longitude: number };
    private pin: L.Marker;
    private pinPosition: { latitude: number, longitude: number};

    constructor() { }

    getWidth(): number {
        return this.map.getWidth();
    }

    getHeight(): number {
        return this.map.getHeight();
    }

    ngAfterViewInit() {
        this.marker = new L.Marker(new L.LatLng(0, 0), { icon: this.map.iconNormal });
        this.updateMarker();
        this.pin = new L.Marker(new L.LatLng(0, 0), { icon: this.map.iconPin });
        this.updatePin();
    }

    setMarkerPosition(position: { latitude: number, longitude: number }) {
        this.markerPosition = position;
        this.updateMarker();
    }

    private updateMarker() {
        if (!this.marker) { return; }
        if (this.markerPosition == null) {
            this.marker.remove();
        } else {
            const c = new L.LatLng(this.markerPosition.latitude, this.markerPosition.longitude);
            this.marker.setLatLng(c);
            this.marker.addTo(this.map.map);
            this.centerPoint(c);
        }
    }

    setPinPosition(position: { latitude: number, longitude: number }) {
        this.pinPosition = position;
        this.updatePin();
    }

    private updatePin() {
        if (!this.pin) { return; }
        if (this.pinPosition == null) {
            this.pin.remove();
        } else {
            const c = new L.LatLng(this.pinPosition.latitude, this.pinPosition.longitude);
            this.pin.setLatLng(c);
            this.pin.addTo(this.map.map);
            this.centerPoint(c);
        }
    }

    centerPoint(latLng: L.LatLng): void {
        // hacky workaround for bug in leaflet
        this.map.map.invalidateSize();
        this.centerBounds([ [latLng.lat, latLng.lng] ], 16);
    }

    centerBounds(latLngExp: L.LatLngBoundsExpression, maxZoom?: number): void {
        // hacky workaround for bug in leaflet
        this.map.map.invalidateSize();
        const overlayWidth = this.overlaySpace.nativeElement.clientWidth;
        const tileWidth = this.tileSpace.nativeElement.clientWidth;
        const freeWidth = tileWidth - overlayWidth;
        let padding = 0;
        if (freeWidth > 100) {
            padding = overlayWidth;
        }
        this.map.map.fitBounds(latLngExp, {
            paddingBottomRight: [ padding, 0],
            maxZoom: maxZoom
        });
    }



}
