import { MatPaginatorIntl } from '@angular/material/paginator';
import { ResourceService } from '../services/resource.service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class GermanPaginatorProvider extends MatPaginatorIntl {

    constructor(@Inject(ResourceService) public res: ResourceService) {
        super();
        this.firstPageLabel = res.strings('pagination_first_page');
        this.lastPageLabel = res.strings('pagination_last_page');
        this.nextPageLabel = res.strings('pagination_next_page');
        this.previousPageLabel = res.strings('pagination_previous_page');
        this.itemsPerPageLabel = res.strings('pagination_items_per_page');
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const labelOf = this.res.strings('pagination_of');
        if (length === 0 || pageSize === 0) { return '0 ' + labelOf + ' ' + length; }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + labelOf + ' ' + length;
    }
}
