import { Injectable } from '@angular/core';
import { AbstractPageService } from '../utils/pagination';
import { DefaultService, NoticePage, Notice } from '../api';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

/**
 * A service that handles notices for admins.
 */
@Injectable()
export class ManageNoticeService extends AbstractPageService<Notice, NoticePage> {

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
    }

    protected loadPage(offset: number, size: number): Observable<NoticePage> {
        return this.apiClientService.getNotices(offset, size);
    }

    setNotice(notice: Notice): Observable<Notice> {
        if (this.loading.getValue()) { return new BehaviorSubject<Notice>(null); }
        this.loading.next(true);
        let call: Observable<Notice>;
        if (notice.id === 0) {
            call = this.apiClientService.addNotice(notice);
        } else {
            call = this.apiClientService.setNotice(notice.id, notice);
        }
        const result = new Subject<Notice>();
        call.subscribe((response) => {
            this.loading.next(false);
            result.next(response);
            this.reload();
        }, (error) => {
            this.loading.next(false);
            result.next(null);
            this.reload();
        });
        return result;
    }

    getNotice(id: number): Observable<Notice> {
        if (this.loading.getValue()) { return new BehaviorSubject<Notice>(null); }
        this.loading.next(true);
        const result = new Subject<Notice>();
        this.apiClientService.getNotice(id).subscribe((response) => {
            result.next(response);
            this.loading.next(false);
        }, (error) => {
            result.next(null);
            this.loading.next(false);
        });
        return result;
    }
}
