import { Component } from '@angular/core';
import { ResourceService } from '../services/resource.service';

/**
 * The responsive footer of the web application
 * that contains a couple of links.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  constructor(public res: ResourceService) {

  }

}
