<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>share</mat-icon>
            <span>{{ res.strings('privileges_title') }}</span>
        </div>
        <div class="header-actions">
            <button mat-icon-button 
                [matTooltip]="res.strings('privileges_add_tooltip')" 
                (click)="onAdd()" 
                [disabled]="loading.getValue() || deleting.getValue()">
                    <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="loading.getValue() || deleting.getValue()">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>

        <mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('id')" > {{ res.strings('privileges_id') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('id')" > {{element.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('name')" > {{ res.strings('privileges_name') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('name')" > {{ getName(element) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="token">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('token')" > {{ res.strings('privileges_token') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" style="font-family: Roboto Mono;font-size: 12px" [ngStyle]="getStyle('token')" > {{element.token}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('creationTime')" > {{ res.strings('privileges_creation') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('creationTime')" > {{element.creationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('message')" > {{ res.strings('privileges_message') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('message')" > {{element.shareInfo.message}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('destination')" > {{ res.strings('privileges_destination') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('destination')" > {{element.shareInfo.destination}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expirationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('expirationTime')" > {{ res.strings('privileges_expiration') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('expirationTime')" > {{element.shareInfo.expirationTime | date:'short'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('actions')" class="action-cell"> {{ res.strings('privileges_actions') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('actions')" class="action-cell">
                    <button mat-icon-button [matTooltip]="res.strings('privileges_show_tooltip')" [routerLink]="element.token" [disabled]="loading.getValue() || deleting.getValue()">
                        <mat-icon>my_location</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="res.strings('privileges_delete_tooltip')" (click)="onDelete(element.id)" [disabled]="loading.getValue() || deleting.getValue()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
        </mat-paginator>
    </main>
</app-content-layout>