import {Injectable, Optional, Inject} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ConsoleService } from '../services/console.service';
import { Injector } from '@angular/core';
import { CredentialService } from '../services/credential.service';

/**
 * This interceptor intercepts http requests and attaches a
 * basic auth header to them as long as the auth service is
 * issuing them.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    /**
     * Creates a new interceptor with the injected console service
     * and credentials service.
     *
     * @param consoleService The console service for logging.
     * @param credentialService The credential service to create auth headers.
     */
    constructor(private consoleService: ConsoleService, private credentialService: CredentialService) {  }

    /**
     * Intercepts the requests and possibly attaches an auth header.
     *
     * @param req The request that is intercepted.
     * @param next The handler to continue the request.
     */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.credentialService.createHeader();
    let clone = req;
    if (authHeader != null) {
        this.consoleService.log('Attaching Authorization header.');
        clone = req.clone({ headers: req.headers.set('Authorization', authHeader)});
    } else {
        this.consoleService.log('Skipping Authorization header.');
    }
    return next.handle(clone);
  }

}
