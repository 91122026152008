<div style="margin-top:15px">
<form #langForm="ngForm" style="font-size: 12px;" fxLayoutAlign="center center" flexLayout="row">
    <mat-form-field fxFlex="1 1 100px">
        <mat-select [placeholder]="res.strings('language_select_placeholder')" [(value)]="value" (change)="change.emit(value)">
            <mat-option *ngFor="let lang of languages" [value]="lang.value">
                <img [src]='lang.flag' height="12px">
                <span>{{ lang.title }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
</div>