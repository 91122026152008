import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DefaultService } from '../api';
import { Snackbars } from '../utils/snackbars';
import { FormErrorEncoder } from '../utils/formcodes';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-share-dialog',
    templateUrl: 'share-dialog.component.html',
  })
  export class ShareDialogComponent extends FormErrorEncoder {

    @ViewChild('shareForm', { static: true }) shareForm: NgForm;

    link: String;
    private subscription: Subscription;

    public static open(dialog: MatDialog, link?: string, w?: string): Observable<boolean> {
      const ref = dialog.open(ShareDialogComponent, {
          data: (link) ? link : '',
          width: w
      });
      return ref.afterClosed();
    }

    constructor(
      public snackbar: MatSnackBar,
      public apiClientService: DefaultService,
      public dialog: MatDialogRef<any, boolean>,
      @Inject(MAT_DIALOG_DATA) data: string,
      public res: ResourceService
    ) {
      super();
      this.link = data;
     }

    isLoading(): boolean {
      return this.subscription != null;
    }

    onClose(): void {
      this.dialog.close(false);
    }

    onAccept(): void {
      if (this.shareForm.invalid || this.subscription) { return; }
      let token = this.link.trim();
      const idx = token.lastIndexOf('/');
      if (idx !== -1) { token = token.substring(idx + 1); }
      this.subscription = this.apiClientService.addCurrentUserPrivilege(token).subscribe((result) => {
        this.subscription = null;
        this.dialog.close(true);
        Snackbars.showMessage(this.snackbar, this.res.strings('share_dialog_success'), Snackbars.DURATION_SHORT);
      }, (error) => {
        this.subscription = null;
        Snackbars.showError(this.snackbar, this.res.strings('share_dialog_error'));
      });
    }

  }
