<div style="flex: 1 1 100%;">
    <mat-toolbar style="margin-top:20px;background:transparent">
        <mat-icon style="margin-left:20px;">equalizer</mat-icon>
        <span style="margin-left: 10px">{{ res.strings('manage_system_title') }}</span>
    </mat-toolbar>

    <mat-grid-list [cols]="columns" gutterSize="10" rowHeight="460" class="grid-layout">
        <mat-grid-tile>
            <mat-card class="mat-typography system-card">
                <mat-card-title-group>
                    <mat-card-title>{{ res.strings('manage_system_load_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('manage_system_load_subtitle') }}</mat-card-subtitle>
                </mat-card-title-group>
                <mat-card-content>
                    <ng-container *ngIf="canGetCpuLoad();else progressBar">
                        <div style="text-align: center">
                            <ngx-gauge size="300" type="arch" [value]="getCpuLoad()" label="" append="%" cap="flat" thick="20" min="0" max="100" foregroundColor="#004c93"
                                duration="500"></ngx-gauge>
                        </div>
                    </ng-container>
                    <mat-card-footer>
                        <ng-container *ngIf="load != null">
                            <div style="text-align: center" class="mat-typography">
                                <span class="detail-label">{{ res.strings('manage_system_uptime') }}</span>
                                <span class="detail-value">{{ getUptime(load.uptime) }}</span>
                            </div>
                        </ng-container>
                    </mat-card-footer>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="mat-typography system-card">
                <mat-card-title>{{ res.strings('manage_system_memory_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('manage_system_memory_subtitle') }}</mat-card-subtitle>
                <mat-card-content class="caption" style="flex:1">
                    <ng-container *ngIf="(load != null);else progressBar">
                        <div style="text-align: center">
                            <ngx-gauge size="300" type="arch" [value]="(load.usedHeapSize / load.maxHeapSize) * 100" label="" append="%" cap="flat" thick="20"
                                min="0" max="100" foregroundColor="#004c93" duration="500"></ngx-gauge>
                        </div>
                    </ng-container>
                    <mat-card-footer>
                        <ng-container *ngIf="load != null">
                            <div style="text-align: center" class="mat-typography">
                                <span class="detail-label">{{ res.strings('manage_system_max_heap') }}</span>
                                <span class="detail-value">{{ getMemory(load.maxHeapSize) }}</span>
                            </div>
                        </ng-container>
                    </mat-card-footer>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="columns" gutterSize="10" rowHeight="900" class="grid-layout">
        <mat-grid-tile>
            <mat-card class="mat-typography system-card">
                <mat-card-title-group>
                    <mat-card-title>{{ res.strings('manage_system_equipment_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('manage_system_equipment_subtitle') }}</mat-card-subtitle>
                </mat-card-title-group>
                <mat-card-content>
                    <ng-container *ngIf="(system != null);else progressBar">
                        <mat-list>
                            <h3 matSubheader>
                                <mat-icon class="icon-button">computer</mat-icon>{{ res.strings('manage_system_os') }}</h3>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_os_name') }}</span>
                                <span class="detail-value">{{ system.osName }}</span>
                                <mat-divider [inset]="true"></mat-divider>
                            </mat-list-item>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_os_architecture') }}</span>
                                <span class="detail-value">{{ system.osArchitecture }}</span>
                                <mat-divider [inset]="true"></mat-divider>
                            </mat-list-item>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_os_version') }}</span>
                                <span class="detail-value">{{ system.osVersion }}</span>
                            </mat-list-item>
                            <h3 matSubheader>
                                <mat-icon class="icon-button">widgets</mat-icon>{{ res.strings('manage_system_jvm') }}</h3>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_jvm_name') }}</span>
                                <span class="detail-value">{{ system.vmName }}</span>
                                <mat-divider [inset]="true"></mat-divider>
                            </mat-list-item>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_jvm_specification') }}</span>
                                <span class="detail-value">{{ system.vmSpecification }}</span>
                                <mat-divider [inset]="true"></mat-divider>
                            </mat-list-item>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_jvm_vendor') }}</span>
                                <span class="detail-value">{{ system.vmVendor }}</span>
                                <mat-divider [inset]="true"></mat-divider>
                            </mat-list-item>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_jvm_version') }}</span>
                                <span class="detail-value">{{ system.vmVersion }}</span>
                            </mat-list-item>
                            <ng-container *ngIf="load != null">
                                <h3 matSubheader>
                                    <mat-icon class="icon-button">delete</mat-icon>{{ res.strings('manage_system_gc') }}</h3>
                                <mat-list-item>
                                    <span class="detail-label">{{ res.strings('manage_system_gc_count') }}</span>
                                    <span class="detail-value">{{ load.gcCount }}</span>
                                    <mat-divider [inset]="true"></mat-divider>
                                </mat-list-item>
                                <mat-list-item>
                                    <span class="detail-label">{{ res.strings('manage_system_gc_duration') }}</span>
                                    <span class="detail-value">{{ load.gcDuration }} ms</span>
                                </mat-list-item>
                            </ng-container>
                            <h3 matSubheader>
                                <mat-icon class="icon-button">power_settings_new</mat-icon>{{ res.strings('manage_system_power') }}</h3>
                            <mat-list-item>
                                <span class="detail-label">{{ res.strings('manage_system_processors') }}</span>
                                <span class="detail-value">{{ system.processors }}</span>
                            </mat-list-item>
                        </mat-list>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="mat-typography system-card">
                <mat-card-title>{{ res.strings('manage_system_data_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('manage_system_data_subtitle') }}</mat-card-subtitle>
                <mat-card-content class="caption" style="flex:1">
                    <ng-container *ngIf="hasEntities()">
                        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                    </ng-container>
                    <mat-table #table [dataSource]="entities">

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> {{ res.strings('manage_system_data_entity') }} </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="mat-caption"> {{element.name}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef>
                                <div style="text-align: right">{{ res.strings('manage_system_data_sum') }}</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div style="text-align: right">{{ element.total }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="active">
                            <mat-header-cell *matHeaderCellDef>
                                <div style="text-align: right">{{ res.strings('manage_system_data_active') }}</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div style="text-align: right">{{ element.total - element.deleted }}</div>
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="deleted">
                            <mat-header-cell *matHeaderCellDef>
                                <div style="text-align: right">{{ res.strings('manage_system_data_deleted') }}</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div style="text-align: right">{{ element.deleted }}</div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="['name', 'active', 'deleted', 'total']"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: ['name', 'active', 'deleted', 'total'];"></mat-row>
                    </mat-table>

                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
    <ng-template #progressBar>
        <div fxLayoutAlign="space-around center" style="margin-top:10px">
            <mat-spinner [diameter]="100"></mat-spinner>
        </div>
    </ng-template>
</div>