import { Component, ViewChild, OnInit, OnDestroy, DoCheck, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrivilegeService } from '../services/privilege.service';
import { DefaultService, Privilege, PrivilegePage } from '../api';
import { DataSource } from '@angular/cdk/table';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PaginationHandler } from '../utils/pagination';
import { ShareDialogComponent } from '../widgets/share-dialog.component';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';
import { Snackbars } from '../utils/snackbars';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TableColumn, TableHandler } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-privileges',
  templateUrl: 'privileges.component.html'
})
export class PrivilegesComponent implements OnInit, OnDestroy, DoCheck {

  private static COLUMNS: TableColumn[] = [
    { name: 'name', dropPriority: 0, width: 100},
    { name: 'token', dropPriority: 2, width: 180, fixedWidth: true },
    { name: 'message', dropPriority: 0, width: 150},
    { name: 'destination', dropPriority: 1, width: 150 },
    { name: 'expirationTime', dropPriority: 3, width: 130, fixedWidth: true },
    { name: 'actions', dropPriority: 0, width: 90, fixedWidth: true }
  ];

  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: DataSource<Privilege>;
  private pageHandler: PaginationHandler<Privilege, PrivilegePage>;
  readonly deleting = new BehaviorSubject<boolean>(false);
  readonly loading: BehaviorSubject<boolean>;

  private addSubscription: Subscription;
  private tableHandler: TableHandler;

  constructor(
    public res: ResourceService,
    private apiClientService: DefaultService,
    private privilegeService: PrivilegeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.loading = privilegeService.loading;
    this.dataSource = privilegeService;
  }


  ngDoCheck(): void {
    this.tableHandler.onCheck();
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  getName(privilege: Privilege) {
    console.dir(privilege);
    if (privilege == null || privilege.shareInfo == null || privilege.shareInfo.name == null) {
        return this.res.strings('privileges_nobody');
    } else {
      return privilege.shareInfo.name;
    }

  }

  ngOnInit() {
    this.tableHandler = new TableHandler(this.table, PrivilegesComponent.COLUMNS);
    const token = this.activatedRoute.snapshot.paramMap.get('add');
    this.pageHandler = new PaginationHandler(this.paginator, this.privilegeService);
    this.privilegeService.reload();
    if (token != null) {
      this.router.navigate(['/privileges'], { replaceUrl: true });
      this.addSubscription = this.privilegeService.loading.subscribe((value) => {
        if (!value) {
          this.onAdd(token);
          this.addSubscription.unsubscribe();
          this.addSubscription = null;
        }
      });
    }

  }

  ngOnDestroy() {
    this.pageHandler.destroy();
    if (this.addSubscription) { this.addSubscription.unsubscribe(); }
  }

  onAdd(link?: string): void {
    ShareDialogComponent.open(this.dialog, link).subscribe((result) => {
      if (result) {
        this.privilegeService.reload();
      }
    });
  }

  onDelete(id: number): void {
    if (this.loading.getValue() || this.deleting.getValue()) { return; }
    ConfirmationDialogComponent.open(this.dialog, {
      title: this.res.strings('privileges_delete_confirm_title'),
      message: this.res.strings('privileges_delete_confirm_message'),
      positive: this.res.strings('button_yes'),
      negative: this.res.strings('button_no')
    }).subscribe((result) => {
      if (result != null && result.confirmed) {
        this.doDelete(id);
      }
    });
  }

  private doDelete(id: number) {
    this.deleting.next(true);
    this.apiClientService.deletePrivilege(id).subscribe((result) => {
      this.deleting.next(false);
      this.privilegeService.reload();
      Snackbars.showMessage(this.snackbar, this.res.strings('privileges_delete_success'), Snackbars.DURATION_SHORT);
    }, (error) => {
      this.deleting.next(false);
      this.privilegeService.reload();
      Snackbars.showError(this.snackbar, this.res.strings('privileges_delete_error'));
    });
  }



}
