import { Directive } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

/**
 * Angular's template driven forms do not allow composition
 * and the fallback of going to reactive froms is too
 * complicated for enabling trivial form reuse of validation
 * logic.
 *
 * This directive, taken from the github page at
 * https://github.com/angular/angular/issues/9600
 * provides a clean and simple solution.
 *
 * The only thing that is required is to add
 * useParentForm to our children and they will
 * properly attach to any enclosing ng form.
 *
 * @author Marcus
 */
@Directive({
    selector: '[appUseParentForm]',
    providers: [
        {
            provide: ControlContainer,
            useFactory: function(form: NgForm): NgForm {
                return form;
            },
            deps: [NgForm]
        }
    ]
})
export class UseParentFormDirective {}
