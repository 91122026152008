<app-marker-map>
    <mat-accordion class="sidebar">
        <mat-expansion-panel class="mat-typography" style="width:280px;margin-right:10px;margin-top:10px">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">smartphone</mat-icon>{{ res.strings('device_location_device') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="device | async as d">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_manufacturer') }}</span>
                    <span class="detail-value">{{ d.manufacturer }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_model') }}</span>
                    <span class="detail-value">{{ d.model }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_os') }}</span>
                    <span class="detail-value">{{ d.os }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_creation') }}</span>
                    <span class="detail-value">{{ d.creationTime | date:'short' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_modification') }}</span>
                    <span class="detail-value">{{ d.modificationTime | date:'short' }}</span>
                </div>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!isUpdatingDevice();else progressBarDevice">
                    <button mat-raised-button color="primary" (click)="updateDevice()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarDevice>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-typography" style="width:280px;margin-right:10px;margin-bottom:10px">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">my_location</mat-icon>{{ res.strings('device_location_location') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="location | async as l">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_time') }}</span>
                    <span class="detail-value">{{ l.timestamp | date:'short' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('device_location_availability') }}</span>
                    <span class="detail-value">{{ l.availability }}</span>
                </div>
                <ng-container *ngIf="l.coordinate">
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('device_location_longitude') }}</span>
                        <span class="detail-value" style="font-family: Roboto Mono">{{ l.coordinate.longitude | number:'1.5-5' }}</span>
                    </div>
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('device_location_latitude') }}</span>
                        <span class="detail-value" style="font-family: Roboto Mono">{{ l.coordinate.latitude | number:'1.5-5' }}</span>
                    </div>
                </ng-container>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!isUpdatingLocation();else progressBarLocation">
                    <button mat-raised-button color="primary" (click)="updateLocation()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarLocation>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</app-marker-map>