import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultService, Notice, NoticePage } from '../api';
import { AbstractPageService } from '../utils/pagination';

/**
 * A service that handles notices.
 */
@Injectable()
export class NoticeService extends AbstractPageService<Notice, NoticePage> {

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
    }

    protected loadPage(offset: number, size: number): Observable<NoticePage> {
        return this.apiClientService.getActiveNotices(offset, size);
    }

}
