import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DefaultService, Device, Location } from '../api';
import { Snackbars } from '../utils/snackbars';
import { MarkerMapComponent } from '../widgets/marker-map.component';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-device-location',
    templateUrl: './device-location.component.html'
  })
  export class DeviceLocationComponent implements OnInit, OnDestroy {

    @ViewChild(MarkerMapComponent, { static: true }) map: MarkerMapComponent;

    id: string;

    device = new BehaviorSubject<Device>(null);
    location = new BehaviorSubject<Location>(null);

    private deviceSubscription: Subscription;
    private locationSubscription: Subscription;

    constructor(
        public res: ResourceService,
        private snackbar: MatSnackBar,
        private apiClientService: DefaultService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.updateDevice();
        this.updateLocation();
    }

    isUpdatingDevice(): boolean {
        return this.deviceSubscription != null;
    }

    isUpdatingLocation(): boolean {
        return this.locationSubscription != null;
    }

    updateDevice(): void {
        if (this.deviceSubscription) { return; }
        this.deviceSubscription = this.apiClientService.getDevice(parseInt(this.id, 10)).subscribe((result) => {
            this.device.next(result);
            this.deviceSubscription = null;
        }, (error) => {
            Snackbars.showError(this.snackbar, this.res.strings('device_location_device_error'));
            this.deviceSubscription = null;
        });
    }

    updateLocation(): void {
        if (this.locationSubscription) { return; }
        this.locationSubscription = this.apiClientService.getDeviceLocation(parseInt(this.id, 10)).subscribe((result) => {
            this.location.next(result);
            this.map.setMarkerPosition(result.coordinate);
            this.locationSubscription = null;
        }, (error) => {
            Snackbars.showError(this.snackbar, this.res.strings('device_location_location_error'));
            this.locationSubscription = null;
            this.map.setMarkerPosition(null);
        });
    }


    ngOnDestroy() {
        if (this.deviceSubscription) { this.deviceSubscription.unsubscribe(); }
        if (this.locationSubscription) { this.locationSubscription.unsubscribe(); }
    }

  }

