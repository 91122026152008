import { Component } from '@angular/core';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-outside',
  templateUrl: './outside.component.html'
})
export class OutsideComponent {

  constructor(public res: ResourceService) {}

}
