import { Component } from '@angular/core';
import { UserService } from '../services/user.service';

/**
 * Implements a responsive profile button for the toolbar.
 */
@Component({
    selector: 'app-profile-button',
    templateUrl: './profile-button.component.html'
})
export class ProfileButtonComponent {

    /**
     * The login name.
     */
    login: string;

    constructor(private userService: UserService) {
        this.login = userService.getLogin();
    }



}
