import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Implements a responsive button for the toolbar
 * that consists of an icon, text and a link.
 * Optionally, it can be disabled and it is possible
 * to set a color. If a color is used, the button
 * will raise as well. If no color is provided, the
 * button will be flat.
 */
@Component({
    selector: 'app-toolbar-button',
    templateUrl: './toolbar-button.component.html'
})
export class ToolbarButtonComponent implements OnInit, OnDestroy {

    /**
     * The title of the button.
     */
    @Input() title: string;
    /**
     * The icon of the button which should
     * be a character or ligature of the material
     * icon font.
     */
    @Input() icon: string;
    /**
     * The router link that is activated by the
     * button.
     */
    @Input() link: string;
    /**
     * Enables or disables the button.
     */
    @Input() disabled: boolean;
    /**
     * The color (e.g. "accent") which will also
     * raise the button, if set.
     */
    @Input() color: string;

    /**
     * Determines whether the button is shown.
     */
    visible: boolean;

    /**
     * The subscription to the router.
     */
    private routerSubscription: Subscription;

    /**
     * Creates a new button.
     *
     * @param router The router.
     */
    constructor(private router: Router) { }

    ngOnInit() {
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateVisibility();
            }
        });
        this.updateVisibility();
    }

    private updateVisibility(): void {
        this.visible = (this.router.url !== this.link);
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

}
