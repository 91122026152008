import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, UserState } from '../services/user.service';
import { Subscription } from 'rxjs';
import { MatSidenavContainer, MatSidenav } from '@angular/material/sidenav';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-inside',
  templateUrl: './inside.component.html'
})
export class InsideComponent implements OnInit, OnDestroy {

  private static readonly DRAWER_BREAKPOINT = 960;

  private drawerSubscription: Subscription;
  private routerSubscription: Subscription;

  @ViewChild(MatSidenav, { static: true }) drawer: MatSidenav;

  compact = false;
  opened = true;

  constructor(private userService: UserService, private router: Router, public res: ResourceService) { }

  ngOnInit(): void {
    this.updateDrawer(window.innerWidth);
    this.opened = this.drawer.opened;
    this.drawerSubscription = this.drawer.openedChange.subscribe((state) => {
      this.opened = state;
    });
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (this.compact) { this.drawer.close(); }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateDrawer((event.target as Window).innerWidth);

  }

  toggleDrawer(): void {
    this.drawer.toggle();
    this.opened = this.drawer.opened;
  }

  updateDrawer(width: number): void {
    if (width < InsideComponent.DRAWER_BREAKPOINT && !this.compact) {
      this.drawer.mode = 'over';
      this.drawer.close();
      this.compact = true;
      this.opened = false;
    } else if (width >= InsideComponent.DRAWER_BREAKPOINT && this.compact) {
      this.compact = false;
      this.drawer.mode = 'side';
      this.drawer.open();
      this.opened = true;
    }
    if (this.drawer.opened !== this.opened) {
      if (this.opened) { this.drawer.open(); } else { this.drawer.close(); }
    }
  }


  ngOnDestroy(): void {
    this.drawerSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  onLogout(): void {
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
