import { Injectable } from '@angular/core';
import { BehaviorSubject, empty } from 'rxjs';
import { DefaultService, PlacePage, VisitPage, DevicePage } from '../api';
import { Page } from '../utils/pagination';
import * as JSZip from 'jszip';
import { UserService } from './user.service';
/**
 * A service that handles the download of
 * data to create exports.
 */
@Injectable()
export class ExportService {

  /** The page size to download. */
  private static readonly BATCH_SIZE = 100;
  private static readonly COLUMN_SEPARATOR = ';';
  private static readonly LINE_SEPARATOR = '\r\n';

  downloading = new BehaviorSubject<boolean>(false);
  error = new BehaviorSubject<boolean>(false);
  devices = new BehaviorSubject<DevicePage>(ExportService.emptyPage());
  places = new BehaviorSubject<PlacePage>(ExportService.emptyPage());
  visits = new BehaviorSubject<VisitPage>(ExportService.emptyPage());
  private request = 0;

  /** Creates an empty page. */
  private static emptyPage() {
    return { elements: [], offset: 0, total: -1, size: 0 };
  }

  constructor(private apiClientService: DefaultService, private userService: UserService) {  }

  startDownload(): void {
    if (this.downloading.getValue()) { return; }
    this.resetDownload();
    this.downloading.next(true);
    this.scheduleDownload(this.request);
  }

  private scheduleDownload(code: number): void {
    if (this.isIncomplete(this.devices.getValue())) {
      // download more devices
      this.apiClientService.getCurrentUserDevices(this.devices.getValue().offset, ExportService.BATCH_SIZE)
        .subscribe(response => {
          if (code === this.request) {
            this.devices.next(this.mergePages(response, this.devices.getValue()));
            this.scheduleDownload(code);
          }
        }, this.createErrorHandler(code)
      );
    } else if (this.isIncomplete(this.places.getValue())) {
      // download more places
      this.apiClientService.getCurrentUserPlaces('IDENTIFIER', this.places.getValue().offset, ExportService.BATCH_SIZE)
        .subscribe(response => {
          if (code === this.request) {
            this.places.next(this.mergePages(response, this.places.getValue()));
            this.scheduleDownload(code);
          }
        }, this.createErrorHandler(code)
      );
    } else if (this.isIncomplete(this.visits.getValue())) {
      // download more visits
      this.apiClientService.getCurrentUserVisits(this.visits.getValue().offset, ExportService.BATCH_SIZE)
        .subscribe(response => {
          if (code === this.request) {
            this.visits.next(this.mergePages(response, this.visits.getValue()));
            this.scheduleDownload(code);
          }
        }, this.createErrorHandler(code)
      );
    } else {
      this.generateZip(code);
    }
  }

  private createErrorHandler(code: number): (any) => void {
    return response => {
      if (code === this.request) {
        this.error.next(true);
        this.downloading.next(false);
      }
    };
  }

  private mergePages<T>(source: Page<T>, destination: Page<T>): Page<T> {
    const result = ExportService.emptyPage();
    result.total = source.total;
    result.size = destination.size + source.elements.length;
    result.elements = destination.elements.concat(source.elements);
    result.offset = destination.offset + ExportService.BATCH_SIZE;
    return result;
  }

  private isIncomplete(page: Page<any>): boolean {
    return page.total < 0 || page.total > page.offset;
  }

  private resetDownload(): void {
    this.devices.next(ExportService.emptyPage());
    this.places.next(ExportService.emptyPage());
    this.visits.next(ExportService.emptyPage());
  }

  stopDownload(): void {
    this.resetDownload();
    this.downloading.next(false);
    this.request += 1;
  }

  private generateZip(code: number) {
      const zip = new JSZip();
      const object = this;
      const devices = this.createDevices();
      const places = this.createPlaces();
      const visits = this.createVisits();
      if (devices != null) { zip.file('devices.csv', devices); }
      if (places != null) { zip.file('places.csv', places); }
      if (visits != null) { zip.file('visits.csv', visits); }
      zip.file('README.txt', this.createReadme());
      zip.generateAsync({type: 'blob'}).then( content => {
        object.finishZip(code, content);
      }, this.createErrorHandler(code));
  }

  private createReadme(): string {
    let result = '';
    result += 'This zip contains up to 3 files in CSV format:' + ExportService.LINE_SEPARATOR;
    result += ExportService.LINE_SEPARATOR;
    result += '- devices.csv: Information about the devices that are associated with your account.'  + ExportService.LINE_SEPARATOR;
    result += '- places.csv: Places that your devices visited at least once for more than 10 minutes.'  + ExportService.LINE_SEPARATOR;
    result += '- visits.csv: Visits of your devices at places, associatable via ID columns.'  + ExportService.LINE_SEPARATOR;
    result += ExportService.LINE_SEPARATOR;
    result += 'Files that would contain no data are removed automatically.' + ExportService.LINE_SEPARATOR;
    result += 'Columns are separated by semi-colons (";"), lines are separated by carriage return followed by line feed ("CRLF").';
    result += ExportService.LINE_SEPARATOR;
    result += 'All time columns ("CREATED", "MODIFIED", "BEGIN", "END") are Unix timestamps (Epoch) with millisecond resolution.';
    result += ExportService.LINE_SEPARATOR;
    return result;
  }

  private createDevices(): string {
    const devices = this.devices.getValue().elements;
    if (devices == null || devices.length === 0) { return null; }
    let result = '';
    result += 'ID' + ExportService.COLUMN_SEPARATOR;
    result += 'MANUFACTURER' + ExportService.COLUMN_SEPARATOR;
    result += 'MODEL' + ExportService.COLUMN_SEPARATOR;
    result += 'BRAND' + ExportService.COLUMN_SEPARATOR;
    result += 'OS' + ExportService.COLUMN_SEPARATOR;
    result += 'CREATED' + ExportService.COLUMN_SEPARATOR;
    result += 'MODIFIED' + ExportService.COLUMN_SEPARATOR;
    result += ExportService.LINE_SEPARATOR;
    devices.forEach((device) => {
      result += device.id + ExportService.COLUMN_SEPARATOR;
      result += this.encodeString(device.manufacturer) + ExportService.COLUMN_SEPARATOR;
      result += this.encodeString(device.model) + ExportService.COLUMN_SEPARATOR;
      result += this.encodeString(device.device) + ExportService.COLUMN_SEPARATOR;
      result += device.os + ExportService.COLUMN_SEPARATOR;
      result += device.creationTime + ExportService.COLUMN_SEPARATOR;
      result += device.modificationTime + ExportService.COLUMN_SEPARATOR;
      result += ExportService.LINE_SEPARATOR;
    });
    return result;
  }

  private createPlaces(): string {
    const places = this.places.getValue().elements;
    if (places == null || places.length === 0) { return null; }
    let result = '';
    result += 'ID' + ExportService.COLUMN_SEPARATOR;
    result += 'NICKNAME' + ExportService.COLUMN_SEPARATOR;
    result += 'ADDRESS' + ExportService.COLUMN_SEPARATOR;
    result += 'LONGITUDE' + ExportService.COLUMN_SEPARATOR;
    result += 'LATITUDE' + ExportService.COLUMN_SEPARATOR;
    result += 'CREATED' + ExportService.COLUMN_SEPARATOR;
    result += 'MODIFIED' + ExportService.COLUMN_SEPARATOR;
    result += ExportService.LINE_SEPARATOR;
    places.forEach((place) => {
      result += place.id + ExportService.COLUMN_SEPARATOR;
      result += this.encodeString(place.nickname) + ExportService.COLUMN_SEPARATOR;
      result += this.encodeString(place.address) + ExportService.COLUMN_SEPARATOR;
      result += place.coordinate.longitude + ExportService.COLUMN_SEPARATOR;
      result += place.coordinate.latitude + ExportService.COLUMN_SEPARATOR;
      result += place.creationTime + ExportService.COLUMN_SEPARATOR;
      result += place.modificationTime + ExportService.COLUMN_SEPARATOR;
      result += ExportService.LINE_SEPARATOR;
    });
    return result;
  }

  private encodeString(value: string): string {
    if (value == null) {
      return '';
    } else {
      return value.replace(';', ',');
    }
  }

  private createVisits(): string {
    const visits = this.visits.getValue().elements;
    if (visits == null || visits.length === 0) { return null; }
    let result = '';
    result += 'ID' + ExportService.COLUMN_SEPARATOR;
    result += 'DEVICE_ID' + ExportService.COLUMN_SEPARATOR;
    result += 'PLACE_ID' + ExportService.COLUMN_SEPARATOR;
    result += 'BEGIN' + ExportService.COLUMN_SEPARATOR;
    result += 'END' + ExportService.COLUMN_SEPARATOR;
    result += 'CREATED' + ExportService.COLUMN_SEPARATOR;
    result += 'MODIFIED' + ExportService.COLUMN_SEPARATOR;
    result += ExportService.LINE_SEPARATOR;
    visits.forEach((visit) => {
      result += visit.id + ExportService.COLUMN_SEPARATOR;
      result += visit.device + ExportService.COLUMN_SEPARATOR;
      result += visit.place + ExportService.COLUMN_SEPARATOR;
      result += visit.beginTime + ExportService.COLUMN_SEPARATOR;
      result += visit.endTime + ExportService.COLUMN_SEPARATOR;
      result += visit.creationTime + ExportService.COLUMN_SEPARATOR;
      result += visit.modificationTime + ExportService.COLUMN_SEPARATOR;
      result += ExportService.LINE_SEPARATOR;
    });
    return result;
  }

  private finishZip(code: number, content: Blob) {
    if (this.request === code) {
      const login = this.userService.getLogin();
      let name = 'maptology-export';
      if (login != null) { name += '-' + login; }
      name += '-' + Date.now();
      this.saveAs(name + '.zip', content);
      this.downloading.next(false);
    }
  }

  private saveAs(name: string, blob: Blob): void {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
    } else {
        const link = document.createElement('a');
        (<any>link).download = name;
        link.href = URL.createObjectURL(blob);
        link.click();
        setTimeout(function() {
            URL.revokeObjectURL(link.href);
            link.remove();
        }, 10);
    }
  }

}
