import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';

/**
 * Content layout component which displays an <app-header>
 * on the top, a <main> on the left and an <aside> on the right side
 * through angular projection.
 * The <main> tag is mandatory and the other two can be omitted if necessary.
 *
 * @author Adriano
 */
@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html'
})
export class ContentLayoutComponent implements AfterViewInit {

  /* Attributes which are forwarding the styles and classes
   * to the div.outer-container which where specified on the app-content-layout.*/
  @Input() layoutStyle?: { [klass: string]: any; };
  @Input() layoutClass?: string | string[] | Set<string> | { [klass: string]: any; };

  @ViewChild('mainDiv') mainDiv: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    const div: HTMLDivElement = this.mainDiv.nativeElement;
    if (div.getElementsByTagName('main').length === 0) {
      throw new Error('<main> tag not specified!');
    }
  }
}
