<mat-card class="mat-elevation-z0">
    <mat-card-header>
        <mat-card-title><mat-icon style="font-size: 18px;">share</mat-icon>{{ res.strings('share_dialog_title') }}</mat-card-title>
        <mat-card-subtitle>{{ res.strings('share_dialog_subtitle') }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form #shareForm="ngForm">
            <mat-form-field [hintLabel]="res.strings('share_dialog_hint')" fxFlex="1 1 100%" useParentForm>
                <input matInput #linkField="ngModel" name="link" [placeholder]="res.strings('share_dialog_placeholder')" (keyup.enter)="shareForm.valid && onAccept()" [(ngModel)]="link" pattern="[ ]*(https:\/\/www.maptology.com\/app\/shares\/){0,1}([A-Za-z0-9_-]){22,22}[ ]*"
                    required [disabled]="isLoading()">
                <mat-hint align="end">{{linkField.value?.length || 0}}</mat-hint>
                <mat-error *ngIf="linkField.invalid">
                    <ng-container [ngSwitch]="encode(linkField.errors)">
                        <ng-container *ngSwitchCase="FormErrorCode.REQUIRED">{{res.strings('share_dialog_required')}}</ng-container>
                        <ng-container *ngSwitchCase="FormErrorCode.PATTERN">{{res.strings('share_dialog_pattern')}}</ng-container>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>
    <app-decision-panel 
        [positiveName]="res.strings('button_save')" 
        positiveIcon="save" 
        [negativeName]="res.strings('button_cancel')"
        negativeIcon="close"
        [processing]="isLoading()"
        [disabled]="shareForm.invalid"
        (positive)="onAccept()"
        (negative)="onClose()"
    ></app-decision-panel>
</mat-card>