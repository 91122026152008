<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>people</mat-icon>
            <span>{{ res.strings('manage_users_title') }}</span>
        </div>
        <div class="header-actions flex-row-nowrap">
            <form #searchForm="ngForm" style="font-size: 12px">
                <mat-form-field style="max-width:120px">
                    <input matInput #termField="ngModel" 
                        maxlength="64" name="term" 
                        [placeholder]="res.strings('manage_users_term_placeholder')" 
                        [(ngModel)]="term" 
                        pattern="([A-Za-z]|[\u00C0-\u00FF]| ){0,64}"
                        [disabled]="loading.getValue()" (keyup.enter)="onSearch()">
                    <mat-error *ngIf="termField.invalid">
                        <ng-container [ngSwitch]="encode(termField.errors)">
                            <ng-container *ngSwitchCase="FormErrorCode.REQUIRED">
                                {{res.strings('manage_users_term_required')}}
                            </ng-container>
                            <ng-container *ngSwitchCase="FormErrorCode.PATTERN">
                                {{res.strings('manage_users_term_pattern')}}
                            </ng-container>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </form>
            <button mat-icon-button 
                [matTooltip]="res.strings('manage_users_search_tooltip')" 
                (click)="onSearch()" 
                [disabled]="loading.getValue()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="loading.getValue()">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("id")'> {{ res.strings('manage_users_id') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("id")'> {{element.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("type")'> {{ res.strings('manage_users_type') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("type")'>
                    <ng-container *ngIf="isAdmin(element);else scientist">
                        <mat-icon [matTooltip]="res.strings('manage_users_admin')">security</mat-icon>
                    </ng-container>
                    <ng-template #scientist>
                        <ng-container *ngIf="isScientist(element);else user">
                                <mat-icon [matTooltip]="res.strings('manage_users_scientist')">insert_chart</mat-icon>
                        </ng-container>
                        <ng-template #user>
                            <mat-icon [matTooltip]="res.strings('manage_users_user')">face</mat-icon>
                        </ng-template>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="login">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("login")'> {{ res.strings('manage_users_login') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("login")'>{{ element.login }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("email")'> {{ res.strings('manage_users_email') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("email")'> {{ getCorrespondence(element) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("name")'> {{ res.strings('manage_users_name') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("name")'> {{element.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("creationTime")'> {{ res.strings('manage_users_creation') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("creationTime")'> {{element.creationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="modificationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("modificationTime")'> {{ res.strings('manage_users_modification') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("modificationTime")'> {{element.modificationTime | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef [ngStyle]='getStyle("actions")' class="action-cell"> {{ res.strings('manage_users_actions') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]='getStyle("actions")' class="action-cell">
                    <ng-container *ngIf="!isSelf(element)">
                        <ng-container *ngIf="element.deleted; else active">
                            <button mat-icon-button [matTooltip]="res.strings('manage_users_restore_tooltip')" (click)="onRestore(element)" [disabled]="loading.getValue()">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #active>
                            <button mat-icon-button [matTooltip]="res.strings('manage_users_delete_tooltip')" (click)="onDelete(element)" [disabled]="loading.getValue()">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <ng-container *ngIf="isAdmin(element) || isScientist(element)">
                                <button mat-icon-button [matTooltip]="res.strings('manage_users_user_tooltip')" (click)="makeUser(element)" [disabled]="loading.getValue()">
                                    <mat-icon>face</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!isScientist(element)">
                                <button mat-icon-button [matTooltip]="res.strings('manage_users_scientist_tooltip')" (click)="makeScientist(element)" [disabled]="loading.getValue()">
                                    <mat-icon>insert_chart</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!isAdmin(element)">
                                <button mat-icon-button [matTooltip]="res.strings('manage_users_admin_tooltip')" (click)="makeAdmin(element)" [disabled]="loading.getValue()">
                                    <mat-icon>security</mat-icon>
                                </button>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
        </mat-paginator>
    </main>
</app-content-layout>