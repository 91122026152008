import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService, UserState, UserRole } from '../services/user.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ConsoleService } from '../services/console.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    private static readonly OUTSIDE: string[] = [
        '/login', '/register', '/shares', '/activate', '/reset'
    ];

    private static readonly USER: string[] = [
        '/devices', '/notices', '/privileges', '/profile', '/places', '/export', '/timesheet'
    ];

    private static readonly ADMIN: string[] = [
        '/manage'
    ];

    private static readonly NO_USER = '/login';
    private static readonly NO_ADMIN = '/notices';



    constructor(private userService: UserService, private consoleService: ConsoleService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        const url: string = state.url;
        // known outside stuff is ok for everyone
        const outside = this.isOutside(url);
        this.consoleService.log('Guard: Accepting outside route: ' + url);
        if (outside) { return true; }
        // handle known user and admin routes
        const admin = this.isAdmin(url);
        const user = this.isUser(url);
        this.consoleService.log('Guard: Checking route: ' + (admin ? 'admin' : 'not admin') + ' ' + (user ? 'user' : 'not user'));

        if (admin || user) { return this.allow(url, admin); }
        // unknown route, go to default page
        if (this.userService.state.getValue() === UserState.Available) {
            this.router.navigate([AuthGuard.NO_ADMIN]);
        } else {
            this.router.navigate([AuthGuard.NO_USER]);
        }
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        return this.canActivate(route, state);
    }

    private allow(url: string, onlyAdmin: boolean): Observable<boolean>|boolean {
        if (this.userService.state.getValue() === UserState.Available) {
            if (!onlyAdmin || this.userService.hasRole(UserRole.Admin)) {
                return true;
            } else {
                this.router.navigate([AuthGuard.NO_ADMIN]);
                return false;
            }
        } else if (this.userService.state.getValue() === UserState.Unavailable) {
            this.userService.redirectUrl = url;
            this.router.navigate([AuthGuard.NO_USER]);
            return false;
        } else {
            const result = new Subject<boolean>();
            const subscription = this.userService.state.subscribe((state) => {
                if (state  === UserState.Available) {
                    if (!onlyAdmin || this.userService.hasRole(UserRole.Admin)) {
                        result.next(true);
                    } else {
                        this.router.navigate([AuthGuard.NO_ADMIN]);
                        result.next(false);
                    }
                } else {
                    this.userService.redirectUrl = url;
                    this.router.navigate([AuthGuard.NO_USER]);
                    result.next(false);
                }
            });
            result.subscribe((e) => {
                subscription.unsubscribe();
            });
            return result;
        }
    }

    private isOutside(url: string): boolean {
        for (const out of AuthGuard.OUTSIDE) {
            if (url.startsWith(out)) { return true; }
        }
        return false;
    }

    private isUser(url: string): boolean {
        for (const user of AuthGuard.USER) {
            if (url.startsWith(user)) { return true; }
        }
        return false;
    }

    private isAdmin(url: string): boolean {
        for (const admin of AuthGuard.ADMIN) {
            if (url.startsWith(admin)) { return true; }
        }
        return false;
    }

}
