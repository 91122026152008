import { Component, OnInit } from '@angular/core';
import { DefaultService } from '../api';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageDialogComponent } from '../widgets/message-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '../utils/httpcodes';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html'
  })
  export class ActivateComponent implements OnInit {

    showMessage = true;

    constructor(
        public res: ResourceService,
        private apiClientService: DefaultService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        const token = this.route.snapshot.paramMap.get('token');
        if (!token) {
            this.router.navigate(['/']);
        } else {
            this.apiClientService.verifyUserEmail(token).subscribe((result) => {
                this.showMessage = false;
                MessageDialogComponent.open(this.dialog, {
                    title: this.res.strings('activate_success_title'),
                    message: this.res.strings('activate_success_message'),
                    button: this.res.strings('button_gotit')}).subscribe((close) => {
                    this.router.navigate(['/']);
                });
            }, (error) => {
                this.showMessage = false;
                let message: string = this.res.strings('activate_error_message');
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case HttpStatusCode.BAD_REQUEST:
                            message = this.res.strings('activate_error_badlink');
                            break;
                        case HttpStatusCode.FORBIDDEN:
                            message = this.res.strings('activate_error_expiredlink');
                            break;
                        default:
                            break;
                    }
                }
                MessageDialogComponent.open(this.dialog, {
                    title: this.res.strings('activate_error_title'),
                    message: message,
                    button: this.res.strings('button_gotit') }).subscribe((close) => {
                    this.router.navigate(['/']);
                });
            });
        }
    }

  }

