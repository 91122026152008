import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DefaultService, Location, ShareInfo } from '../api';
import { Snackbars } from '../utils/snackbars';
import { MarkerMapComponent } from '../widgets/marker-map.component';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html'
  })
  export class ShareComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild(MarkerMapComponent, { static: true }) map: MarkerMapComponent;

    token: string;

    info = new BehaviorSubject<ShareInfo>(null);
    location = new BehaviorSubject<Location>(null);

    private infoSubscription: Subscription;
    private locationSubscription: Subscription;

    constructor(
        public res: ResourceService,
        private snackbar: MatSnackBar,
        private apiClientService: DefaultService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        this.updateInfo();
    }

    ngAfterViewInit() {
    }

    isUpdatingInfo(): boolean {
        return this.infoSubscription != null;
    }

    isUpdatingLocation(): boolean {
        return this.infoSubscription != null || this.locationSubscription != null;
    }

    isOutside(): boolean {
        if (this.route.parent != null) {
            return this.route.parent.snapshot.url[0].path !== 'privileges';
        }
        return false;
    }

    addShare(): void {
        this.router.navigate(['/privileges', { add: this.token}]);
    }

    updateInfo(): void {
        if (this.infoSubscription) { return; }
        this.infoSubscription = this.apiClientService.getShareInfo(this.token).subscribe((result) => {
            this.info.next(result);
            this.infoSubscription = null;
            this.updateLocation();
        }, (error) => {
            Snackbars.showError(this.snackbar, this.res.strings('share_no_update_info'));
            this.infoSubscription = null;
        });
    }

    updateLocation(): void {
        if (this.locationSubscription) { return; }
        this.locationSubscription = this.apiClientService.getShareLocation(this.token).subscribe((result) => {
            this.location.next(result);
            this.map.setMarkerPosition(result.coordinate);
            this.locationSubscription = null;
        }, (error) => {
            Snackbars.showError(this.snackbar, this.res.strings('share_no_update_location'));
            this.map.setMarkerPosition(null);
            this.locationSubscription = null;
        });
    }

    ngOnDestroy() {
        if (this.locationSubscription) { this.locationSubscription.unsubscribe(); }
        if (this.infoSubscription) { this.infoSubscription.unsubscribe(); }
    }

  }

