<div style="min-height:100%;width:100%;display:table">
    <div style="display:table-cell;vertical-align: middle">
        <mat-grid-list [cols]="columns" gutterSize="10" rowHeight="350" class="grid-layout">
            <mat-grid-tile>
                <mat-card class="mat-typography" style="flex: 1; max-width:500px;min-height:280px;margin:10px;">
                    <mat-card-title-group>
                        <mat-card-title>{{res.strings('login_login_title')}}</mat-card-title>
                        <mat-card-subtitle>{{res.strings('login_login_subtitle')}}</mat-card-subtitle>
                    </mat-card-title-group>
                    <mat-card-content>
                        <form #loginForm="ngForm">
                            <app-login-field [login]="login"></app-login-field>
                            <app-password-field [password]="password" (keyup.enter)="loginForm.valid && onLogin()"></app-password-field>
                        </form>
                    </mat-card-content>
                    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center" style="min-height:60px">
                        <ng-container *ngIf="idle | async as submittable;else progressBar">
                            <button #loginButton mat-raised-button color="accent" [disabled]="!submittable || loginForm.invalid" (click)="onLogin()">
                                <mat-icon class="icon-button">lock_open</mat-icon>
                                <span>{{res.strings('button_login')}}</span>
                            </button>
                        </ng-container>
                        <ng-template #progressBar>
                            <mat-spinner [diameter]="36"></mat-spinner>
                        </ng-template>
                        <a class="link-primary" routerLink="/reset" style="text-align: center">{{res.strings('login_login_reset')}}</a>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile rowspan="2">
                <mat-card class="mat-typography" style="flex: 1;max-width:500px;min-height:280px;margin:10px;vertical-align:top">
                    <mat-card-content class="mat-typography">
                        <img mat-card-image src="assets/images/frontpage.png">
                        <mat-list>
                            <mat-list-item>
                                <div style="width:120px" class="detail-label">{{res.strings('login_build_version')}}</div>
                                <div class="detail-value">{{ getVersion() }}</div>
                            </mat-list-item>
                            <mat-list-item>
                                <div style="width:120px" class="detail-label">{{res.strings('login_build_time')}}</div>
                                <div class="detail-value">{{ getTime() }}</div>
                            </mat-list-item>

                        </mat-list>

                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="mat-typography" style="flex: 1; max-width:500px;min-height:280px;margin:10px;">
                    <mat-card-title>{{res.strings('login_register_title')}}</mat-card-title>
                    <mat-card-subtitle>{{res.strings('login_register_subtitle')}}</mat-card-subtitle>
                    <mat-card-content class="caption" style="flex:1">
                        <p>{{res.strings('login_register_paragraph')}}</p>
                    </mat-card-content>
                    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center" style="min-height:150px">
                        <button mat-raised-button color="primary" routerLink="/register">
                            <mat-icon class="icon-button">mode_edit</mat-icon>
                            <span>{{res.strings('button_register')}}</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>