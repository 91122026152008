import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { DefaultService, Device, Position } from '../api';
import { CollectionViewer } from '@angular/cdk/collections';
import { DeltaCodec } from "../utils/deltacodec";

/**
 * A service that handles visits for a particular device and time.
 */
@Injectable()
export class DevicePositionService implements DataSource<Position> {

    readonly device = new BehaviorSubject<Device>(null);
    readonly loading = new BehaviorSubject<boolean>(false);
    readonly date = new BehaviorSubject<Date>(new Date());
    private elements = new BehaviorSubject<Position[]>([]);

    private readonly streams = 5;
    private readonly deltaCodec = new DeltaCodec(this.streams);

    private id: number;
    private subscriptions = 0;

    constructor(private apiClientService: DefaultService) { }

    getPositions(): Position[] {
        return this.elements.getValue();
    }

    setDevice(id: number) {
        if (this.loading.getValue()) { return; }
        this.loading.next(true);
        this.device.next(null);
        this.elements.next([]);
        this.id = id;
        if (id != null) {
            this.apiClientService.getDevice(id).subscribe((result) => {
                this.device.next(result);
                this.loading.next(false);
                this.setDate(this.date.getValue());
            }, (error) => {
                this.id = null;
                this.loading.next(false);
            });
        }
    }

    setDate(date: Date) {
        if (this.loading.getValue()) { return; }
        this.loading.next(true);
        const d = (date == null) ? new Date() : new Date(date);
        d.setHours(0, 0, 0, 0);
        const millis = d.getTime();
        this.date.next(d);
        this.elements.next([]);
        this.apiClientService.getDevicePositionsRange(this.id, millis, 24 * 60 * 60 * 1000, true).subscribe((result) => {
            this.elements.next(result.sort((a, b) => a.timestamp - b.timestamp));
            this.loading.next(false);
        }, (error) => {
            this.elements.next([]);
            this.loading.next(false);
        });
    }

    connect(collectionViewer: CollectionViewer): Observable<Position[]> {
        this.subscriptions += 1;
        return this.elements;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subscriptions -= 1;
    }

    /**
     * Decodes the accelerator string of the given position if present.
     * 
     * The accelerator string is decoded into an array of 5 tuples by the DeltaCodec.
     *  -> (timestamp, accuracy, x, y, z).
     *  -> timestamp is given in microseconds.
     *  -> x, y & z need to be shiftet 7 digits to the left (multiply with 10^-7).
     * 
     * @param currPosition Current position object.
     * @return A multidimensional array of numbers.
     */
    decodeAccelData(currPosition: Position): number[][] {
        if (!currPosition.metadata.accel) return null;
        let data = this.deltaCodec.decode(currPosition.metadata.accel as string, null);
        data.forEach((value: number[], idx: number, arr: number[][]) => {
            value[2] *= 0.0000001;
            value[3] *= 0.0000001;
            value[4] *= 0.0000001;
        })
        return data;
    }
}
