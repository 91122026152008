import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceService } from '../services/resource.service';
import { ExportService } from '../services/export.service';
import { Page } from '../utils/pagination';
import { Subscription } from 'rxjs';
import { Snackbars } from '../utils/snackbars';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styles: [
    `.progress-label { font-size: 85% !important; }`,
    `.progress-float { float: right; }`
  ]
})
export class ExportComponent implements OnInit, OnDestroy {

  columns = 1;
  private errorSubscription: Subscription;

  constructor(public exportService: ExportService,
      public res: ResourceService, private snackbar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.updateColumns(window.innerWidth);
    this.errorSubscription = this.exportService.error.subscribe(error => {
      if (error) {
        this.exportService.error.next(false);
        Snackbars.showError(this.snackbar, this.res.strings('export_network_error'), Snackbars.DURATION_SHORT);
      }
    });
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateColumns((event.target as Window).innerWidth);
  }

  updateColumns(width: number): void {
    if (width < 800) {
        this.columns = 1;
    } else {
        this.columns = 2;
    }
  }

  progressValue<T>(page: Page<T>): number {
    if (page == null || page.total < 0)  {
      return 0;
    } else {
      return Math.min(page.offset, page.total) / page.total * 100;
    }
  }


}
