import { Component, OnInit, OnDestroy, ViewChild, DoCheck, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { LogMessage } from '../api';
import { ManageLogService } from '../services/manage-log.service';
import { LogDialogComponent } from '../widgets/log-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn, TableHandler } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-manage-log',
    templateUrl: './manage-log.component.html'
})
export class ManageLogComponent implements OnInit, OnDestroy, DoCheck {

    private static COLUMNS: TableColumn[] = [
        { name: 'id', dropPriority: 1, width: 80, fixedWidth: true },
        { name: 'time', dropPriority: 3, width: 170, fixedWidth: true },
        { name: 'level', dropPriority: 0, width: 60, fixedWidth: true },
        { name: 'thread', dropPriority: 4, width: 50, fixedWidth: true },
        { name: 'className', dropPriority: 5, width: 220, fixedWidth: true },
        { name: 'message', dropPriority: 0, width: 200 },
        { name: 'trace', dropPriority: 2, width: 60, fixedWidth: true },
        { name: 'actions', dropPriority: 0, width: 60, fixedWidth: true }
    ];

    @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;

    readonly connecting: BehaviorSubject<boolean>;
    readonly dataSource: DataSource<LogMessage>;
    connected: boolean;

    private tableHandler: TableHandler;

    constructor(public res: ResourceService, private logService: ManageLogService, private dialog: MatDialog) {
        this.connecting = logService.connecting;
        this.dataSource = logService;
    }

    ngDoCheck(): void {
        this.tableHandler.onCheck();
    }

    getStyle(column: string): any {
        return this.tableHandler.getColumnStyle(column);
    }

    getColumns(): string[] {
        return this.tableHandler.getDisplayColumns();
    }


    ngOnInit() {
        this.tableHandler = new TableHandler(this.table, ManageLogComponent.COLUMNS);
        this.logService.open();
        this.connected = true;
    }

    ngOnDestroy(): void {
        this.connected = false;
        this.logService.close();
    }

    onConnect(): void {
        this.connected = true;
        this.logService.close();
        this.logService.open();
    }

    onDisconnect(): void {
        this.connected = false;
        this.logService.close();
    }

    onClear(): void {
        this.logService.clear();
    }

    getClassName(name: string) {
        const idx = name.lastIndexOf('.');
        if (idx === -1) {
            return name;
        } else {
            return name.substring(idx + 1);
        }
    }

    getLevel(level: string) {
        if (level === 'ERROR') {
            return 'error';
        } else if (level === 'SEVERE') {
            return 'error_outline';
        } else if (level === 'INFO') {
            return 'info_outline';
        } else {
            return 'panorama_fish_eye';
        }
    }

    getColor(level: string): string {
        if (level === 'ERROR' || level === 'SEVERE') {
            return 'accent';
        } else {
            return null;
        }
    }

    onShow(message: LogMessage): void {
        LogDialogComponent.open(this.dialog, message);
    }

}
