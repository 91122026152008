<mat-form-field [hintLabel]="res.strings('login_field_hint')" fxFlex="1 1 100%" appUseParentForm>
    <input matInput #loginField="ngModel" minlength="5" maxlength="32" 
        name="login" [placeholder]="res.strings('login_field_placeholder')" [(ngModel)]="login" pattern="[a-zA-Z0-9]*" required [disabled]="disabled">
    <mat-hint align="end">{{loginField.value?.length || 0}}/32</mat-hint>
    <mat-error *ngIf="loginField.invalid">
        <ng-container [ngSwitch]="encode(loginField.errors)">
            <ng-container *ngSwitchCase="FormErrorCode.REQUIRED">{{ res.strings('login_field_required') }}</ng-container>
            <ng-container *ngSwitchCase="FormErrorCode.PATTERN">{{ res.strings('login_field_pattern') }}</ng-container>
            <ng-container *ngSwitchCase="FormErrorCode.MINLENGTH">{{ res.strings('login_field_minlength') }}</ng-container>
        </ng-container>
    </mat-error>
</mat-form-field>