/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate';


/**
 * Represents a detailed location with usage approved or prohibited by the user.
 */
export interface ReleasedLocation { 
    /**
     * The timestamp of the location.
     */
    timestamp: number;
    /**
     * The coordinate of the location.
     */
    coordinate?: Coordinate;
    /**
     * The availability information of the location.
     */
    availability: ReleasedLocation.AvailabilityEnum;
    /**
     * The version code of the application.
     */
    version: number;
    /**
     * The speed in meter per second or null, if not available.
     */
    speed?: number;
    /**
     * The bearing in degree or null, if not available.
     */
    bearing?: number;
    /**
     * The altitude in meters or null, if not available.
     */
    altitude?: number;
    /**
     * The accuracy in meters or null, if not available.
     */
    accuracy?: number;
    /**
     * The provider name or null, if not available.
     */
    provider?: string;
    /**
     * A boolean value to mark the update as sharable for research purposes.
     */
    sharable: boolean;
    /**
     * A boolean value to mark the update as analyzable to compute places and visits.
     */
    analyzable: boolean;
    /**
     * Additional data about the position such as readings from other sensors.
     */
    metadata?: { [key: string]: string; };
}
export namespace ReleasedLocation {
    export type AvailabilityEnum = 'FRESH' | 'CACHED' | 'UNABLE' | 'PRIVATE' | 'IDLE';
    export const AvailabilityEnum = {
        FRESH: 'FRESH' as AvailabilityEnum,
        CACHED: 'CACHED' as AvailabilityEnum,
        UNABLE: 'UNABLE' as AvailabilityEnum,
        PRIVATE: 'PRIVATE' as AvailabilityEnum,
        IDLE: 'IDLE' as AvailabilityEnum
    };
}
