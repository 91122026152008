<div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="center start" fxLayoutAlign.lt-md="center center" fxLayoutGap="10px" style="margin: 20px;" >
        <mat-card class="mat-typography" style="width:400px">
            <mat-card-title-group>
                <mat-card-title>{{ res.strings('change_reset_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('change_reset_subtitle') }}</mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                <form #changeForm="ngForm">
                    <app-password-field (keyup.enter)="changeForm.valid && onChange()"></app-password-field>
                </form>
            </mat-card-content>
            <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="idle | async as submittable;else progressBar">
                    <button mat-raised-button color="accent" [disabled]="!submittable || resetForm.invalid" (click)="onChange()">
                        <mat-icon class="icon-button">lock_open</mat-icon>
                        <span>{{ res.strings('button_change') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBar>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-card-actions>
        </mat-card>
    </div>