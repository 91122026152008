import { MatTable } from '@angular/material/table';
import { ElementRef } from '@angular/core';

export interface TableColumn {
    name: string;
    width: number;
    fixedWidth?: boolean;
    dropPriority: number;
}

export class TableHandler {

    private tableWidth: number = null;
    private displayColumns: string[] = null;
    private minWidth: number;
    private columnsMap: Map<string, TableColumn> = new Map<string, TableColumn>();


    constructor(
        private table: ElementRef,
        private columns: TableColumn[],
        private fallback?: TableColumn[]) {
        // compute the min width once before going to fallback, if available
        this.minWidth = 0;
        for (const c of columns) {
            if (c.dropPriority < 0) { c.dropPriority = 0; }
            if (c.dropPriority === 0) { this.minWidth += c.width; }
            this.columnsMap.set(c.name, c);
        }
        if (fallback) {
            for (const c of fallback) {
                this.columnsMap.set(c.name, c);
            }
        }
    }

    onCheck(): void {
        if (this.table.nativeElement.clientWidth !== this.tableWidth) {
            this.displayColumns = null;
        }
    }

    getColumnStyle(name: string): any {
        const c = this.columnsMap.get(name);
        if (c == null) { return { }; }
        if (c.fixedWidth) {
            return { 'width': c.width + 'px', 'min-width': c.width + 'px', 'max-width': c.width + 'px'};
        } else {
            return { 'flex': '1 1 ' + c.width + 'px'};
        }
    }

    getDisplayColumns(): string[] {
        if (this.displayColumns == null) {
            this.displayColumns = this.computeColumns();
        }
        return this.displayColumns;
    }

    private computeColumns(): string[] {
        this.tableWidth = this.table.nativeElement.clientWidth;
        // get the columns to process (if too small use fallback)
        const selectedColumns: TableColumn[] = [];
        const targetColumns: TableColumn[] = (this.fallback && (this.minWidth > this.tableWidth)) ? this.fallback : this.columns;
        // sort the copy by priority
        const columns = targetColumns.slice();
        columns.sort((c1, c2) => c1.dropPriority - c2.dropPriority);
        // process columns
        let currentWidth = 0;
        while (columns.length > 0) {
            // for each priority, compute the length and the required width
            let requiredWidth = 0;
            const priority: number = columns[0].dropPriority;
            let index = 0;
            for (const c of columns) {
                if (c.dropPriority === priority) {
                    requiredWidth += c.width;
                    index += 1;
                } else {
                    break;
                }
            }
            // now check whether everything fits, and insert the columns as selection
            if (priority === 0 || this.tableWidth >= (currentWidth + requiredWidth)) {
                for (const c of columns.splice(0, index)) {
                    selectedColumns.push(c);
                }
                currentWidth += requiredWidth;
            } else {
                // or stop processing
                break;
            }
        }
        // sort the result by the original ordering
        selectedColumns.sort((c1, c2) => targetColumns.indexOf(c1) - targetColumns.indexOf(c2));
        // get the name only
        const result: string[] = [];
        for (const c of selectedColumns) {
            result.push(c.name);
        }
        return result;
    }


}
