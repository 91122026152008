import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordFieldComponent } from '../widgets/password-field.component';
import { HttpStatusCode } from '../utils/httpcodes';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { DefaultService } from '../api';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageDialogComponent } from '../widgets/message-dialog.component';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-change',
    templateUrl: './change.component.html'
})
export class ChangeComponent implements OnInit, OnDestroy {

    @ViewChild(PasswordFieldComponent, { static: true }) passwordField: PasswordFieldComponent;
    @ViewChild('changeForm', { static: true }) resetForm: NgForm;

    readonly idle = new BehaviorSubject<boolean>(true);

    private changeSubscription: Subscription;
    private token: string;

    constructor(
        public res: ResourceService,
        private apiClientService: DefaultService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
    }

    onChange(): void {
        this.snackbar.dismiss();
        if (!this.idle || !this.resetForm.valid) { return; }
        this.setIdle(false);
        this.changeSubscription = this.apiClientService.resetUserPassword
          (this.token, { password: this.passwordField.password }).subscribe((result) => {
            this.setIdle(true);
            MessageDialogComponent.open(this.dialog, {
                title: this.res.strings('change_reset_success_title'),
                message: this.res.strings('change_reset_success_message'),
                button: this.res.strings('button_gotit') }).subscribe((close) => {
                this.router.navigate(['/']);
            });
        }, (error) => {
            this.setIdle(true);
            let message: string = this.res.strings('change_reset_error_message');
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case HttpStatusCode.BAD_REQUEST:
                        message = this.res.strings('change_reset_error_badlink');
                        break;
                    case HttpStatusCode.FORBIDDEN:
                        message = this.res.strings('change_reset_error_expiredlink');
                        break;
                    default:
                        break;
                }
            }
            MessageDialogComponent.open(this.dialog, {
                title: this.res.strings('change_reset_error_title'),
                message: message,
                button: this.res.strings('button_gotit') }).subscribe((close) => {
                this.router.navigate(['/']);
            });
        });
    }

    private setIdle(value: boolean) {
        this.passwordField.disabled = !value;
        this.idle.next(value);
    }

    ngOnDestroy(): void {
        this.snackbar.dismiss();
        if (this.changeSubscription) { this.changeSubscription.unsubscribe(); }
    }


}
