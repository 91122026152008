import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { DefaultService, Device, Place, Visit } from '../api';
import { CollectionViewer } from '@angular/cdk/collections';

/**
 * A service that handles visits for a particular device and time.
 */
@Injectable()
export class DeviceVisitService implements DataSource<Visit> {

    readonly device = new BehaviorSubject<Device>(null);
    readonly loading = new BehaviorSubject<boolean>(false);
    readonly date = new BehaviorSubject<Date>(new Date());
    private elements = new BehaviorSubject<Visit[]>([]);
    private places: Map<number, Place> = new Map<number, Place>();
    private loaders = 0;

    private id: number;
    private subscriptions = 0;

    constructor(private apiClientService: DefaultService) {
    }

    getVisits(): Visit[] {
        return this.elements.getValue();
    }

    setDevice(id: number) {
        if (this.loading.getValue()) { return; }
        this.loading.next(true);
        this.device.next(null);
        this.elements.next([]);
        this.id = id;
        if (id != null) {
            this.apiClientService.getDevice(id).subscribe((result) => {
                this.device.next(result);
                this.loading.next(false);
                this.setDate(this.date.getValue());
            }, (error) => {
                this.id = null;
                this.loading.next(false);
            });
        }
    }

    setDate(date: Date) {
        if (this.loading.getValue()) { return; }
        this.loading.next(true);
        const d = (date == null) ? new Date() : new Date(date);
        d.setHours(0, 0, 0, 0);
        const millis = d.getTime();
        this.date.next(d);
        this.elements.next([]);
        this.apiClientService.getDeviceVisitsRange(this.id, millis, 24 * 60 * 60 * 1000).subscribe((result) => {
            this.elements.next(result);
            this.loadPlaces();
        }, (error) => {
            this.elements.next([]);
            this.loading.next(false);
        });
    }

    getPlace(id: number): Place {
        return this.places.get(id);
    }


    private loadPlaces() {
        const ids: number[] = new Array();
        for (const v of this.elements.getValue()) {
            if (this.getPlace(v.place) == null) {
                ids.push(v.place);
            }
        }
        if (ids.length === 0) {
            this.loading.next(false);
        } else {
            this.loaders = ids.length;
            for (const v of ids) {
                this.apiClientService.getPlace(v).subscribe((result) => {
                    this.finishLoading(v, result);
                }, (error) => {
                    this.finishLoading(v, null);
                });
            }
        }
    }

    private finishLoading(id: number, place: Place) {
        if (place) { this.places.set(id, place); }
        this.loaders -= 1;
        if (this.loaders === 0) {
            this.loading.next(false);
        }
    }

    connect(collectionViewer: CollectionViewer): Observable<Visit[]> {
        this.subscriptions += 1;
        return this.elements;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subscriptions -= 1;
    }

}
