<app-marker-map>
    <mat-card class="sidebar" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-top:10px">
        <mat-card-title>
            <mat-icon style="font-size: 18px;">linear_scale</mat-icon>{{ res.strings('positions_title') }}
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content style="padding-top: 20px;">
            <ng-container *ngIf="device | async as d">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('positions_manufacturer') }}</span>
                    <span class="detail-value">{{ d.manufacturer }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('positions_model') }}</span>
                    <span class="detail-value">{{ d.model }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('positions_os') }}</span>
                    <span class="detail-value">{{ d.os }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('positions_creation') }}</span>
                    <span class="detail-value">{{ d.creationTime | date:'short' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('positions_modification') }}</span>
                    <span class="detail-value">{{ d.modificationTime | date:'short' }}</span>
                </div>
            </ng-container>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
            <ng-container *ngIf="!loading.getValue();else progressBarDevice">
                    <button mat-icon-button [matTooltip]="res.strings('positions_previous_minute_tooltip')"
                    [disabled]="cannotSlidePrevious()" (click)="onIncrementSlider(-1)">
                    <mat-icon>chevron_left</mat-icon>
                </button>    
                <mat-slider min="0" max="1440" [(ngModel)]="sliderValue" [displayWith]="getSliderFormat" thumbLabel tickInterval="1" (input)="onSliderChange($event)" [disabled]="isSliderDisabled()" style="width: 220px"></mat-slider>
                <button mat-icon-button [matTooltip]="res.strings('positions_next_minute_tooltip')"
                    [disabled]="cannotSlideNext()" (click)="onIncrementSlider(1)">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </ng-container>
            <ng-template #progressBarDevice>
                <mat-spinner [diameter]="36"></mat-spinner>
            </ng-template>
        </mat-card-actions>
        <mat-card-footer fxFlexLayout="row" fxLayoutAlign="space-around center">
            <button mat-icon-button [matTooltip]="res.strings('positions_previous_day_tooltip')"
                [disabled]="loading.getValue() || !hasPreviousDay()" (click)="onPreviousDay()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <mat-form-field style="margin-bottom:0px;width:120px">
                <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [(value)]="selectedDate"
                    (dateInput)="onDateChange($event)" [placeholder]="res.strings('button_update')" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="loading.getValue()" #picker touchUi="true"></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button [matTooltip]="res.strings('positions_next_day_tooltip')"
                [disabled]="loading.getValue() || !hasNextDay()" (click)="onNextDay()">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="reload()" [matTooltip]="res.strings('button_update')">
                    <mat-icon class="icon-button">update</mat-icon>
                </button>
        </mat-card-footer>
    </mat-card>
    <mat-card class="sidebar" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-top:10px">
        <mat-card-title style="font-size: 14px;text-align: center;">
            {{ res.strings('devices_position_chart_header') }}
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content style="padding-top: 10px;">
            <app-chart [width]="300" [height]="300" 
                       [xAxisConfig]="getXAxisConfig()"
                       [yAxisConfig]="getYAxisConfig()"
                       [horizontalGrid]="true"
                       [verticalGrid]="true"></app-chart>
        </mat-card-content>
    </mat-card>
</app-marker-map>