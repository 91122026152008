import { Injectable } from '@angular/core';


/** An interface to model a language. */
export interface Language {
  /** The 2 letter code. */
  readonly value: string;
  /** The title. */
  title: string;
  /** The reference to a flag icon. */
  readonly flag: string;
}


/**
 * A console that can be used to log debug output
 * throughout the application.
 */
@Injectable()
export class ResourceService {

  private values_de = {
    'language': 'de',
    // buttons
    'button_save' : 'Speichern',
    'button_change' : 'Ändern',
    'button_add': 'Hinzufügen',
    'button_update': 'Aktualisieren',
    'button_gotit': 'Verstanden',
    'button_reset': 'Zurücksetzen',
    'button_register' : 'Registrieren',
    'button_login' : 'Anmelden',
    'button_yes' : 'Ja',
    'button_no' : 'Nein',
    'button_ok' : 'OK',
    'button_cancel' : 'Abbrechen',
    'button_close': 'Schließen',
    'button_logout': 'Abmelden',
    'button_delete' : 'Löschen',
    'button_export' : 'Exportieren',
    // components
    'email_field_hint': 'Emailadresse eingeben',
    'email_field_placeholder': 'Emailadresse',
    'email_field_required': 'Deine Emailadresse ist erforderlich.',
    'email_field_pattern': 'Deine Emailadresse ist ungültig.',
    'language_select_placeholder': 'Sprache',
    'log_dialog_title': 'Logeintrag',
    'log_dialog_id': 'ID',
    'log_dialog_thread': 'Thread',
    'log_dialog_time': 'Zeit',
    'log_dialog_level': 'Level',
    'log_dialog_logger': 'Logger',
    'log_dialog_class': 'Klasse',
    'log_dialog_method': 'Methode',
    'log_dialog_message': 'Nachricht',
    'log_dialog_trace': 'Trace',
    'login_field_hint': 'Kontoname eingeben',
    'login_field_placeholder': 'Kontoname',
    'login_field_required': 'Der Kontoname ist erforderlich.',
    'login_field_pattern': 'Der Kontoname enthält ein ungültiges Zeichen.',
    'login_field_minlength': 'Der Kontoname ist zu kurz.',
    'name_field_hint': 'Name eingeben',
    'name_field_placeholder': 'Name',
    'name_field_required': 'Dein Name ist erforderlich.',
    'name_field_pattern': 'Dein Name enthält ein ungültiges Zeichen.',
    'nickname_dialog_title': 'Ort benennen',
    'nickname_dialog_subtitle': 'Gib einen Namen für den Ort ein.',
    'nickname_dialog_hint': 'Name eingeben',
    'nickname_dialog_placeholder': 'Name',
    'nickname_dialog_pattern': 'Dein Name enthält ein ungültiges Zeichen.',
    'nickname_dialog_success': 'Der Ort wurde erfolgreich umbenannt.',
    'nickname_dialog_conflict': 'Der Ort wurde bereits verändert. Falls Du die Änderung überschreiben möchtest, gib den Namen nochmal ein.',
    'nickname_dialog_failure': 'Der Ort konnte nicht umbennant werden.',
    'notice_card_creation': 'Veröffentlicht am',
    'notice_card_modification': 'Aktualisiert am',
    'password_field_hint': 'Passwort eingeben',
    'password_field_placeholder': 'Passwort',
    'password_field_required': 'Das Passwort ist erforderlich.',
    'password_field_minlength': 'Das Passwort ist zu kurz.',
    'password_field_show': 'Passwort anzeigen',
    'password_field_hide': 'Passwort verbergen',
    'share_dialog_required': 'Ein Freigabelink ist erforderlich.',
    'share_dialog_pattern': 'Der Freigabelink ist ungültig.',
    'share_dialog_title': 'Freigabe hinzufügen',
    'share_dialog_subtitle': 'Kopiere einen Freigabelink in das Feld um ihn zu speichern.',
    'share_dialog_hint': 'Freigabelink eingeben',
    'share_dialog_placeholder': 'Freigabelink',
    'share_dialog_success': 'Die Freigabe wurde erfolgreich hinzugefügt.',
    'share_dialog_error': 'Die Freigabe konnte nicht erstellt werden. Hast Du den Freigabelink vollständig eingegeben?',
    'tile_map_attribution': 'Kartendaten: <a href="https://www.openstreetmap.de">© OpenStreetMap</a> '
        + '| Visualisierung: <a href="https://www.locoslab.com/de/">© Locoslab GmbH</a>',
    // views
    'export_title' : 'Export',
    'export_explain_title' : 'Daten exportieren',
    'export_explain_subtitle' : 'Hier kannst Du Deine Orte und Besuche exportieren.',
    'export_explain_content' : 'Deine Daten gehören Dir. Damit Du sie in einer anderen Anwendung nutzen kannst, '
        + 'kannst Du sie hier exportieren.',
    'export_explain_process' : 'Wenn Du den Export startest, lädt Dein Browser zunächst alle Orte und Besuche herunter. '
        + 'Sobald der Download abgeschlossen ist, wird eine Datei mit den Daten generiert und ein automatischer Download ausgelöst.',
    'export_download_title' : 'Fortschritt ansehen',
    'export_download_subtitle' : 'Hier siehst Du den Downloadfortschritt Deines Datenexports.',
    'export_devices' : 'Geräte',
    'export_visits' : 'Besuche',
    'export_places' : 'Orte',
    'export_network_error' : 'Download fehlgeschlagen. Hast Du gerade kein Netz?',

    'user_title': 'Konto',
    'user_change_title' : 'Informationen ändern',
    'user_change_subtitle' : 'Hier kannst Du Deine Kontaktinformationen ändern.',
    'user_change_primary' : 'Primäre Emailadresse',
    'user_password_title' : 'Passwort ändern',
    'user_password_subtitle' : 'Hier kannst Du Dein Passwort ändern.',
    'user_password_old' : 'Altes Passwort',
    'user_password_old_hint' : 'Altes Passwort eingeben',
    'user_password_new' : 'Neues Passwort',
    'user_password_new_hint' : 'Neues Passwort eingeben',
    'user_delete_title' : 'Konto löschen',
    'user_delete_subtitle' :  'Hier kannst Du dein Konto vollständig löschen.',
    'user_delete_password' : 'Passwort',
    'user_delete_password_hint' : 'Passwort eingeben',
    'user_delete_admin_title' : 'Löschen nicht möglich',
    'user_delete_admin_message' : 'Dein Konto hat erweiterte Berechtigungen und kann deshalb nicht von Dir selbst gelöscht werden.',
    'user_delete_confirm_title' : 'Konto löschen?',
    'user_delete_confirm_message' : 'Möchtest Du Dein Konto wirklich löschen? Diesen Vorgang kannst Du nicht mehr rückgängig machen.',
    'user_delete_success' : 'Dein Konto wurde erfolgreich gelöscht.',
    'user_add_email_title' : 'Emailadresse hinzufügen',
    'user_add_email_subtitle' : 'Hier kannst Du eine neue Emailadresse registrieren.',
    'user_del_email_title' : 'Emailadresse löschen',
    'user_del_email_subtitle' : 'Hier kannst Du ungenutze Emailadressen löschen.',
    'user_del_email_tooltip' : 'Emailadresse löschen',
    'user_email_registered_already' : 'Die Emailadresse ist bereits registriert.',
    'user_password_wrong_title' : 'Passwort falsch',
    'user_password_wrong_message' : 'Dein derzeitiges Passwort stimmt nicht mit Deiner Eingabe überein.'
        + ' Bitte prüfe Deine Eingabe und versuchs nochmal.',
    'share_title' : 'Freigabe',
    'share_no_update_info' : 'Die Freigabe konnte nicht abgerufen werden.',
    'share_no_update_location' : 'Der Standort konte nicht abgerufen werden.',
    'share_token' : 'Schlüssel',
    'share_name' : 'Name',
    'share_message' : 'Nachricht',
    'share_destination' : 'Ziel',
    'share_expiration' : 'Ablauf',
    'share_location' :  'Standort',
    'share_time' : 'Zeit',
    'share_availability' : 'Status',
    'share_longitude' : 'Längengrad',
    'share_latitude' : 'Breitengrad',
    'share_add_tooltip' : 'Zu Konto hinzufügen',
    'reset_confirm_title' : 'Passwort zurückgesetzt',
    'reset_confirm_message' : 'Wir haben Dir einen Link an Deine Kontaktemailadresse versandt, '
        + 'mit dem Du Dein Passwort ändern kannst. Falls Dir Dein Passwort wieder einfällt, kannst Du die Email einfach löschen.',
    'reset_account_error' : 'Konto nicht gefunden. Bitte prüfe Deinen Kontonamen und versuchs nochmal.',
    'reset_network_error' : 'Fehler beim Zurücksetzen. Hast Du gerade kein Netz?',
    'reset_title' : 'Passwort zurücksetzen',
    'reset_subtitle' : 'Gib Deinen Kontonamen an um Dein Passwort zurückzusetzen.',
    'register_accept_terms_title' : 'Nutzungsbedingungen',
    'register_accept_terms_message' : 'Um Dein Konto zu beantragen musst Du zuerst den Nutzungsbedingungen zustimmen.',
    'register_success_title' : 'Einrichtung erfolgreich',
    'register_success_message' : 'Das Konto wurde eingerichtet. Bevor Du Dein Konto nutzen kannst, musst Du es aktivieren. '
        + 'Wir haben Dir eine Email mit einem Aktivierungslink zugeschickt. Bitte sieh in Deinem Postfach nach. '
        + 'Falls die Email lange nicht ankommt, schau auch mal in Deinen Spamordner!',
    'register_conflict_error' : 'Der Kontoname ist bereits vergeben. Such Dir bitte einen anderen aus.',
    'register_network_error' : 'Fehler bei der Registrierung. Hast Du gerade kein Netz?',
    'register_title' : 'Registrieren',
    'register_subtitle' : 'Gib Deine Daten ein und lege ein neues Konto an.',
    'register_terms_pre' : 'Ich bin volljährig, habe die',
    'register_terms_mid' : 'gelesen und stimme den',
    'register_terms_end' : 'zu.',
    'register_terms_service' : 'Nutzungsbedingungen',
    'register_terms_policy' : 'Datenschutzerklärung',
    'register_already_title' : 'Du hast bereits ein Konto?',
    'register_already_subtitle' : 'Kein Problem!',
    'register_already_paragraph' : 'Geh einfach zur Anmeldung und gib Deinen Kontonamen und Dein Passwort ein. '
        + 'Dann kannst Du sofort auf Deinen Standort und Deine besuchten Orte zugreifen!',
    'register_why_title': 'Warum registrieren?',
    'register_why_subtitle': 'Du musst nicht!',
    'register_why_paragraph': 'Viele Funktionen von Maptology lassen sich auch ohne Registrierung nutzen. '
        + 'Falls Du zum Beispiel Deinen Standort teilen möchtest, brauchst Du nur die mobile Anwendung. '
        + 'Zum Ansehen von Freigaben genügt sogar Dein Webbrowser.',
    'register_why_moreinfo': 'Mehr Infos',
    'privileges_delete_confirm_title': 'Freigabe löschen?',
    'privileges_delete_confirm_message': 'Möchtest Du die Freigabe wirklich löschen? '
        + 'Diesen Vorgang kannst Du nicht mehr rückgängig machen.',
    'privileges_delete_success': 'Die Freigabe wurde erfolgreich gelöscht.',
    'privileges_delete_error': 'Die Freigabe konnte nicht gelöscht werden. Hast Du gerade kein Netz?',
    'privileges_title': 'Freigaben',
    'privileges_add_tooltip': 'Freigabe hinzufügen',
    'privileges_id': 'ID',
    'privileges_name' : 'Name',
    'privileges_nobody' : 'Anonymer Nutzer',
    'privileges_token': 'Schlüssel',
    'privileges_creation': 'Erstellung',
    'privileges_message': 'Nachricht',
    'privileges_destination': 'Ziel',
    'privileges_expiration': 'Ablauf',
    'privileges_actions' : 'Aktionen',
    'privileges_show_tooltip' : 'Freigabe anzeigen',
    'privileges_delete_tooltip' : 'Freigabe löschen',
    'places_delete_confirm_title': 'Ort löschen?',
    'places_delete_confirm_messages': 'Möchtest Du den Ort wirklich löschen? Dieser Vorgang löscht auch alle Besuche an diesem Ort '
        + 'und Du kannst ihn nicht mehr rückgängig machen.',
    'places_delete_success': 'Der Ort wurde erfolgreich gelöscht.',
    'places_delete_error': 'Der Ort konnte nicht gelöscht werden. Hast Du gerade kein Netz?',
    'places_title': 'Orte',
    'places_id': 'Nr.',
    'places_address': 'Adresse',
    'places_name': 'Name',
    'places_nickname': 'Name',
    'places_longitude': 'Längengrad',
    'places_latitude': 'Breitengrad',
    'places_creation': 'Erstellung',
    'places_modification': 'Änderung',
    'places_actions': 'Aktionen',
    'places_rename_tooltip': 'Ort benennen',
    'places_visits_tooltip': 'Besuche anzeigen',
    'places_delete_tooltip': 'Ort löschen',
    'places_order_placeholder' : 'Reihenfolge',
    'places_order_value_identifier' : 'Frühste Erstellung',
    'places_order_value_time' : 'Letzter Besuch',
    'places_order_value_frequency' : 'Höchste Besuchsanzahl',
    'places_order_value_duration' : 'Längste Besuchsdauer',
    'places_order_value_importance' : 'Höchste Bedeutung',
    'visits_delete_confirm_title' : 'Besuch löschen?',
    'visits_delete_confirm_message' : 'Möchtest Du den Besuch wirklich löschen? Diesen Vorgang kannst Du nicht mehr rückgängig machen.',
    'visits_delete_success' : 'Der Besuch wurde erfolgreich gelöscht.',
    'visits_delete_error': 'Der Besuch konnte nicht gelöscht werden. Hast Du gerade kein Netz?',
    'visits_place_load_error': 'Der Ort konnte nicht geladen werden.',
    'visits_place': 'Ort',
    'visits_id': 'Nr.',
    'visits_manufacturer': 'Hersteller',
    'visits_model': 'Modell',
    'visits_os': 'Betriebssystem',
    'visits_creation': 'Registrierung',
    'visits_modification': 'Aktualisierung',
    'visits_nickname' : 'Name',
    'visits_address' : 'Adresse',
    'visits_longitude' : 'Längengrad',
    'visits_latitude' : 'Breitengrad',
    'visits_visits' : 'Besuche',
    'visits_begin' : 'Beginn',
    'visits_end' : 'Ende',
    'visits_device' : 'Gerät',
    'visits_visit' : 'Besuch',
    'visits_from' : 'Von',
    'visits_to' : 'Bis',
    'visits_actions' : 'Aktionen',
    'visits_device_tooltip' : 'Gerät anzeigen',
    'visits_delete_tooltip' : 'Besuch löschen',
    'visits_map_tooltip' : 'Ort auf Karte zentrieren',
    'visits_place_tooltip': 'Besuche für Ort anzeigen',
    'visits_previous_tooltip': 'Vorheriger Tag',
    'visits_next_tooltip': 'Nächster Tag',
    'visits_day_placeholder': 'Tag auswählen',
    'positions_title': 'Aktualisierungen',
    'positions_manufacturer': 'Hersteller',
    'positions_model': 'Modell',
    'positions_os': 'Betriebssystem',
    'positions_creation': 'Registrierung',
    'positions_modification': 'Aktualisierung',
    'positions_previous_day_tooltip': 'Vorheriger Tag',
    'positions_next_day_tooltip': 'Nächster Tag',
    'positions_previous_minute_tooltip': 'Vorherige Minute',
    'positions_next_minute_tooltip': 'Nächste Minute',
    'positions_day_placeholder': 'Tag auswählen',
    'notices_title' : 'Neues',
    'manage_users_success' : 'Die Kontoänderung wurde erfolgreich durchgeführt.',
    'manage_users_error' : 'Die Kontoänderung konnte nicht durchgeführt werden.',
    'manage_users_title' : 'Nutzer',
    'manage_users_term_placeholder' : 'Suchbegriff',
    'manage_users_term_required' : 'Ein Suchbegriff ist erforderlich.',
    'manage_users_term_pattern' : 'Dein Suchbegriff enthält ein ungültiges Zeichen.',
    'manage_users_search_tooltip' : 'Nutzer suchen',
    'manage_users_id' : 'ID',
    'manage_users_type' : 'Typ',
    'manage_users_login': 'Kontoname',
    'manage_users_email' : 'Emailadresse',
    'manage_users_name' : 'Nutzername',
    'manage_users_creation' : 'Registrierung',
    'manage_users_modification' : 'Aktualisierung',
    'manage_users_actions' : 'Aktionen',
    'manage_users_restore_tooltip' : 'Konto wiederherstellen',
    'manage_users_delete_tooltip' : 'Konto löschen',
    'manage_users_user_tooltip' : 'Zum Nutzer machen',
    'manage_users_admin_tooltip' : 'Zum Administrator machen',
    'manage_users_scientist_tooltip' : 'Zum Wissenschaftler machen',
    'manage_users_user': 'Nutzer',
    'manage_users_admin': 'Administrator',
    'manage_users_scientist' : 'Wissenschaftler',
    'manage_system_title': 'System',
    'manage_system_gbs' : 'Gigabytes',
    'manage_system_gb' : 'Gigabyte',
    'manage_system_mbs' : 'Megabytes',
    'manage_system_mb' : 'Megabyte',
    'manage_system_days' : 'Tage',
    'manage_system_day' : 'Tag',
    'manage_system_hours' : 'Stunden',
    'manage_system_hour' : 'Stunde',
    'manage_system_minutes' : 'Minuten',
    'manage_system_minute' : 'Mintue',
    'manage_system_seconds' : 'Sekunden',
    'manage_system_second' : 'Sekunde',
    'manage_system_load_title' : 'Auslastung',
    'manage_system_load_subtitle' : 'Zeigt die durchschnittliche CPU-Auslastung der letzten Minute an.',
    'manage_system_uptime' : 'Betriebszeit',
    'manage_system_memory_title': 'Speicher',
    'manage_system_memory_subtitle': 'Zeigt den genutzen Haldenspeicher zum Zeitpunkt der Anfrage an.',
    'manage_system_max_heap': 'Maximale Haldengröße',
    'manage_system_equipment_title': 'Ausstattung',
    'manage_system_equipment_subtitle': 'Zeigt die Hardware- und Softwareausstattung des Rechners an.',
    'manage_system_os': 'Betriebssystem',
    'manage_system_os_name': 'Name',
    'manage_system_os_architecture': 'Architektur',
    'manage_system_os_version': 'Version',
    'manage_system_jvm': 'Java Virtual Machine',
    'manage_system_jvm_name': 'Name',
    'manage_system_jvm_specification': 'Spezifikation',
    'manage_system_jvm_vendor': 'Hersteller',
    'manage_system_jvm_version': 'Version',
    'manage_system_gc': 'Garbage Collector',
    'manage_system_gc_count' : 'Ausführungen',
    'manage_system_gc_duration': 'Dauer',
    'manage_system_power': 'Rechenleistung',
    'manage_system_processors': 'Prozessoren',
    'manage_system_data_title': 'Datenbestand',
    'manage_system_data_subtitle': 'Zeigt die vorhandenen Datenbankeinträge für alle Entitäten an.',
    'manage_system_data_entity': 'Entität',
    'manage_system_data_sum': 'Summe',
    'manage_system_data_active': 'Aktiv',
    'manage_system_data_deleted': 'Gelöscht',
    'manage_notices_change_success': 'Die Änderung wurde erfolgreich durchgeführt.',
    'manage_notices_change_error': 'Die Änderung konnte nicht durchgeführt werden.',
    'manage_notices_title': 'Hinweise',
    'manage_notices_id': 'ID',
    'manage_notices_languages': 'Sprachen',
    'manage_notices_creation': 'Erstellung',
    'manage_notices_modification': 'Aktualisierung',
    'manage_notices_expiration': 'Ablauf',
    'manage_notices_header': 'Titel',
    'manage_notices_published': 'Veröff.',
    'manage_notices_announced': 'Ank. (Min/Max)',
    'manage_notices_actions': 'Aktionen',
    'manage_notices_restore_tooltip': 'Hinweis wiederherstellen',
    'manage_notices_delete_tooltip': 'Hinweis löschen',
    'manage_notices_edit_tooltip': 'Hinweis bearbeiten',
    'manage_logs_title': 'Logs',
    'manage_logs_connect_tooltip': 'Verbindung herstellen',
    'manage_logs_disconnect_tooltip': 'Verbindung beenden',
    'manage_logs_clear_tooltip': 'Einträge löschen',
    'manage_logs_id': 'ID',
    'manage_logs_time': 'Zeit',
    'manage_logs_thread': 'Thread',
    'manage_logs_level': 'Stufe',
    'manage_logs_class': 'Klasse',
    'manage_logs_method': 'Methode',
    'manage_logs_message': 'Nachricht',
    'manage_logs_trace': 'Trace',
    'manage_logs_trace_tooltip': 'Stacktrace vorhanden',
    'manage_logs_actions': 'Aktionen',
    'manage_logs_details_tooltip': 'Details anzeigen',
    'login_login_title': 'Anmelden',
    'login_login_subtitle': 'Mit Deinem Konto kannst Du hier auf Deine Daten zugreifen.',
    'login_login_reset': 'Passwort vergessen?',
    'login_build_version': 'Buildversion',
    'login_build_time': 'Buildzeit',
    'login_register_title': 'Noch kein Konto?',
    'login_register_subtitle': 'Kein Problem!',
    'login_register_paragraph': 'Hol Dir einfach ein Konto und registriere Dein Gerät. '
      + 'Dann kannst Du von überall aus auf Deinen Standort und Deine besuchten Orte zugreifen!',
    'edit_notice_load_error': 'Der Hinweis konnte nicht geladen werden.',
    'edit_notice_save_success': 'Der Hinweis wurde erfolgreich gespeichert.',
    'edit_notice_save_error': 'Der Hinweis konnte nicht gespeicher werden.',
    'edit_notice_title_new': 'Erstellen',
    'edit_notice_title_edit': 'Bearbeiten',
    'edit_notice_save_tooltip': 'Hinweis speichern',
    'edit_notice_close_tooltip': 'Hinweis schließen',
    'edit_notice_content_title': 'Inhalt',
    'edit_notice_content_subtitle': 'Hier kannst Du den Inhalt des Hinweises ändern.',
    'edit_notice_title_placeholder': 'Titel eingeben',
    'edit_notice_body_placeholder': 'Inhalt eingeben',
    'edit_notice_add_language_tooltip': 'Sprache hinzufügen',
    'edit_notice_settings_title': 'Einstellungen',
    'edit_notice_settings_subtitle': 'Hier kannst Du die Art der Veröffentlichung einstellen.',
    'edit_notice_settings_time_placeholder': 'Ablaufzeit festlegen',
    'edit_notice_settings_date_placeholder': 'Ablaufdatum auswählen',
    'edit_notice_settings_publish': 'Veröffentlichen',
    'edit_notice_settings_announce': 'Ankündigen',
    'edit_notice_settings_min_version': 'Minimale Version',
    'edit_notice_settings_max_version': 'Maximale Version',
    'edit_notice_creation': 'Erstellung',
    'edit_notice_modification': 'Aktualisierung',
    'devices_delete_confirm_title': 'Gerät löschen?',
    'devices_delete_confirm_message': 'Möchtest Du das Gerät wirklich löschen? Dieser Vorgang löscht auch die Besuche des Geräts '
        + 'und Du kannst ihn nicht mehr rückgängig machen.',
    'devices_delete_success': 'Das Gerät wurde erfolgreich gelöscht.',
    'devices_delete_error': 'Das Gerät konnte nicht gelöscht werden. Hast Du gerade kein Netz?',
    'devices_title': 'Geräte',
    'devices_id': 'Nr.',
    'devices_os': 'OS',
    'devices_manufacturer': 'Hersteller',
    'devices_model': 'Model',
    'devices_creation': 'Registrierung',
    'devices_modification': 'Aktualisierung',
    'devices_actions': 'Aktionen',
    'devices_location_tooltip': 'Standort anzeigen',
    'devices_history_tooltip': 'Verlauf anzeigen',
    'devices_position_tooltip': 'Aktualisierungen anzeigen',
    'devices_position_chart_header': 'Accelerometer',
    'devices_position_chart_y_axis_label': 'Daten',
    'devices_position_chart_x_axis_label': 'Sek.',
    'devices_position_chart_acceleration': 'Beschleunigung',
    'devices_delete_tooltip': 'Gerät löschen',
    'device_location_device_error': 'Die Geräteinformationen konnten nicht geladen werden.',
    'device_location_location_error': 'Der Standort konte nicht abgerufen werden.',
    'device_location_device': 'Gerät',
    'device_location_id' : 'ID',
    'device_location_manufacturer': 'Hersteller',
    'device_location_model': 'Model',
    'device_location_os': 'Betriebssystem',
    'device_location_creation': 'Registrierung',
    'device_location_modification': 'Aktualisierung',
    'device_location_time': 'Zeit',
    'device_location_availability': 'Status',
    'device_location_longitude': 'Längengrad',
    'device_location_latitude': 'Breitengrad',
    'device_location_location': 'Standort',
    'change_reset_success_title': 'Änderung erfolgreich',
    'change_reset_success_message': 'Dein Passwort wurde erfolgreich geändert. '
        + 'Du musst ab sofort immer Dein neues Passwort zur Anmeldung verwenden.',
    'change_reset_error_title': 'Änderung fehlgeschlagen',
    'change_reset_error_message': 'Die Passwortänderung konnte nicht abeschlossen werden. Hast Du gerade kein Netz?',
    'change_reset_error_badlink': 'Der Link zum Zurücksetzen ist ungültig. Falls Du ihn aus Deinem Emailprogramm '
        + 'in die Adressleiste Deines Browsers kopiert hast, stell sicher, dass der Link vollständig ist.',
    'change_reset_error_expiredlink': 'Der Link zum Zurücksetzen ist nicht mehr gültig. '
        + 'Entweder wurder er schon benutzt oder er ist abgelaufen.',
    'change_reset_title': 'Passwort ändern',
    'change_reset_subtitle': 'Gib einfach Dein neues Passwort ein.',
    'activate_title': 'Aktivierung',
    'activate_subtitle': 'Die Aktivierung wird abgeschlossen.',
    'activate_success_title': 'Aktivierung erfolgreich',
    'activate_success_message': 'Die Emailadresse wurde für Dein Konto aktiviert. Falls das die Emailadresse '
        + 'mit einem neuen Konto verbunden wurde, kannst Du das Konto ab sofort nutzen. Falls Du eine weitere '
        + 'Emailadresse für Dein Konto freigeschaltet hast, kannst Du sie jetzt in den Kontoeinstellungen als Kontaktadresse setzen.',
    'activate_error_title': 'Aktivierung fehlgeschlagen',
    'activate_error_message': 'Die Aktivierung konnte nicht abeschlossen werden. Hast Du gerade kein Netz?',
    'activate_error_badlink': 'Der Aktivierungslink ist ungültig. Falls Du ihn aus Deinem Emailprogramm in die '
        + 'Adressleiste Deines Browsers kopiert hast, stell sicher, dass der Link vollständig ist.',
    'activate_error_expiredlink': 'Der Aktivierungslink ist nicht mehr gültig. Entweder wurder er schon benutzt oder er ist abgelaufen.',
    // TODO: Time Sheet labels DE
    'time_sheet_update_btn_tooltip': 'Aktualisieren',
    'time_sheet_export_btn_tooltip': 'Exportieren',
    'time_sheet_export_as_csv_label': 'als CSV',
    'time_sheet_export_as_json_label': 'als JSON',
    'time_sheet_export_error_msg_title': 'Fehler!',
    'time_sheet_export_error_msg': 'Es ist ein unerwarteter Fehler aufgetreten. Der Export ist fehlgeschlagen.',
    'time_sheet_title': 'Stundenzettel',
    'time_sheet_table_row_dialog_title': 'Zeitinfo',
    'time_sheet_table_date': 'Datum',
    'time_sheet_table_from': 'von',
    'time_sheet_table_to': 'bis',
    'time_sheet_table_working_time': 'Arbeitszeit',
    'time_sheet_table_place': 'Arbeitsplatz',
    'time_sheet_table_device': 'Gerät',
    'time_sheet_config_label': 'Konfiguration',
    'time_sheet_toggle_default_label': 'Arbeit',
    'time_sheet_toggle_default_tooltip': 'Gedeckelte & zusammengefasste Arbeitszeit je Tag.',
    'time_sheet_toggle_acc_label': 'Normal',
    'time_sheet_toggle_acc_tooltip': 'Ungedeckelte & zusammengefasste Zeiteinträge je Tag.',
    'time_sheet_toggle_by_days_label': 'Nach Tagen',
    'time_sheet_toggle_by_days_tooltip': 'Alle Zeiteinträge je Tag.',
    'time_sheet_toggle_raw_label': 'Rohdaten',
    'time_sheet_toggle_raw_tooltip': 'Rohdaten',
    'time_sheet_select_device_label': 'Gerät',
    'time_sheet_select_device_tooltip': 'Filtert die Zeiteinträge auf das ausgewählte Gerät.',
    'time_sheet_no_device_selected': 'Keins',
    'time_sheet_break_time_label': 'Pausendauer',
    'time_sheet_break_time_tooltip': 'Gibt an, ab welcher Dauer die Abwesenheit vom Arbeitsplatz als Pausendauer '
        + 'zu werten ist und somit nicht zum Zeiteintrag hinzugerechnet wird.',
    'time_sheet_break_time_minutes': 'Minuten',
    'time_sheet_no_break_time_selected': 'Keine',
    'time_sheet_time_period_label': 'Zeitraum',
    'time_sheet_time_period_tooltip': 'Markiert den Zeitraum für die auszuwertendend Daten.',
    'time_sheet_workplace_button': 'Arbeitsplatz auswählen',
    'time_sheet_workplace_clear_list': 'Liste leeren',
    // layouts
    'drawer_navigation': 'Navigation',
    'drawer_settings': 'Einstellungen',
    'drawer_management': 'Verwaltung',
    'footer_rights_reserved': 'Alle Rechte vorbehalten',
    'footer_imprint': 'Impressum',
    'footer_terms' : 'Nutzungsbedingungen',
    'footer_licenses' : 'Open Source Lizenzen',
    // pagination provider
    'pagination_first_page': 'Erste Seite',
    'pagination_last_page': 'Letzte Seite',
    'pagination_next_page': 'Nächste Seite',
    'pagination_previous_page': 'Vorherige Seite',
    'pagination_items_per_page': 'Einträge pro Seite',
    'pagination_of': 'von',
    // language titles
    'lang_de': 'Deutsch',
    'lang_en': 'Englisch',
    'lang_fr': 'Französisch',
    'lang_it': 'Italienisch',
    'lang_es': 'Spanisch',
  };

  private values_en = {
    'language': 'en',
    // buttons
    'button_save' : 'Save',
    'button_change' : 'Change',
    'button_add': 'Add',
    'button_update': 'Update',
    'button_gotit': 'Got it',
    'button_reset': 'Reset',
    'button_register' : 'Register',
    'button_login' : 'Login',
    'button_yes' : 'Yes',
    'button_no' : 'No',
    'button_ok' : 'OK',
    'button_cancel' : 'Cancel',
    'button_close': 'Close',
    'button_logout': 'Logout',
    'button_delete' : 'Delete',
    'button_export' : 'Export',
    // components
    'email_field_hint': 'Enter email address',
    'email_field_placeholder': 'Email address',
    'email_field_required': 'Your email address is required.',
    'email_field_pattern': 'Your email address is invalid.',
    'language_select_placeholder': 'Language',
    'log_dialog_title': 'Log entry',
    'log_dialog_id': 'ID',
    'log_dialog_thread': 'Thread',
    'log_dialog_time': 'Time',
    'log_dialog_level': 'Level',
    'log_dialog_logger': 'Logger',
    'log_dialog_class': 'Class',
    'log_dialog_method': 'Method',
    'log_dialog_message': 'Message',
    'log_dialog_trace': 'Trace',
    'login_field_hint': 'Enter account name',
    'login_field_placeholder': 'Account name',
    'login_field_required': 'The account name is required.',
    'login_field_pattern': 'The account name contains an invalid character.',
    'login_field_minlength': 'The account name is too short.',
    'name_field_hint': 'Enter your name',
    'name_field_placeholder': 'Your name',
    'name_field_required': 'Your name is required.',
    'name_field_pattern': 'Your name contains an invalid character.',
    'nickname_dialog_title': 'Rename place',
    'nickname_dialog_subtitle': 'Enter a name for the place.',
    'nickname_dialog_hint': 'Enter name',
    'nickname_dialog_placeholder': 'Name',
    'nickname_dialog_pattern': 'The name contains an invalid character.',
    'nickname_dialog_success': 'The place was renamed successfully.',
    'nickname_dialog_conflict': 'The place was renamed already, to overwrite, rename the place again.',
    'nickname_dialog_failure': 'Failed to rename the place.',
    'notice_card_creation': 'Published on',
    'notice_card_modification': 'Modified on',
    'password_field_hint': 'Enter password',
    'password_field_placeholder': 'Password',
    'password_field_required': 'The password is required.',
    'password_field_minlength': 'The password is too short.',
    'password_field_show': 'Show password',
    'password_field_hide': 'Hide password',
    'share_dialog_required': 'A sharing link is required.',
    'share_dialog_pattern': 'The sharing link is invalid.',
    'share_dialog_title': 'Add share',
    'share_dialog_subtitle': 'Copy a sharing link into the field below to save it.',
    'share_dialog_hint': 'Enter sharing link',
    'share_dialog_placeholder': 'Sharing link',
    'share_dialog_success': 'The share was added successfully.',
    'share_dialog_error': 'Unable to add the sharing link. Did you type it in correctly?',
    'tile_map_attribution': 'Map data: <a href="https://www.openstreetmap.org">© OpenStreetMap</a> '
        + '| Visualization: <a href="https://www.locoslab.com/en/">© Locoslab GmbH</a>',
    // views
    'export_title' : 'Export',
    'export_explain_title' : 'Export data',
    'export_explain_subtitle' : 'Here you can export your places and visits.',
    'export_explain_content' : 'Your data belongs to you. To use you data in another application, you can export it here.',
    'export_explain_process' : 'Once you start the export, your browser will download all places and visits. '
        + 'When the download is complete, your browser will generate a file containing the data and triggers an automatic download.',
    'export_download_title' : 'View progress',
    'export_download_subtitle' : 'Here you can view the download progress of your data export.',
    'export_devices' : 'Devices',
    'export_visits' : 'Visits',
    'export_places' : 'Places',
    'export_network_error' : 'Download failed. Are you connected to the Internet?',

    'user_title': 'Account',
    'user_change_title' : 'Change information',
    'user_change_subtitle' : 'Here you can change your contact information.',
    'user_change_primary' : 'Primary email address',
    'user_password_title' : 'Change password',
    'user_password_subtitle' : 'Here you can change your password.',
    'user_password_old' : 'Current password',
    'user_password_old_hint' : 'Enter current password',
    'user_password_new' : 'New password',
    'user_password_new_hint' : 'Enter new password',
    'user_delete_title' : 'Delete account',
    'user_delete_subtitle' :  'Here you can delete your account completely.',
    'user_delete_password' : 'Password',
    'user_delete_password_hint' : 'Enter current password',
    'user_delete_admin_title' : 'Deletion not possible',
    'user_delete_admin_message' : 'Your account has administrative rights. Therefore, you cannot delete it yourself.',
    'user_delete_confirm_title' : 'Delete account?',
    'user_delete_confirm_message' : 'Do you really want to delete your account? This cannot be undone later on.',
    'user_delete_success' : 'Your account has been deleted successfully.',
    'user_add_email_title' : 'Add email address',
    'user_add_email_subtitle' : 'Here you can register a new email address.',
    'user_del_email_title' : 'Delete email address',
    'user_del_email_subtitle' : 'Here you can delete unused email addresses.',
    'user_del_email_tooltip' : 'Delete email address',
    'user_email_registered_already' : 'The email address is registered already.',
    'user_password_wrong_title' : 'Password invalid',
    'user_password_wrong_message' : 'Your current password does not match the one you provided. Please validate your input and try again.',
    'share_title' : 'Share',
    'share_no_update_info' : 'The share information could not be retrieved.',
    'share_no_update_location' : 'The location could not be retrieved.',
    'share_token' : 'Key',
    'share_name' : 'Name',
    'share_message' : 'Message',
    'share_destination' : 'Destination',
    'share_expiration' : 'Expiration',
    'share_location' :  'Location',
    'share_time' : 'Time',
    'share_availability' : 'Status',
    'share_longitude' : 'Longitude',
    'share_latitude' : 'Latitude',
    'share_add_tooltip' : 'Add to account',
    'reset_confirm_title' : 'Password reset',
    'reset_confirm_message' : 'We just sent an email to your primary email address that contains a password reset link. '
        + 'If you remember your password again, you can simply delete the email.',
    'reset_account_error' : 'Account not found. Please check your account name and try again.',
    'reset_network_error' : 'Reset failed. Are you connected to the Internet?',
    'reset_title' : 'Reset password',
    'reset_subtitle' : 'Enter your account name to reset your password',
    'register_accept_terms_title' : 'Terms of service',
    'register_accept_terms_message' : 'To register your account, you must accept the terms of service.',
    'register_success_title' : 'Registration successful',
    'register_success_message' : 'Your account has been created. Before you can use it, you have to activate it. '
        + 'To do this, we just sent you an email with an activation link. Please check your inbox. '
        + 'If the email takes a long time to reach you, make sure to check your spam folder!',
    'register_conflict_error' : 'Your account name is already registered, plase select another one.',
    'register_network_error' : 'Registration failed. Are you connected to the Internet?',
    'register_title' : 'Register',
    'register_subtitle' : 'Enter your data and create a new account.',
    'register_terms_pre' : 'I am 18 years old, I have read the',
    'register_terms_mid' : 'and I accept the',
    'register_terms_end' : '.',
    'register_terms_service' : 'terms of service',
    'register_terms_policy' : 'privacy policy',
    'register_already_title' : 'Do you have an account already?',
    'register_already_subtitle' : 'No problem!',
    'register_already_paragraph' : 'Go to the login page and enter your account name and password. '
        + 'Then you will be able to access all your data immediately.',
    'register_why_title': 'Why should I register?',
    'register_why_subtitle': 'Well, you don\'t have to!',
    'register_why_paragraph': 'Many Maptology features can be used without an account. For example, '
        + 'to share your location with your friends, you only need the mobile app. In order to view shares you only need a web browser.',
    'register_why_moreinfo': 'More Information',
    'privileges_delete_confirm_title': 'Delete share?',
    'privileges_delete_confirm_message': 'Do you really want to delete the share? This action cannot be undone later on.',
    'privileges_delete_success': 'The share has been deleted successfully.',
    'privileges_delete_error': 'The deletion of the share failed. Are you connected to the Internet?',
    'privileges_title': 'Shares',
    'privileges_add_tooltip': 'Add share',
    'privileges_id': 'ID',
    'privileges_name' : 'Name',
    'privileges_nobody' : 'Anonymous User',
    'privileges_token': 'Key',
    'privileges_creation': 'Creation',
    'privileges_message': 'Message',
    'privileges_destination': 'Destination',
    'privileges_expiration': 'Expiration',
    'privileges_actions' : 'Actions',
    'privileges_show_tooltip' : 'Show share',
    'privileges_delete_tooltip' : 'Delete share',
    'places_delete_confirm_title': 'Delete place?',
    'places_delete_confirm_messages': 'Do you really want to delete the place? This will also delete all visits made '
        + 'at the place and you will not be able to undo the deletion later on.',
    'places_delete_success': 'The place has been deleted successfully.',
    'places_delete_error': 'The deletion of the place failed. Are you connected to the Internet?',
    'places_title': 'Places',
    'places_id': 'No.',
    'places_address': 'Address',
    'places_name': 'Name',
    'places_nickname': 'Name',
    'places_longitude': 'Longitude',
    'places_latitude': 'Latitude',
    'places_creation': 'Creation',
    'places_modification': 'Modification',
    'places_actions': 'Actions',
    'places_rename_tooltip': 'Rename place',
    'places_visits_tooltip': 'Show visits',
    'places_delete_tooltip': 'Delete place',
    'places_order_placeholder' : 'Order',
    'places_order_value_identifier' : 'Earliest visit time',
    'places_order_value_time' : 'Most recently visited',
    'places_order_value_frequency' : 'Highest number of visits',
    'places_order_value_duration' : 'Longest total visit duration',
    'places_order_value_importance' : 'Highest importance',
    'visits_delete_confirm_title' : 'Delete visit?',
    'visits_delete_confirm_message' : 'Do you really want to delete the visit? This cannot be undone later on.',
    'visits_delete_success' : 'The visit has been deleted successfully.',
    'visits_delete_error': 'Failed to delete the vist. Are you connected to the Internet?',
    'visits_place_load_error': 'Failed to load the place.',
    'visits_place': 'Place',
    'visits_id': 'No.',
    'visits_manufacturer': 'Manufacturer',
    'visits_model': 'Model',
    'visits_os': 'OS',
    'visits_creation': 'Registration',
    'visits_modification': 'Modification',
    'visits_nickname' : 'Name',
    'visits_address' : 'Address',
    'visits_longitude' : 'Longitude',
    'visits_latitude' : 'Latitude',
    'visits_visits' : 'Visits',
    'visits_begin' : 'Begin',
    'visits_end' : 'End',
    'visits_device' : 'Device',
    'visits_visit' : 'Visits',
    'visits_from' : 'From',
    'visits_to' : 'Until',
    'visits_actions' : 'Actions',
    'visits_device_tooltip' : 'Show device',
    'visits_delete_tooltip' : 'Delete visit',
    'visits_map_tooltip' : 'Center map on place',
    'visits_place_tooltip': 'Show visits for place',
    'visits_previous_tooltip': 'Previous day',
    'visits_next_tooltip': 'Next day',
    'visits_day_placeholder': 'Select day',
    'positions_title': 'Updates',
    'positions_manufacturer': 'Manufacturer',
    'positions_model': 'Model',
    'positions_os': 'OS',
    'positions_creation': 'Registration',
    'positions_modification': 'Modification',
    'positions_previous_day_tooltip': 'Previous day',
    'positions_next_day_tooltip': 'Next day',
    'positions_previous_minute_tooltip': 'Previous minute',
    'positions_next_minute_tooltip': 'Next minute',
    'positions_day_placeholder': 'Select day',
    'notices_title' : 'News',
    'manage_users_success' : 'The account has been modified successfully.',
    'manage_users_error' : 'The account modification failed.',
    'manage_users_title' : 'Users',
    'manage_users_term_placeholder' : 'Search term',
    'manage_users_term_required' : 'The search term is required.',
    'manage_users_term_pattern' : 'The search term contains an invalid character.',
    'manage_users_search_tooltip' : 'Search users',
    'manage_users_id' : 'ID',
    'manage_users_type' : 'Type',
    'manage_users_login': 'Account name',
    'manage_users_email' : 'Email address',
    'manage_users_name' : 'User name',
    'manage_users_creation' : 'Registration',
    'manage_users_modification' : 'Modification',
    'manage_users_actions' : 'Actions',
    'manage_users_restore_tooltip' : 'Restore account',
    'manage_users_delete_tooltip' : 'Delete account',
    'manage_users_user_tooltip' : 'Convert to user account',
    'manage_users_admin_tooltip' : 'Convert to adminstrator account',
    'manage_users_scientist_tooltip' : 'Convert to scientist account',
    'manage_users_user': 'User',
    'manage_users_admin': 'Administrator',
    'manage_users_scientist': 'Scientist',
    'manage_system_title': 'System',
    'manage_system_gbs' : 'gigabytes',
    'manage_system_gb' : 'gigabyte',
    'manage_system_mbs' : 'megabytes',
    'manage_system_mb' : 'megabyte',
    'manage_system_days' : 'days',
    'manage_system_day' : 'day',
    'manage_system_hours' : 'hours',
    'manage_system_hour' : 'hour',
    'manage_system_minutes' : 'minutes',
    'manage_system_minute' : 'minute',
    'manage_system_seconds' : 'seconds',
    'manage_system_second' : 'second',
    'manage_system_load_title' : 'Load',
    'manage_system_load_subtitle' : 'Shows the average CPU load over the last minute.',
    'manage_system_uptime' : 'Uptime',
    'manage_system_memory_title': 'Memory',
    'manage_system_memory_subtitle': 'Shows the heap memory usage at the time of the request.',
    'manage_system_max_heap': 'Maximum heap size',
    'manage_system_equipment_title': 'Configuration',
    'manage_system_equipment_subtitle': 'Shows the hard- and software configuration of the machine.',
    'manage_system_os': 'Operating system',
    'manage_system_os_name': 'Name',
    'manage_system_os_architecture': 'Architecture',
    'manage_system_os_version': 'Version',
    'manage_system_jvm': 'Java Virtual Machine',
    'manage_system_jvm_name': 'Name',
    'manage_system_jvm_specification': 'Specification',
    'manage_system_jvm_vendor': 'Vendor',
    'manage_system_jvm_version': 'Version',
    'manage_system_gc': 'Garbage Collector',
    'manage_system_gc_count' : 'Executions',
    'manage_system_gc_duration': 'Duration',
    'manage_system_power': 'Computer',
    'manage_system_processors': 'Processors',
    'manage_system_data_title': 'Database',
    'manage_system_data_subtitle': 'Shows the number of database entries for all entities.',
    'manage_system_data_entity': 'Entity',
    'manage_system_data_sum': 'Sum',
    'manage_system_data_active': 'Active',
    'manage_system_data_deleted': 'Deleted',
    'manage_notices_change_success': 'The notice has been modified successfully.',
    'manage_notices_change_error': 'The modification of the notice has failed.',
    'manage_notices_title': 'Notices',
    'manage_notices_id': 'ID',
    'manage_notices_languages': 'Languages',
    'manage_notices_creation': 'Creation',
    'manage_notices_modification': 'Modification',
    'manage_notices_expiration': 'Expiration',
    'manage_notices_header': 'Title',
    'manage_notices_published': 'Pub.',
    'manage_notices_announced': 'An. (Min/Max)',
    'manage_notices_actions': 'Actions',
    'manage_notices_restore_tooltip': 'Restore notice',
    'manage_notices_delete_tooltip': 'Delete notice',
    'manage_notices_edit_tooltip': 'Edit notice',
    'manage_logs_title': 'Logs',
    'manage_logs_connect_tooltip': 'Connect',
    'manage_logs_disconnect_tooltip': 'Disconnect',
    'manage_logs_clear_tooltip': 'Clear',
    'manage_logs_id': 'ID',
    'manage_logs_time': 'Time',
    'manage_logs_thread': 'Thread',
    'manage_logs_level': 'Level',
    'manage_logs_class': 'Class',
    'manage_logs_method': 'Method',
    'manage_logs_message': 'Message',
    'manage_logs_trace': 'Trace',
    'manage_logs_trace_tooltip': 'Stacktrace available',
    'manage_logs_actions': 'Actions',
    'manage_logs_details_tooltip': 'Show details',
    'login_login_title': 'Login',
    'login_login_subtitle': 'Here you can access your data with your account',
    'login_login_reset': 'Forgot password?',
    'login_build_version': 'Build version',
    'login_build_time': 'Build time',
    'login_register_title': 'You do not have an account?',
    'login_register_subtitle': 'No problem!',
    'login_register_paragraph': 'Simply get one and connect your device. Then you can access your device location '
        + 'and your visitied places from everywhere!',
    'edit_notice_load_error': 'Failed to load the notice.',
    'edit_notice_save_success': 'The notice has been saved successfully.',
    'edit_notice_save_error': 'The notice could not be saved.',
    'edit_notice_title_new': 'Create',
    'edit_notice_title_edit': 'Edit',
    'edit_notice_save_tooltip': 'Save notice',
    'edit_notice_close_tooltip': 'Close notice',
    'edit_notice_content_title': 'Content',
    'edit_notice_content_subtitle': 'Here you can change the content of the notice.',
    'edit_notice_title_placeholder': 'Enter title',
    'edit_notice_body_placeholder': 'Enter content',
    'edit_notice_add_language_tooltip': 'Add language',
    'edit_notice_settings_title': 'Settings',
    'edit_notice_settings_subtitle': 'Here you can change the publication settings.',
    'edit_notice_settings_time_placeholder': 'Set expiration time',
    'edit_notice_settings_date_placeholder': 'Set expiration date',
    'edit_notice_settings_publish': 'Publish',
    'edit_notice_settings_announce': 'Announce',
    'edit_notice_settings_min_version': 'Minimum version',
    'edit_notice_settings_max_version': 'Maximum version',
    'edit_notice_creation': 'Creation',
    'edit_notice_modification': 'Modification',
    'devices_delete_confirm_title': 'Delete device?',
    'devices_delete_confirm_message': 'Do you really want to delete the device? This will also delete the visits '
        + 'of the device and it cannot be undone later on.',
    'devices_delete_success': 'The device has been deleted successfully.',
    'devices_delete_error': 'The deletion of the device failed. Are you connected to the Internet?',
    'devices_title': 'Devices',
    'devices_id': 'No.',
    'devices_os': 'OS',
    'devices_manufacturer': 'Manufacturer',
    'devices_model': 'Model',
    'devices_creation': 'Registration',
    'devices_modification': 'Modification',
    'devices_actions': 'Actions',
    'devices_location_tooltip': 'Show current location',
    'devices_history_tooltip': 'Show location history',
    'devices_position_tooltip': 'Show individual updates',
    'devices_position_chart_header': 'Accelerometer',
    'devices_position_chart_y_axis_label': 'Data',
    'devices_position_chart_x_axis_label': 'Sec.',
    'devices_position_chart_acceleration': 'Acceleration',
    'devices_delete_tooltip': 'Delete device',
    'device_location_device_error': 'Failed to load the device information.',
    'device_location_location_error': 'Failed to load the device location.',
    'device_location_device': 'Device',
    'device_location_id' : 'ID',
    'device_location_manufacturer': 'Manufacturer',
    'device_location_model': 'Model',
    'device_location_os': 'Operating system',
    'device_location_creation': 'Registration',
    'device_location_modification': 'Modification',
    'device_location_time': 'Time',
    'device_location_availability': 'Status',
    'device_location_longitude': 'Longitude',
    'device_location_latitude': 'Latitude',
    'device_location_location': 'Location',
    'change_reset_success_title': 'Reset successful',
    'change_reset_success_message': 'Your password has been reset successfully. From now on, you must use your new password to log in.',
    'change_reset_error_title': 'Reset failed',
    'change_reset_error_message': 'The password reset failed. Are you connected to the Internet?',
    'change_reset_error_badlink': 'The reset link is invalid. If you copied it from an email, make sure to copy it completely.',
    'change_reset_error_expiredlink': 'The reset link is expired. Did you use it already?',
    'change_reset_title': 'Reset password',
    'change_reset_subtitle': 'Just enter your new password.',
    'activate_title': 'Activation',
    'activate_subtitle': 'Activating your account.',
    'activate_success_title': 'Activation successful',
    'activate_success_message': 'The email address has been added to your account. If this is the first email address of a new account, '
        + 'you can now log in. If this was another email address for an existing account, you can now set it as the '
        + 'primary contact address in your account settings.',
    'activate_error_title': 'Activation failed',
    'activate_error_message': 'The activation could not be completed. Are you connected to the Internet?',
    'activate_error_badlink': 'The activation link was invalid. If you copied it from your email program into the browser, '
        + 'make sure to copy it completely.',
    'activate_error_expiredlink': 'The activation link is no longer valid. Did you already use it?',
    // TODO: Time Sheet labels EN
    'time_sheet_update_btn_tooltip': 'Update',
    'time_sheet_export_btn_tooltip': 'Export',
    'time_sheet_export_as_csv_label': 'as CSV',
    'time_sheet_export_as_json_label': 'as JSON',
    'time_sheet_export_error_msg_title': 'Error!',
    'time_sheet_export_error_msg': 'An unexpected error has occurred. The export has failed.',
    'time_sheet_title': 'Time Sheet',
    'time_sheet_table_row_dialog_title': 'Time Info',
    'time_sheet_table_date': 'Date',
    'time_sheet_table_from': 'from',
    'time_sheet_table_to': 'to',
    'time_sheet_table_working_time': 'Working Hours',
    'time_sheet_table_place': 'Workplace',
    'time_sheet_table_device': 'Device',
    'time_sheet_config_label': 'Configuration',
    'time_sheet_toggle_default_label': 'Work',
    'time_sheet_toggle_default_tooltip': 'Capped & accumulated working time per day.',
    'time_sheet_toggle_acc_label': 'Normal',
    'time_sheet_toggle_acc_tooltip': 'Uncapped & accumulated time entries per day.',
    'time_sheet_toggle_by_days_label': 'By Days',
    'time_sheet_toggle_by_days_tooltip': 'All time entries per day.',
    'time_sheet_toggle_raw_label': 'Raw',
    'time_sheet_toggle_raw_tooltip': 'Raw data.',
    'time_sheet_select_device_label': 'Device',
    'time_sheet_select_device_tooltip': 'Filters the time entries to the selected device.',
    'time_sheet_no_device_selected': 'None',
    'time_sheet_break_time_label': 'Break Time',
    'time_sheet_break_time_tooltip': 'Specifies the duration from which the absence from the work '
        + 'place is to be valuated as a break duration and is therefore not added to the time entry.',
    'time_sheet_break_time_minutes': 'Minutes',
    'time_sheet_no_break_time_selected': 'None',
    'time_sheet_time_period_label': 'Time Period',
    'time_sheet_time_period_tooltip': 'Marks the period for the data to be evaluated.',
    'time_sheet_workplace_button': 'Select Workplace',
    'time_sheet_workplace_clear_list': 'Clear List',
    // layouts
    'drawer_navigation': 'Navigation',
    'drawer_settings': 'Settings',
    'drawer_management': 'Management',
    'footer_rights_reserved': 'All rights reserved',
    'footer_imprint': 'Imprint',
    'footer_licenses' : 'Open Source Licenses',
    'footer_terms' : 'Terms of Service',
    // pagination provider
    'pagination_first_page': 'First page',
    'pagination_last_page': 'Last page',
    'pagination_next_page': 'Next page',
    'pagination_previous_page': 'Previous page',
    'pagination_items_per_page': 'Items per Page',
    'pagination_of': 'of',
    // language titles
    'lang_de': 'German',
    'lang_en': 'English',
    'lang_fr': 'French',
    'lang_it': 'Italian',
    'lang_es': 'Spanish',
  };

  /**
   * The current selection of languages.
   */
  values = this.values_en;
  readonly languages: Language[] = [
    {value: 'de', title: '', flag: 'assets/flags/de.svg' },
    {value: 'en', title: '', flag: 'assets/flags/en.svg'},
    {value: 'fr', title: '', flag: 'assets/flags/fr.svg'},
    {value: 'it', title: '', flag: 'assets/flags/it.svg'},
    {value: 'es', title: '', flag: 'assets/flags/es.svg'}];


  constructor() {
    if (navigator.language) {
      if (navigator.language.startsWith('de')) {
        this.values = this.values_de;
      }
    }
    this.updateLanguages();
  }

  public strings(key: string): string {
    const result =  this.values[key];
    if (!result) { console.log('Failed to load string for: ' + key); }
    return result;
  }

  public setLanguage(key: string): void {
    if (key === 'de') { this.values = this.values_de; }
    if (key === 'en') { this.values = this.values_en; }
    this.updateLanguages();
  }

  private updateLanguages() {
    for (const lang of this.languages) {
      lang.title = this.strings('lang_' + lang.value);
    }

  }

}

