<app-marker-map>
    <mat-accordion class="sidebar">
        <mat-expansion-panel class="mat-typography" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-top:10px">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">smartphone</mat-icon>{{ res.strings('visits_device') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="device | async as d">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_manufacturer') }}</span>
                    <span class="detail-value">{{ d.manufacturer }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_model') }}</span>
                    <span class="detail-value">{{ d.model }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_os') }}</span>
                    <span class="detail-value">{{ d.os }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_creation') }}</span>
                    <span class="detail-value">{{ d.creationTime | date:'short' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_modification') }}</span>
                    <span class="detail-value">{{ d.modificationTime | date:'short' }}</span>
                </div>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!loading.getValue();else progressBarDevice">
                    <button mat-raised-button color="primary" (click)="reload()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarDevice>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-typography" [ngClass]="{ 'narrow-panel': compact }" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-bottom:10px" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">beenhere</mat-icon>{{ res.strings('visits_visits') }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-table #table [dataSource]="dataSource">

                <ng-container matColumnDef="place">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('place')"> {{ res.strings('visits_place') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"  [ngStyle]="getStyle('place')">
                        <span class="mat-caption">{{ getPlaceName(element.place) }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="time">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('time')">
                        <div style="margin-left:25px">{{ res.strings('visits_from') }} - {{ res.strings('visits_to') }}</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngStyle]="getStyle('time')">
                        <div class="mat-caption" style="margin-left:25px">{{ getTime(element.beginTime) | date:'shortTime' }} - {{ getTime(element.endTime) | date:'shortTime' }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('actions')" class="action-cell"> {{ res.strings('visits_actions') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngStyle]="getStyle('actions')" class="action-cell">
                        <button mat-icon-button [matTooltip]="res.strings('visits_map_tooltip')" (click)="onSelectPlace(element.place)" [disabled]="loading.getValue() || deleting.getValue()">
                            <mat-icon>map</mat-icon>
                        </button>
                        <button mat-icon-button [matTooltip]="res.strings('visits_place_tooltip')" [routerLink]="'/places/' + element.place" [disabled]="loading.getValue() || deleting.getValue()">
                            <mat-icon>place</mat-icon>
                        </button>

                        <button mat-icon-button [matTooltip]="res.strings('visits_delete_tooltip')" (click)="onDelete(element.id)" [disabled]="loading.getValue() || deleting.getValue()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="visit">
                        <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('visit')"> {{ res.strings('visits_visit') }} </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngStyle]="getStyle('visit')">
                            <mat-list>
                                <mat-list-item style="height:auto"> 
                                        <div class="narrow-label">{{ res.strings('visits_place') }}</div>
                                        <div class="narrow-value">{{ getPlaceName(element.place) }}</div>
                                </mat-list-item>
                                <mat-list-item style="height:24px"> 
                                        <div class="narrow-label">{{ res.strings('visits_from') }}</div>
                                        <div class="narrow-value">{{ getTime(element.beginTime) | date:'shortTime' }}</div>                                
                                </mat-list-item>
                                <mat-list-item style="height:24px"> 
                                        <div class="narrow-label">{{ res.strings('visits_to') }}</div>
                                        <div class="narrow-value">{{ getTime(element.endTime) | date:'shortTime' }}</div>
                                </mat-list-item>
                            </mat-list>
                        </mat-cell>
                    </ng-container>
    
    
                <mat-header-row *matHeaderRowDef="getColumns()" [ngClass]="{ 'narrow-header' : compact }"></mat-header-row>
                <mat-row *matRowDef="let row; columns: getColumns();" [ngClass]="{ 'narrow-row' : compact }"></mat-row>
            </mat-table>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <button mat-icon-button [matTooltip]="res.strings('visits_previous_tooltip')" [disabled]="loading.getValue() || deleting.getValue() || !hasPreviousDay()"
                    (click)="onPreviousDay()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <mat-form-field style="margin-bottom:0px;width:120px">
                    <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [(value)]="selectedDate" (dateInput)="onDateChange($event)"
                        [placeholder]="res.strings('visits_day_placeholder')" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker [disabled]="loading.getValue() || deleting.getValue()" #picker touchUi="true"></mat-datepicker>
                </mat-form-field>
                <button mat-icon-button [matTooltip]="res.strings('visits_next_tooltip')" [disabled]="loading.getValue() || deleting.getValue() || !hasNextDay()"
                    (click)="onNextDay()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </mat-action-row>

            <ng-container *ngIf="loading.getValue() || deleting.getValue(); else spacer">
                <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </ng-container>
            <ng-template #spacer><div style="height:5px"></div></ng-template>


        </mat-expansion-panel>
    </mat-accordion>
</app-marker-map>