import { Component, ViewChild, OnInit, OnDestroy, ElementRef, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService } from '../services/device.service';
import { DefaultService, Device, DevicePage } from '../api';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { PaginationHandler } from '../utils/pagination';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';
import { Snackbars } from '../utils/snackbars';
import { TableHandler, TableColumn } from '../utils/tables';
import { ResourceService } from '../services/resource.service';
import { UserService, UserState, UserRole } from '../services/user.service';

@Component({
  selector: 'app-devices',
  templateUrl: 'devices.component.html'
})
export class DevicesComponent implements OnInit, OnDestroy, DoCheck {

  private static COLUMNS: TableColumn[] = [
    { name: 'id', dropPriority: 2, width: 140, fixedWidth: true },
    { name: 'os', dropPriority: 1, width: 60, fixedWidth: true },
    { name: 'manufacturer', dropPriority: 0, width: 200 },
    { name: 'model', dropPriority: 0, width: 200 },
    { name: 'creationTime', dropPriority: 3, width: 130, fixedWidth: true },
    { name: 'modificationTime', dropPriority: 4, width: 130, fixedWidth: true },
    { name: 'actions', dropPriority: 0, width: 150, fixedWidth: true }
  ];

  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: DataSource<Device>;
  private pageHandler: PaginationHandler<Device, DevicePage>;
  readonly loading: BehaviorSubject<boolean>;
  readonly deleting = new BehaviorSubject(true);

  private tableHandler: TableHandler;

  constructor(
    public res: ResourceService,
    private userService: UserService,
    private apiClientService: DefaultService,
    private deviceSerivce: DeviceService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog) {
    this.dataSource = deviceSerivce;
    this.loading = deviceSerivce.loading;
  }

  ngDoCheck(): void {
    this.tableHandler.onCheck();
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  ngOnInit() {
    this.tableHandler = new TableHandler(this.table, DevicesComponent.COLUMNS);
    this.pageHandler = new PaginationHandler(this.paginator, this.deviceSerivce);
    this.deviceSerivce.reload();
  }

  ngOnDestroy() {
    this.pageHandler.destroy();
  }

  getNumber(index: number): number {
    if (this.deviceSerivce.page.getValue() != null) {
      return this.deviceSerivce.page.getValue().offset + 1 + index;
    } else {
      return index + 1;
    }
  }

  getOsIcon(os: string): string {
    if (os === 'ANDROID') {
      return 'android';
    } else {
      return 'smartphone';
    }
  }

  canShowPositions(): boolean {
    if (this.userService.state.getValue() === UserState.Available) {
      const user = this.userService.user.getValue();
      for (const role of user.roles) {
        if (role === UserRole.Scientist || role === UserRole.Admin) {
          return true;
        }
      }
    }
    return false;
  }

  onDelete(id: number): void {
    ConfirmationDialogComponent.open(this.dialog, {
      title: this.res.strings('devices_delete_confirm_title'),
      message: this.res.strings('devices_delete_confirm_message'),
      positive: this.res.strings('button_yes'),
      negative: this.res.strings('button_no')
    }).subscribe((result) => {
      if (result != null && result.confirmed) {
        this.doDelete(id);
      }
    });
  }

  private doDelete(id: number): void {
    this.deleting.next(true);
    this.apiClientService.deleteDevice(id).subscribe((result) => {
      this.deleting.next(false);
      this.deviceSerivce.reload();
      Snackbars.showMessage(this.snackbar, this.res.strings('devices_delete_success'), Snackbars.DURATION_SHORT);
    }, (error) => {
      this.deleting.next(false);
      this.deviceSerivce.reload();
      Snackbars.showError(this.snackbar, this.res.strings('devices_delete_error'));
    });
  }

}
