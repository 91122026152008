import { Injectable } from '@angular/core';
import { Time, formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateRange } from '@angular/material/datepicker' ;

import { BehaviorSubject } from 'rxjs';

import { Page } from '../utils/pagination';
import { DefaultService, VisitPage, Device, Place, Visit } from '../api';
import { UserService } from './user.service';


/**
 * Data model for the TimeSheetService.
 */
export interface TimeSheetData {
  overlappingDays: boolean;     /* True if contained data is overlapping two or more days. */
  beginDate: Date;              /* Start date */
  endDate: Date;                /* End date */
  duration: Time;               /* Duration time */
  placeId?: number;             /* The id of the place. Null if placesSet is set. */
  deviceId?: number;            /* The id of the device. Null if devicesSet is set. */
  placesSet?: Set<number>;      /* A set of Places. Null if placeId is set. */
  devicesSet?: Set<number>;     /* A set of Devices. Null if deviceId is set.  */
}

/**
 * Determines how the TimeSheetData
 * of the service is grouped.
 */
export enum Grouping {
  DEFAULT = 1,        /* Capped & accumulated working time per day. */
  BY_DAY_ACC = 2,     /* Uncapped & accumulated working time per day. */
  BY_DAY = 3,         /* All time entries per day. */
  RAW = 4             /* Raw data which was extracted from the call to the '/users/current/visits' api */
}

interface TimeSheetRange {
  range?: DateRange<Date>;
  begin?: number;
  end?: number;
}

const COLUMN_SEPARATOR = ';';
const LINE_SEPARATOR = '\r\n';

/**
 * A service which is fetching data from the '/users/current/visits'
 * api and transforms it to TimeSheetData.
 */
@Injectable({
  providedIn: 'root'
})
export class TimeSheetService extends MatTableDataSource<TimeSheetData> {

  private static readonly BATCH_SIZE = 500;

  private static readonly DAY_IN_MILLI = 86_400_000;

  /* Properties representing the current progress in
   * fetching Visit data from the server. */
  fetching$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<boolean>(false);

  private _visitsAvailable = false;
  private visitsPage: VisitPage = TimeSheetService.emptyPage();
  private request = 0;

  /* These Properties are used to filter the Visit data which then,
   * in turn can be composed to the final timeSheet data shown
   * to the user.
   * At least the dateRange & workPlaces have to contain
   * valid values to start the composition process.
   * Remark: The prefixed _prev... values serve as a workaround
   *  for a known bug in angular, where mat-selection is fireing
   *  a "selectionChange" event twice when the selected value is null. */
  private _grouped: Grouping = Grouping.DEFAULT;
  private _device: Device = null;
  private _prevDevice: Device = null;
  private _breakTime: number = null;
  private _prevBreakTime: number = null;
  private _dateRange: TimeSheetRange = { range: new DateRange<Date>(null, null), begin: null, end: null };
  private _workPlaces: Place[] = [];

  /** Creates an empty page. */
  private static emptyPage() {
    return { elements: [], offset: 0, total: -1, size: 0 };
  }

  constructor(
    private apiClientService: DefaultService,
    private userService: UserService
  ) {
    super([]);
  }

  get isTimeSheetReady(): boolean {
    return this.visitsAvailable === true && this.dateRange !== null && this.workPlaces.length > 0;
  }

  private get visitsAvailable(): boolean {
    return this._visitsAvailable;
  }

  private set visitsAvailable(available: boolean) {
    this._visitsAvailable = available;
    this.updateTimeSheet();
  }

  get grouped(): Grouping {
    return this._grouped;
  }

  set grouped(grouped: Grouping) {
    if (grouped != null) {
      if (this._grouped !== grouped) {
        if ((this._grouped === Grouping.DEFAULT || this.grouped === Grouping.BY_DAY_ACC)
         && this._breakTime) {
          this._breakTime = null;
        }
        this._grouped = grouped;
        this.updateTimeSheet();
      }
    }
  }

  get device(): Device | null {
    return this._device;
  }

  set device(device: Device | null) {
    this._prevDevice = this._device;
    this._device = device;
    if (this._prevDevice === null && device === null) { return; }
    this.updateTimeSheet();
  }

  get breakTime(): number | null {
    return this._breakTime;
  }

  set breakTime(breakTime: number | null) {
    this._prevBreakTime = this._breakTime;
    this._breakTime = breakTime;
    if (this._prevBreakTime === null && breakTime === null) { return; }
    this.updateTimeSheet();
  }

  get dateRange(): DateRange<Date> {
    return this._dateRange.range;
  }

  set dateRange(dateRange: DateRange<Date>) {
    if (dateRange.start != null && dateRange.end != null) {
      const beginMillis = dateRange.start.getTime();
      const endMillis = new Date(dateRange.end.getTime() + TimeSheetService.DAY_IN_MILLI).getTime();
      this._dateRange = {
        range: dateRange,
        begin: beginMillis,
        end: endMillis
      };
    } else {
      this._dateRange = { range: dateRange, begin: null, end: null };
    }
    this.updateTimeSheet();
  }

  get workPlaces(): Place[] {
    return this._workPlaces;
  }

  set workPlaces(workPlaces: Place[]) {
    this._workPlaces = workPlaces;
    this.updateTimeSheet();
  }

  setup(paginator?: MatPaginator, sort?: MatSort): void {
    this.paginator = paginator;
    this.sort = sort;
    this.fetchData();
  }

  reset(): void {
    this.paginator = null;
    this.sort = null;
    this.resetService(false);
    this.resetFilters();
  }

  fetchData(): void {
    if (this.fetching$.getValue()) { return; }
    this.resetService(true);
    this.fetchAllVisits(this.request);
  }

  private resetService(isFetch: boolean): void {
    this.request += 1;
    this.fetching$.next(isFetch);
    this.visitsAvailable = false;
    this.visitsPage = TimeSheetService.emptyPage();
  }

  private resetFilters(): void {
    this._grouped = Grouping.RAW;
    this._device = null;
    this._breakTime = null;
    this._dateRange = {};
    this._workPlaces = [];
    this.updateTimeSheet();
  }

  private fetchAllVisits(code: number): void {
    if (this.isIncomplete(this.visitsPage)) {
      this.apiClientService.getCurrentUserVisits(this.visitsPage.offset, TimeSheetService.BATCH_SIZE)
          .subscribe((response) => {
            if (code === this.request) {
              this.visitsPage = this.mergePages(response, this.visitsPage);
              this.fetchAllVisits(code);
            }
          },
          this.createErrorHandler(code)
        );
    } else {
      this.fetching$.next(false);
      this.visitsAvailable = true;
    }
  }

  private isIncomplete(page: Page<any>): boolean {
    return page.total < 0 || page.total > page.offset;
  }

  private mergePages<T>(source: Page<T>, destination: Page<T>): Page<T> {
    const result = TimeSheetService.emptyPage();
    result.total = source.total;
    result.size = destination.size + source.elements.length;
    result.elements = destination.elements.concat(source.elements);
    result.offset = destination.offset + TimeSheetService.BATCH_SIZE;
    return result;
  }

  private createErrorHandler(code: number): (error: any) => void {
    return (error: any) => {
      if (code === this.request) {
        this.error$.next(true);
        this.fetching$.next(false);
      }
    };
  }

  /**
   * Updates the member data of the MatTableDataSource if some of the properties change.
   * The data is only updated if the property "isTimeSheetReady" is true.
   */
  private updateTimeSheet(): void {
    let timeSheet: TimeSheetData[] = [];
    if (this.isTimeSheetReady) {
      /* Filter all relevant places and dates. */
      let filteredVisits = this.visitsPage.elements.filter((visit: Visit) => {
        if (this._dateRange.begin <= visit.beginTime && visit.endTime < this._dateRange.end
          && this.workPlaces.some(place => place.id === visit.place)) {
            return visit;
        }
      });
      /* Filter for specified device. */
      if (this.device != null) {
        filteredVisits = filteredVisits.filter(visit => visit.device === this.device.id);
      }

      /* Map filtered visits to TimeSheetData */
      if (filteredVisits.length > 0) {
        switch (this.grouped) {
          case Grouping.DEFAULT:
          case Grouping.BY_DAY_ACC:
            timeSheet = this.accumulateV2TsByDay(filteredVisits);
            break;
          case Grouping.BY_DAY:
            const tsMap = this.mapV2TsByDay(filteredVisits);
            tsMap.forEach((ts: TimeSheetData[]) => {
              if (ts.length > 0) { timeSheet.push(...ts); }
            });
            break;
          case Grouping.RAW:
            timeSheet = this.mapVisitsToTimeSheet(filteredVisits);
            break;
        }
      }
    }
    this.data = timeSheet;
  }

  /**
   * Mapping the passed Visit objects to raw TimeSheetData objects.
   *
   * @param visits Visits to map.
   * @return List of the corresponding TimeSheetData objects.
   */
  private mapVisitsToTimeSheet(visits: Visit[]): TimeSheetData[] {
    return visits.map(visit => {
      const workingTime = this.getWorkingTime(visit.beginTime, visit.endTime);
      const midnight = this.getMidnightDate(visit.beginTime);
      const overlapping = (visit.endTime - midnight.getTime()) >= TimeSheetService.DAY_IN_MILLI;

      return {
        overlappingDays: overlapping,
        beginDate: new Date(visit.beginTime),
        endDate: new Date(visit.endTime),
        duration: workingTime,
        placeId: visit.place,
        deviceId: visit.device
      };
    });
  }

  /**
   * Mapping the passed Visit objects to TimeSheetData objects which are grouped by days
   * represented as Unix timestamps starting at midnight.
   * E.g.:
   *  timestamp: 1580425200000
   *  GMT: Thursday, 30. January 2020 23:00:00
   *  Germany: Freitag, 31. Januar 2020 00:00:00 GMT+01:00
   *
   * @param visits Visits to map.
   * @return Map of TimeSheetData objects grouped by days.
   */
  private mapV2TsByDay(visits: Visit[]): Map<number, TimeSheetData[]> {
    const timeSheetMap = new Map<number, TimeSheetData[]>();
    const rawTimeSheet: TimeSheetData[] = this.mapVisitsToTimeSheet(visits);
    const putInMap = (map: Map<number, TimeSheetData[]>, key: number, value: TimeSheetData) => {
      if (map.has(key)) {
        map.get(key).push(value);
      } else {
        map.set(key, [value]);
      }
    };

    if (rawTimeSheet.length > 0) {
      return rawTimeSheet
        .reduce((prevMap: Map<number, TimeSheetData[]>, currTS: TimeSheetData) => {
          const beginMidnight = this.getMidnightDate(currTS.beginDate.getTime()).getTime();
          const endMidnight = this.getMidnightDate(currTS.endDate.getTime()).getTime();

          if (beginMidnight === endMidnight) {
            putInMap(prevMap, beginMidnight, currTS);
          } else {
            /* If beginDate & endDate are overlapping two or more days then split
             * object into multiple TimeSheetData objects till endDate is reached. */
            const DAY = TimeSheetService.DAY_IN_MILLI;
            for (let j = beginMidnight + DAY; j <= endMidnight; j += DAY) {
              const begin = (j === beginMidnight + DAY) ? currTS.beginDate.getTime() : j - DAY;
              const end = j;
              const newTimeSheet = {
                overlappingDays: false,
                beginDate: new Date(begin),
                endDate: new Date(end),
                duration: this.getWorkingTime(begin, end),
                placeId: currTS.placeId,
                deviceId: currTS.deviceId
              };
              putInMap(prevMap, end - DAY, newTimeSheet);
            }

            const newTS = {
              overlappingDays: false,
              beginDate: new Date(endMidnight),
              endDate: currTS.endDate,
              duration: this.getWorkingTime(endMidnight, currTS.endDate.getTime()),
              placeId: currTS.placeId,
              deviceId: currTS.deviceId
            };
            putInMap(prevMap, endMidnight, newTS);
          }
          return prevMap;
        }, timeSheetMap);
    } else {
      return timeSheetMap;
    }
  }

  /**
   * Maps a list of Visits to a list of TimeSheetData which in turn
   * is containing only accumulated TimeSheetData object each
   * representing a single day.
   *
   * @param visits Visits to map.
   * @param workingTimeAcc Function to accumulate the working time.
   * @returns List of accumulated TimeSheetData.
   */
  private accumulateV2TsByDay(visits: Visit[]): TimeSheetData[] {
    const timeSheet: TimeSheetData[] = [];
    const tsMap = this.mapV2TsByDay(visits);
    tsMap.forEach((tsList: TimeSheetData[]) => {
      if (tsList.length > 0) {
        const erliestBegin = tsList
          .map((ts: TimeSheetData) => ts.beginDate.getTime())
          .reduce((prev, curr) => prev < curr ? prev : curr, Number.MAX_SAFE_INTEGER);
        const latestEnd = tsList
          .map((ts: TimeSheetData) => ts.endDate.getTime())
          .reduce((prev, curr) => prev > curr ? prev : curr, Number.MIN_SAFE_INTEGER);
        const places = new Set<number>();
        const devices = new Set<number>();
        tsList.forEach((ts: TimeSheetData) => {
          if (ts.placeId) { places.add(ts.placeId); }
          if (ts.deviceId) { devices.add(ts.deviceId); }
        });

        const btThreshold = (this.breakTime) ? this.breakTime * 60_000 : 0;
        const timeAccumulation = tsList
          .reduce((prev: { sheet?: TimeSheetData, acc: Time, breakAcc: Time }, curr: TimeSheetData) => {
              if (prev.sheet) {
                let addToBreakAcc = true;
                const time = this.getWorkingTime(prev.sheet.endDate.getTime(), curr.beginDate.getTime());
                if (btThreshold > 0) {
                  if (curr.beginDate.getTime() - prev.sheet.endDate.getTime() <= btThreshold) {
                    prev.acc.hours += time.hours;
                    prev.acc.minutes += time.minutes;
                    addToBreakAcc = false;
                  }
                }
                if (addToBreakAcc) {
                  prev.breakAcc.hours += time.hours;
                  prev.breakAcc.minutes += time.minutes;
                }
              }
              prev.acc.hours += curr.duration.hours;
              prev.acc.minutes += curr.duration.minutes;
              prev.sheet = curr;
              return prev;
            },
            { sheet: null, acc: { hours: 0, minutes: 0 }, breakAcc: { hours: 0, minutes: 0} }
          );
        const accWorkingHours = this.processWorkingHours(timeAccumulation.acc, timeAccumulation.breakAcc);
        timeSheet.push({
          overlappingDays: false,
          beginDate: new Date(erliestBegin),
          endDate: new Date(latestEnd),
          duration: accWorkingHours,
          placesSet: places,
          devicesSet: devices
        });
      }
    });
    return timeSheet;
  }

  /**
   * Processes the work and break time. If Grouping is DEFAULT, the break time is evaluated
   * and has to fit the legal requirements of having at least a 30 or 45 minutes break when
   * working more than 6 or 9 hours respectively.
   *
   * @param working  The time that is counting as work time.
   * @param breaking The time between the single work time entries
   *                 which can be seen as break time.
   * @return The normalized and processed work time.
   */
  private processWorkingHours(working: Time, breaking: Time): Time {
    working = this.normalizeTime(working);
    if (this.grouped === Grouping.DEFAULT) {
      let subtrahend = 0;
      breaking = this.normalizeTime(breaking);
      if (working.hours >= 6 && breaking.hours === 0 && breaking.minutes < 30) {
        subtrahend = 30 - breaking.minutes;
      } else if (working.hours >= 9 && breaking.hours === 0 && breaking.minutes < 45) {
        subtrahend = 45 - breaking.minutes;
      }
      working.minutes -= subtrahend;
      if (working.minutes < 0) {
        --working.hours;
        working.minutes = 60 - working.minutes;
      }

      /* Rounds up the minutes to a multiple of 15.  */
      if (working.minutes !== 0) {
        working.minutes += Math.floor(15 - working.minutes % 15);
      }
      if (working.minutes === 60) {
        ++working.hours;
        working.minutes = 0;
      }
      if (working.hours >= 10) { working = { hours: 10, minutes: 0 }; }
    }
    return working;
  }

  private normalizeTime(time: Time): Time {
    time.hours += Math.floor(time.minutes / 60);
    time.minutes = time.minutes % 60;
    return time;
  }

  private getMidnightDate(tstp: number): Date {
    const midnight = new Date(tstp);
    midnight.setHours(0, 0, 0, 0);
    return midnight;
  }

  private getWorkingTime(beginMillis: number, endMillis: number): Time {
    const beginMinutes = Math.floor(beginMillis / 1000 / 60);
    const endMinutes = Math.floor(endMillis / 1000 / 60);
    const totalMinutes = endMinutes - beginMinutes;
    const totalHours = totalMinutes / 60;

    let hours = Math.floor(totalHours);
    let minutes = Math.round(60 * (totalHours - hours));
    if (minutes >= 60) {
      const additionalHours = Math.floor(minutes / 60);
      hours += additionalHours;
      minutes = minutes - (60 * additionalHours);
    }
    return { hours, minutes };
  }

  getWorkplaceName(place: Place): string {
    return (place.nickname != null && place.nickname.length > 0) ? place.nickname : place.address;
  }

  getWorkplaceString(data: TimeSheetData): string {
    let res = '';
    if (data.placeId) {
      const place = this.workPlaces.find(p => p.id === data.placeId);
      res = this.getWorkplaceName(place);
    } else {
      data.placesSet.forEach(id => {
        const place = this.workPlaces.find(p => p.id === id);
        res += this.getWorkplaceName(place) + ', ';
      });
      res = res.slice(0, res.length - 2);
    }
    return res;
  }

  getDate(data: TimeSheetData): string {
    if (this.grouped === Grouping.RAW) {
      let prefix = '';
      const date = formatDate(data.endDate, 'dd.LL.yyyy', 'de');
      if (data.overlappingDays) {
        prefix = formatDate(data.beginDate, 'dd', 'de') + ' - ';
      }
      return prefix + date;
    } else {
      return formatDate(data.beginDate, 'dd.LL.yyyy', 'de');
    }
  }

  exportTimeSheet(type: string): boolean {
    let exportStr: string;
    switch (type) {
      case 'csv':
        exportStr = this.createCSV();
        break;
      case 'json':
        exportStr = this.createJSON();
        break;
    }
    if (!exportStr) { return false; }

    /* '\ufeff' -> UTF-8 Header Byte prepended.  */
    const blob = new Blob(['\ufeff', exportStr], { type: `text/${type};` });
    const login = this.userService.getLogin();
    let name = 'timesheet-export';
    if (login != null) { name += '-' + login; }
    name += '-' + Date.now();
    this.saveAs(`${name}.${type}`, blob);
    return true;
  }

  private saveAs(name: string, blob: Blob): void {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
    } else {
        const link = document.createElement('a');
        (<any>link).download = name;
        link.href = URL.createObjectURL(blob);
        link.click();
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.remove();
        }, 10);
    }
  }

  private createCSV(): string | null {
    if (this.data.length === 0) { return null; }
    let result = '';
    result += 'DATE' + COLUMN_SEPARATOR;
    result += 'FROM' + COLUMN_SEPARATOR;
    result += 'TO' + COLUMN_SEPARATOR;
    result += 'WORKING_TIME' + COLUMN_SEPARATOR;
    result += 'PLACES' + COLUMN_SEPARATOR;
    result += LINE_SEPARATOR;
    this.data.forEach((td: TimeSheetData) => {
      result += this.getDate(td) + COLUMN_SEPARATOR;
      result += formatDate(td.beginDate, 'H:mm', 'de') + COLUMN_SEPARATOR;
      result += formatDate(td.endDate, 'H:mm', 'de') + COLUMN_SEPARATOR;
      result += `${td.duration.hours} h ${td.duration.minutes} m` + COLUMN_SEPARATOR;
      result += this.getWorkplaceString(td) + COLUMN_SEPARATOR;
      result += LINE_SEPARATOR;
    });
    return result;
  }

  private createJSON(): string | null {
    return JSON.stringify(
      this.data.map((td: TimeSheetData) => {
        return {
          date: this.getDate(td),
          from: formatDate(td.beginDate, 'H:mm', 'de'),
          to: formatDate(td.endDate, 'H:mm', 'de'),
          workingTime: `${td.duration.hours} h ${td.duration.minutes} m`,
          workplaces: this.getWorkplaceString(td)
        };
      })
    );
  }
}
