import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, DoCheck } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Device, DefaultService, Visit } from '../api';
import { Snackbars } from '../utils/snackbars';
import * as L from 'leaflet';
import { MarkerMapComponent } from '../widgets/marker-map.component';
import { DeviceVisitService } from '../services/device-visit.service';
import { DataSource } from '@angular/cdk/table';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';
import { TableColumn, TableHandler } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-device-visits',
    templateUrl: './device-visits.component.html'
})
export class DeviceVisitsComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

    private static COLUMNS: TableColumn[] = [
        { name: 'time', dropPriority: 0, width: 120, fixedWidth: true },
        { name: 'place', dropPriority: 0, width: 120 },
        { name: 'actions', dropPriority: 0, width: 130, fixedWidth: true }
    ];

    private static FALLBACK: TableColumn[] = [
        { name: 'visit', dropPriority: 0, width: 120 },
        { name: 'actions', dropPriority: 0, width: 130, fixedWidth: true }
    ];

    @ViewChild(MarkerMapComponent, { static: true }) map: MarkerMapComponent;
    @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;

    id: string;

    maxDate = new Date();
    minDate = new Date();
    selectedDate = new Date();
    minTime = 0;
    maxTime = 0;

    device: BehaviorSubject<Device>;
    dataSource: DataSource<Visit>;
    loading: BehaviorSubject<boolean>;
    date: BehaviorSubject<Date>;
    deleting = new BehaviorSubject<boolean>(false);

    compact = false;

    private places: L.CircleMarker[] = [];
    private tableHandler: TableHandler;

    private dateSubscription: Subscription;
    private deviceSubscription: Subscription;
    private loadingSubscription: Subscription;

    constructor(
        public res: ResourceService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private apiClientService: DefaultService,
        private deviceVisitSerivce: DeviceVisitService,
        private route: ActivatedRoute
    ) {
        this.dataSource = deviceVisitSerivce;
        this.device = deviceVisitSerivce.device;
        this.loading = deviceVisitSerivce.loading;
        this.date = deviceVisitSerivce.date;
    }

    reload() {
        this.deviceVisitSerivce.setDevice(+this.id);
    }

    setDate(date: Date) {
        this.deviceVisitSerivce.setDate(date);
    }

    onNextDay() {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() + 1);
        this.setDate(d);
    }

    hasNextDay(): boolean {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() + 1);
        return this.maxDate.getTime() >= d.getTime();
    }

    onPreviousDay() {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() - 1);
        this.setDate(d);
    }


    hasPreviousDay(): boolean {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() - 1);
        return this.minDate.getTime() <= d.getTime();
    }

    onDateChange(event: MatDatepickerInputEvent<Date>) {
        this.setDate(event.value);
    }


    getPanelWidth(): any {
        if (this.compact) {
            return { width: '280px' };
        } else {
            return { width: '520px' };
        }
    }

    getStyle(column: string): any {
        return this.tableHandler.getColumnStyle(column);
    }

    getColumns(): string[] {
        return this.tableHandler.getDisplayColumns();
    }

    ngDoCheck(): void {
        this.tableHandler.onCheck();
        this.compact = (this.map.getWidth() < 900);
    }

    ngOnInit() {
        this.tableHandler = new TableHandler(this.table, DeviceVisitsComponent.COLUMNS, DeviceVisitsComponent.FALLBACK);
        this.id = this.route.snapshot.paramMap.get('id');
        this.deviceVisitSerivce.setDevice(+this.id);
        this.dateSubscription = this.date.subscribe((date) => {
            this.selectedDate = date;
            const d = new Date(date);
            d.setHours(0, 0, 0, 0);
            this.minTime = d.getTime();
            d.setHours(23, 59, 59, 999);
            this.maxTime = d.getTime();
        });
        this.deviceSubscription = this.device.subscribe((device) => {
            if (device == null) {
                this.minDate = new Date();
                this.maxDate = new Date();
            } else {
                this.minDate = new Date(device.creationTime);
                this.maxDate = new Date();
            }
            this.minDate.setHours(0, 0, 0, 0);
            this.maxDate.setHours(23, 59, 59, 999);
        });
    }

    ngAfterViewInit() {
        this.loadingSubscription = this.loading.subscribe((load) => {
            if (load) {
                this.clearPlaces();
            } else {
                this.createPlaces();
            }
        });
    }


    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
        this.deviceSubscription.unsubscribe();
        this.loadingSubscription.unsubscribe();
    }

    getPlaceName(id: number): string {
        const place = this.deviceVisitSerivce.getPlace(id);
        if (!place) {
            return '' + id;
        } else if (place.nickname) {
            return place.nickname;
        } else if (place.address) {
            return place.address;
        } else {
            return '' + place.id;
        }
    }

    onDelete(id: number): void {
        ConfirmationDialogComponent.open(this.dialog, {
            title: this.res.strings('visits_delete_confirm_title'),
            message: this.res.strings('visits_delete_confirm_message'),
            positive: this.res.strings('button_yes'),
            negative: this.res.strings('button_no')
        }).subscribe((result) => {
            if (result != null && result.confirmed) {
                this.doDelete(id);
            }
        });
    }

    private doDelete(id: number): void {
        this.deleting.next(true);
        this.apiClientService.deleteVisit(id).subscribe((result) => {
            this.deleting.next(false);
            this.deviceVisitSerivce.setDevice(+this.id);
            Snackbars.showMessage(this.snackbar, this.res.strings('visits_delete_success'), Snackbars.DURATION_SHORT);
        }, (error) => {
            this.deleting.next(false);
            this.deviceVisitSerivce.setDevice(+this.id);
            Snackbars.showError(this.snackbar, this.res.strings('visits_delete_error'));
        });
    }

    onSelectPlace(id: number): void {
        const place = this.deviceVisitSerivce.getPlace(id);
        if (!place) {
            Snackbars.showError(this.snackbar, this.res.strings('visits_place_load_error'));
            this.map.setPinPosition(null);
        } else {
            this.map.setPinPosition(place.coordinate);
        }
    }

    getTime(time: number): number {
        return Math.min(this.maxTime, Math.max(this.minTime, time));
    }


    private createPlaces(): void {
        // compute the total and individual stay duration
        const placeDurations = new Map<number, number>();
        let totalDuration = 0;
        for (const v of this.deviceVisitSerivce.getVisits()) {
            const begin = this.getTime(v.beginTime);
            const end = this.getTime(v.endTime);
            let duration = end - begin;
            totalDuration += duration;
            if (placeDurations.has(v.place)) {
                duration += placeDurations.get(v.place);
            }
            placeDurations.set(v.place, duration);
        }
        const latlngs: ([number, number])[] = new Array();
        // create circles for places
        placeDurations.forEach((v, k) => {
            const place = this.deviceVisitSerivce.getPlace(k);
            if (place != null) {
                const pos = new L.LatLng(place.coordinate.latitude, place.coordinate.longitude);
                latlngs.push([place.coordinate.latitude, place.coordinate.longitude]);
                const circle = L.circleMarker(pos, {
                    radius: 36,
                    fillColor: '#f9a825',
                    fill: true,
                    stroke: true,
                    weight: 4,
                    fillOpacity: this.getOpacity(v, totalDuration),
                    opacity: 0.8,
                    color: '#01579b'
                });
                this.places.push(circle);
                circle.addTo(this.map.map.map);
            }
        });
        if (latlngs.length > 0) {
            const bounds = new L.LatLngBounds(latlngs);
            this.map.centerBounds(bounds);
        }
    }

    private getOpacity(duration: number, total: number): number {
        const maxOpacity = 0.8;
        if (total === 0) { return maxOpacity; }
        const fraction = duration / total;
        return fraction * maxOpacity;
    }

    private clearPlaces(): void {
        this.map.setPinPosition(null);
        for (const c of this.places) {
            c.remove();
        }
        this.places = [];
    }

}
