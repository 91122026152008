/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a device.
 */
export interface Device { 
    /**
     * The unique identifier of the data.
     */
    readonly id?: number;
    /**
     * The time stamp when the data was created.
     */
    readonly creationTime?: number;
    /**
     * The time stamp when the data was created.
     */
    readonly modificationTime?: number;
    /**
     * A flag to indicate whether the data was deleted.
     */
    deleted?: boolean;
    /**
     * The id of the owning user or null, if there is none.
     */
    user?: number;
    /**
     * The operating system.
     */
    os: Device.OsEnum;
    /**
     * The manufacturer name, see android.os.Build.MANUFACTURER.
     */
    manufacturer: string;
    /**
     * The device name, see android.os.Build.DEVICE.
     */
    device: string;
    /**
     * The model name, see android.os.Build.MODEL.
     */
    model: string;
}
export namespace Device {
    export type OsEnum = 'ANDROID' | 'IOS';
    export const OsEnum = {
        ANDROID: 'ANDROID' as OsEnum,
        IOS: 'IOS' as OsEnum
    };
}
