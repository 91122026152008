import { Component, Input } from '@angular/core';
import { FormErrorEncoder } from '../utils/formcodes';
import { ResourceService } from '../services/resource.service';

/**
 * A validated field that enables a user to enter the
 * human name. The name will be available through the
 * components name which also acts as an input.
 */
@Component({
  selector: 'app-name-field',
  templateUrl: './name-field.component.html'
})
export class NameFieldComponent extends FormErrorEncoder {

  /**
   * The human name
   */
  @Input() name: string;
  /**
   * Disables the field.
   */
  @Input() disabled: boolean;

  constructor(public res: ResourceService) {
    super();
  }
}
