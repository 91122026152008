import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserState, UserService } from '../services/user.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LoginFieldComponent } from '../widgets/login-field.component';
import { PasswordFieldComponent } from '../widgets/password-field.component';
import { HttpStatusCode } from '../utils/httpcodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { Snackbars } from '../utils/snackbars';
import { VersionService } from '../services/version.service';
import { BuildVersion } from '../api';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  UserState = UserState;

  @ViewChild(LoginFieldComponent, { static: true }) loginField: LoginFieldComponent;
  @ViewChild(PasswordFieldComponent, { static: true }) passwordField: PasswordFieldComponent;
  @ViewChild('loginForm', { static: true }) loginForm: NgForm;

  login: string;
  password: string;
  columns = 2;

  readonly idle = new BehaviorSubject<boolean>(false);

  private version: BuildVersion;
  private stateSubscription: Subscription;
  private errorSubscription: Subscription;
  private versionSubscription: Subscription;

  constructor(
    public res: ResourceService,
    private userService: UserService,
    private datePipe: DatePipe,
    private versionService: VersionService,
    private snackbar: MatSnackBar,
    private router: Router) {
    this.login = userService.getLogin();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateColumns((event.target as Window).innerWidth);
  }

  updateColumns(width: number): void {
      if (width < 800) {
          this.columns = 1;
      } else {
          this.columns = 2;
      }
  }


  ngOnInit(): void {
    this.updateColumns(window.innerWidth);
    this.stateSubscription = this.userService.state.subscribe((state) => {
      if (state === UserState.Available) {
        this.idle.next(false);
        let url = '/notices';
        if (this.userService.redirectUrl != null) {
          url = this.userService.redirectUrl;
          this.userService.redirectUrl = null;
        }
        this.router.navigateByUrl(url);
      } else {
        this.idle.next(state !== UserState.Changing);
        this.loginField.disabled = ! this.idle.getValue();
        this.loginField.disabled = ! this.idle.getValue();
      }
    });
    this.errorSubscription = this.userService.error.subscribe((error) => {
      this.password = '';
      if (error.status === HttpStatusCode.FORBIDDEN) {
        Snackbars.showError(this.snackbar, 'Zugriff verweigert. Prüf Deine Eingaben und versuchs nochmal.');
      } else {
        Snackbars.showError(this.snackbar, 'Fehler bei der Anmeldung (' + error.status + '). Hast Du gerade kein Netz?');
      }
    });
    this.versionSubscription = this.versionService.subscribe((v) => {
      this.version = v;
    });
  }

  getTime(): string {
    if (this.version) {
      return this.datePipe.transform(this.version.time, 'short');
    } else {
      return '-';
    }
  }

  getVersion(): string {
    if (this.version) {
      return this.version.build;
    } else {
      return '-';
    }
  }

  onLogin(): void {
    this.snackbar.dismiss();
    if (!this.idle || !this.loginForm.valid) { return; }
    this.login = this.loginField.login;
    this.password = this.passwordField.password;
    this.userService.setUser(this.login, this.password);
    this.userService.login();
  }

  ngOnDestroy(): void {
    this.snackbar.dismiss();
    this.stateSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
    this.versionSubscription.unsubscribe();
  }


}
