import {Component, ViewChild, OnInit, OnDestroy, ElementRef, DoCheck} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultService, User, UserPage } from '../api';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { PaginationHandler } from '../utils/pagination';
import { Snackbars } from '../utils/snackbars';
import { ManageUserService } from '../services/manage-user.service';
import { FormErrorEncoder } from '../utils/formcodes';
import { NgForm } from '@angular/forms';
import { UserRole, UserService } from '../services/user.service';
import { TableColumn, TableHandler } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: 'manage-users.component.html'
})
export class ManageUsersComponent extends FormErrorEncoder implements OnInit, OnDestroy, DoCheck {

  private static COLUMNS: TableColumn[] = [
    { name: 'id', dropPriority: 5, width: 120, fixedWidth: true},
    { name: 'type', dropPriority: 0, width: 55, fixedWidth: true},
    { name: 'login', dropPriority: 0, width: 150},
    { name: 'name', dropPriority: 1, width: 200},
    { name: 'email', dropPriority: 2, width: 200},
    { name: 'creationTime', dropPriority: 3, width: 130, fixedWidth: true},
    { name: 'modificationTime', dropPriority: 4, width: 130 , fixedWidth: true},
    { name: 'actions', dropPriority: 0, width: 130, fixedWidth: true }
  ];

  @ViewChild('searchForm', { static: true }) searchForm: NgForm;
  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: DataSource<User>;
  readonly loading: BehaviorSubject<boolean>;
  term: string;

  private tableHandler: TableHandler;
  private pageHandler: PaginationHandler<User, UserPage>;


  constructor(
      public res: ResourceService,
      private apiClientService: DefaultService,
      private userService: UserService,
      private manageUserService: ManageUserService,
      private snackbar: MatSnackBar,
      private dialog: MatDialog) {
        super();
    this.dataSource = manageUserService;
    this.loading = manageUserService.loading;
    this.term = manageUserService.getTerm();
  }

  ngDoCheck(): void {
    this.tableHandler.onCheck();
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  ngOnInit() {
    this.tableHandler = new TableHandler(this.table, ManageUsersComponent.COLUMNS);
    this.pageHandler = new PaginationHandler(this.paginator, this.manageUserService);
    this.manageUserService.reload();
  }

  ngOnDestroy() {
    this.pageHandler.destroy();
  }

  getCorrespondence(user: User): string {
      for (const email of user.emails) {
          if (email.correspondence) { return email.address; }
      }
      return null;
  }

  onSearch(): void {
    if (this.searchForm.invalid || this.loading.getValue()) { return; }
    this.manageUserService.setTerm(this.term);
  }

  isAdmin(user: User): boolean {
    for (const r of user.roles) {
      if (r === UserRole.Admin) { return true; }
    }
    return false;
  }

  isScientist(user: User): boolean {
    for (const r of user.roles) {
      if (r === UserRole.Scientist) { return true; }
    }
    return false;

  }

  isSelf(user: User): boolean {
    return user.id === this.userService.user.getValue().id;
  }

  onDelete(user: User): void {
    if (this.loading.getValue()) { return; }
    user.deleted = true;
    this.updateUser(user);
  }

  onRestore(user: User): void {
    if (this.loading.getValue()) { return; }
    user.deleted = false;
    this.updateUser(user);
  }

  makeAdmin(user: User): void {
    if (this.loading.getValue()) { return; }
    const i = user.roles.indexOf(UserRole.Admin);
    if (i !== -1) { return; }
    user.roles.push(UserRole.Admin);
    this.updateUser(user);
  }

  makeScientist(user: User): void {
    if (this.loading.getValue()) { return; }
    const i = user.roles.indexOf(UserRole.Scientist);
    if (i !== -1) { return; }
    user.roles.push(UserRole.Scientist);
    this.updateUser(user);
  }

  makeUser(user: User): void {
    if (this.loading.getValue()) { return; }
    const i = user.roles.indexOf(UserRole.Admin);
    if (i !== -1) {
      user.roles.splice(i, 1);
    }
    const j = user.roles.indexOf(UserRole.Scientist);
    if (j !== -1) {
      user.roles.splice(j, 1);
    }
    if (i !== -1 || j !== -1) {
      this.updateUser(user);
    }
  }


  private updateUser(user: User) {
    this.manageUserService.setUser(user).subscribe((result) => {
      if (result) {
        Snackbars.showMessage(this.snackbar, this.res.strings('manage_users_success'), Snackbars.DURATION_SHORT);
      } else {
        Snackbars.showError(this.snackbar, this.res.strings('manage_users_error'));
      }
    });

  }

}
