import { 
    Component, OnInit, OnDestroy, ViewChild, 
    AfterViewInit, ElementRef, DoCheck, AfterContentInit, 
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Device, Position } from '../api';
import * as L from 'leaflet';
import { MarkerMapComponent } from '../widgets/marker-map.component';
import { DataSource } from '@angular/cdk/table';
import { ResourceService } from '../services/resource.service';
import { DevicePositionService } from '../services/device-position.service';
import { ChartComponent, AxisConfig, Point } from '../widgets/chart.component';

@Component({
    selector: 'app-device-positions',
    templateUrl: './device-positions.component.html'
})
export class DevicePositionsComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

    @ViewChild(MarkerMapComponent, { static: true }) map: MarkerMapComponent;
    @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
    @ViewChild(ChartComponent, { static: true }) chart: ChartComponent;

    id: string;

    maxDate = new Date();
    minDate = new Date();
    selectedDate = new Date();

    device: BehaviorSubject<Device>;
    dataSource: DataSource<Position>;
    loading: BehaviorSubject<boolean>;
    date: BehaviorSubject<Date>;

    sliderValue = 6 * 60;
    compact = false;

    private positions: L.Polyline = null;
    private selectedPosition: L.CircleMarker = null;

    private dateSubscription: Subscription;
    private deviceSubscription: Subscription;
    private loadingSubscription: Subscription;

    constructor(
        public res: ResourceService,
        private devicePositionService: DevicePositionService,
        private route: ActivatedRoute
    ) {
        this.dataSource = devicePositionService;
        this.device = devicePositionService.device;
        this.loading = devicePositionService.loading;
        this.date = devicePositionService.date;
    }

    reload() {
        this.devicePositionService.setDevice(+this.id);
    }

    setDate(date: Date) {
        this.devicePositionService.setDate(date);
    }

    onNextDay() {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() + 1);
        this.setDate(d);
    }

    hasNextDay(): boolean {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() + 1);
        return this.maxDate.getTime() >= d.getTime();
    }

    onPreviousDay() {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() - 1);
        this.setDate(d);
    }


    hasPreviousDay(): boolean {
        const d = new Date(this.selectedDate);
        d.setDate(d.getDate() - 1);
        return this.minDate.getTime() <= d.getTime();
    }

    onDateChange(event: MatDatepickerInputEvent<Date>) {
        this.setDate(event.value);
    }

    isSliderDisabled(): boolean {
        return this.loading.getValue() || this.devicePositionService.getPositions().length === 0;
    }

    onSliderChange(event: MatSliderChange) {
        this.sliderValue = event.value;
        this.updateSelectedPosition();
    }

    cannotSlideNext(): boolean {
        return this.isSliderDisabled() || this.sliderValue >= 1440;
    }

    cannotSlidePrevious(): boolean {
        return this.isSliderDisabled() || this.sliderValue <= 0;
    }

    onIncrementSlider(value: number) {
        this.sliderValue += value;
        this.updateSelectedPosition();
    }

    getSliderFormat(value: number): string {
        let m = '' + (value % 60);
        if (m.length === 1) { m += '0'; }
        const h = '' + Math.floor(value / 60);
        return h + ':' + m;
    }


    getPanelWidth(): any {
        if (this.compact) {
            return { width: '280px' };
        } else {
            return { width: '280px' };
        }
    }

    getXAxisConfig(): AxisConfig {
        return {
            label: this.res.strings('devices_position_chart_x_axis_label'),
            from: 0,
            to: 60,
            stride: 5,
            fontSize: 28
        };
    }

    getYAxisConfig(): AxisConfig {
        return {
            label: this.res.strings('devices_position_chart_y_axis_label'),
            from: -20,
            to: 20,
            stride: 2,
            fontSize: 28
        };
    }

    ngDoCheck(): void {
        this.compact = (this.map.getWidth() < 900);
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.devicePositionService.setDevice(+this.id);
        this.dateSubscription = this.date.subscribe((date) => {
            this.selectedDate = new Date(date);
        });
        this.deviceSubscription = this.device.subscribe((device) => {
            if (device == null) {
                this.minDate = new Date();
                this.maxDate = new Date();
            } else {
                this.minDate = new Date(device.creationTime);
                this.maxDate = new Date();
            }
            this.minDate.setHours(0, 0, 0, 0);
            this.maxDate.setHours(23, 59, 59, 999);
        });
        this.selectedPosition = L.circleMarker([ 0, 0], {
            radius: 10,
            fillColor: '#f9a825',
            fill: true,
            stroke: true,
            weight: 4,
            opacity: 0.8,
            color: '#01579b'
        });
    }

    ngAfterViewInit() {
        this.loadingSubscription = this.loading.subscribe((load) => {
            if (load) {
                this.clearPositions();
                this.updateSelectedPosition();
            } else {
                this.createPositions();
                this.updateSelectedPosition();
            }
        });
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
        this.deviceSubscription.unsubscribe();
        this.loadingSubscription.unsubscribe();
    }

    private updateSelectedPosition(): void {
        if (this.loading.getValue() || this.devicePositionService.getPositions().length === 0) {
            this.selectedPosition.remove();
            this.updateChart();
        } else {
            const date = new Date(this.selectedDate);
            date.setHours(Math.floor(this.sliderValue / 60));
            date.setMinutes(this.sliderValue % 60, 0, 0);
            const time = date.getTime();
            let before: Position = null;
            let after: Position = null;
            for (const p of this.devicePositionService.getPositions()) {
                if (p.coordinate != null) {
                    if (p.timestamp <= time) {
                        before = p;
                    } else if (after == null) {
                        after = p;
                        break;
                    }
                }
            }
            if (before == null && after == null) {
                this.selectedPosition.remove();
            } else {
                if (before == null && after != null) {
                    this.selectedPosition.setLatLng([after.coordinate.latitude, after.coordinate.longitude]);
                } else if (after == null && before != null) {
                    this.selectedPosition.setLatLng([before.coordinate.latitude, before.coordinate.longitude]);
                } else {
                    const percent = (time - before.timestamp) / (after.timestamp - before.timestamp);
                    const b = before.coordinate;
                    const a = after.coordinate;
                    this.selectedPosition.setLatLng(
                        [this.interpolate(b.latitude, a.latitude, percent),
                            this.interpolate(b.longitude, a.longitude, percent)]);
                }
                this.selectedPosition.addTo(this.map.map.map);
            }
            this.updateChart(after, before);
        }
    }

    private interpolate(from: number, to: number, percent: number): number {
        return from + (to - from) * percent;
    }

    private createPositions(): void {
        const latlngs: ([number, number])[] = new Array();
        for (const p of this.devicePositionService.getPositions()) {
            if (p.coordinate != null) {
                latlngs.push([ p.coordinate.latitude, p.coordinate.longitude ]);
            }
        }
        if (latlngs.length > 0) {
            this.positions = L.polyline(latlngs, {
                color: '#f9a825',
                stroke: true,
                weight: 4
            });
            this.positions.addTo(this.map.map.map);
            const bounds = new L.LatLngBounds(latlngs);
            this.map.centerBounds(bounds);
        }
    }

    private clearPositions(): void {
        this.map.setPinPosition(null);
        if (this.positions != null) {
            this.positions.remove();
            this.positions = null;
        }
    }

    /**
     * Updates the acceleration data for the given position and minute. 
     * Depending on what parameters are passed, the method clears the chart
     * and draws either the before or after position or interpolates between them
     * if the date of both positions is present.
     * 
     * This data is summarized into x, y, z and the respective timestamp 
     * delta which starts with the first measurement with second 0.
     * Furthermore it also calculates the summerized Acceleration of the x, y 
     * & z values.
     * 
     * @param after  Position data of the after position.
     * @param before Position data of the before position.
     */
    private updateChart(after?: Position, before?: Position): void {
        this.chart.clearChartLines();
        if (!before && !after) return;

        const beforePoints = before ? this.processChartData(this.devicePositionService.decodeAccelData(before)) : null;
        const afterPoints = after ? this.processChartData(this.devicePositionService.decodeAccelData(after)) : null;
        if (!beforePoints && afterPoints) {
            this.drawChart(afterPoints);
        } else if (beforePoints && !afterPoints) {
            this.drawChart(beforePoints);
        } else if (beforePoints && afterPoints) {
            const interpolated = { x: [], y: [], z: [], accel: [] };
            const len = Math.min(beforePoints.x.length, afterPoints.x.length);
            for (let i = 0; i < len; i++) {
                interpolated.x.push(
                    [(beforePoints.x[i][0] + afterPoints.x[i][0]) / 2,
                     (beforePoints.x[i][1] + afterPoints.x[i][1]) / 2]
                );
                interpolated.y.push(
                    [(beforePoints.y[i][0] + afterPoints.y[i][0]) / 2,
                     (beforePoints.y[i][1] + afterPoints.y[i][1]) / 2]
                );
                interpolated.z.push(
                    [(beforePoints.z[i][0] + afterPoints.z[i][0]) / 2,
                     (beforePoints.z[i][1] + afterPoints.z[i][1]) / 2]
                );
                interpolated.accel.push(
                    [(beforePoints.accel[i][0] + afterPoints.accel[i][0]) / 2,
                     (beforePoints.accel[i][1] + afterPoints.accel[i][1]) / 2]
                );
            }
            this.drawChart(interpolated);
        }
    }

    /**
     * The acceleration sample data is processed to show the rate of change within one minute.
     * Multiple samples are summarized into one second which is represented by a Point object
     * containing the current second and the rate of change.
     * 
     * @param data The acceleration data which is pre-processed and formatted.
     */
    private processChartData(data: number[][])
        : { x: Point[], y: Point[], z: Point[], accel: Point[] } {
        if (!data || data.length === 0) return;

        const THRESHOLD = 1_000_000;
        const chartPoints = { x: [], y: [], z: [], accel: [] };
        let startTime = data[0][0];
        let lastTimestampDelta = 0;
        let second = 0; 
        let count = 1;
        let x = data[0][2], y = data[0][3], z = data[0][4];
        let accel = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
        for (const value of data) {
            let timestampDelta = value[0] - startTime;
            if (timestampDelta > THRESHOLD || timestampDelta < lastTimestampDelta || second === 0) {
                chartPoints.x.push([second, x / count]);
                chartPoints.y.push([second, y / count]);
                chartPoints.z.push([second, z / count]);
                chartPoints.accel.push([second, accel / count]);
                startTime = value[0];
                count = x = y = z = accel = 0;
                ++second;
            } 
            if (second > 60) break;

            lastTimestampDelta = timestampDelta;
            ++count;
            x += value[2];
            y += value[3];
            z += value[4];
            accel += Math.sqrt(
                Math.pow(value[2], 2) + Math.pow(value[3], 2) + Math.pow(value[4], 2)
            );
        }
        return chartPoints;
    }

    /**
     * Draws the current Lines onto the chart.
     * 
     * @param chartData Object containing Point arrays representing the lines to be drawn.
     */
    private drawChart(chartData: { x: Point[], y: Point[], z: Point[], accel: Point[] }): void {
        this.chart.addChartLine(chartData.x, 'x', 'red', 4);
        this.chart.addChartLine(chartData.y, 'y', 'green', 4);
        this.chart.addChartLine(chartData.z, 'z', 'blue', 4);
        this.chart.addChartLine(
            chartData.accel, this.res.strings('devices_position_chart_acceleration'), 'purple', 4
        );
    }
}
