/**
 * A delta compression codec based on Google's WGS84 encoder that
 * supports multiple data streams to encode other data than latitude
 * and longitude values. Callers must supply the number of streams
 * encoded in the data and the streams must exhibit the same number
 * of data items.
 * 
 * Copied the DeltaCodec class from the `android-app project` and
 * ported to TypeScript. 
 * 
 * @author Adriano
 */
export class DeltaCodec {
    
    /**
     * Creates a delta compressor for the specified number of streams.
     * 
     * @param streams The number of streams to encode in the string.
     */
    constructor(private streams: number) { }
    
    /**
     * Returns the number of streams expected by this codec.
     * 
     * @return The number of streams.
     */
    getStreams(): number {
        return this.streams;
    }
    
    /**
     * Encodes a list of values into a string.
     *
     * @param values The values consisting of arrays that represent
     *  the individual streams.
     * @return The string.
     */
    encode(values: number[][]): string {
        let builder: string = '';
        let previous: number[] = new Array(this.streams).fill(0);
        for (const current of values) {
            for (let i = 0; i < this.streams; i++) {
                const delta: number  = current[i] - previous[i];
                builder += DeltaCodec.encodeSignedNumber(delta);
                previous[i] = current[i];             
            }
        }
        return builder;
    }

    /**
     * Extracts the list of data from an encoded string.
     *
     * @param encoded The encoded string.
     * @param data An optional list of data items to be reused
     *  to avoid object allocations. Can be null.
     * @return The list of data items representing the streams.
     */
    decode(encoded: string, data: number[][]): number[][] {
        let list: number[][] = [];
        let index: number = 0;
        let length: number = encoded.length;
        let current: number[] = new Array(this.streams).fill(0);
        while (index < length) {
            let next: number[];
            if (data !== null && data.length > 0) {
                next = data.shift();
            } else {
                next = new Array(this.streams).fill(0);
            }
            let c: number;
            for (let i = 0; i < this.streams; i++) {
                let shift: number = 0;
                let result: number = 0;
                do {
                    c = encoded.charCodeAt(index++) - 63;
                    result |= (c & 0x1f) << shift;
                    shift += 5;
                } while (c >= 0x20);
                const delta: number = ((result & 1) !== 0) ? ~(result >> 1) : (result >> 1);
                current[i] += delta;
                next[i] = current[i];
            }
            list.push(next);
        }
        return list;
    }

    /**
     * Encodes a signed number as string.
     *
     * @param num The number.
     * @return The string.
     */
    private static encodeSignedNumber(num: number): string {
        let sgn_num: number = num << 1;
        if (num < 0) {
            sgn_num = ~(sgn_num);
        }
        return (DeltaCodec.encodeNumber(sgn_num));
    }

    /**
     * Encodes an unsigned number as string.
     *
     * @param num The number.
     * @return The string.
     */
    private static encodeNumber(num: number): string {
        let encodeString: string = '';
        while (num >= 0x20) {
            let nextValue: number = (0x20 | (num & 0x1f)) + 63;
            encodeString += String.fromCharCode(nextValue);
            num >>= 5;
        }
        num += 63;
        encodeString += String.fromCharCode(num);
        return encodeString;
    }

}
