<mat-toolbar color="primary" style="height:auto;" fxLayout="row">
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center" fxFlex="1 1 auto" fxLayoutGap.gt-sm="10px">
        <div fxFlex="1 1 auto"></div>
        <div fxLayout="row" fxLayoutGap="10px">
            <a class="link-white mat-caption" href="https://www.nes.uni-due.de" target="_blank">NES</a>
            <a class="link-white mat-caption" href="https://www.uni-due.de" target="_blank">Universität Duisburg-Essen</a>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <a class="link-white mat-caption" href="assets/texts/licenses.txt" target="_blank">{{ res.strings('footer_licenses')}}</a>
            <a class="link-white mat-caption" href="https://www.maptology.com/terms-of-service" target="_blank">{{ res.strings('footer_terms')}}</a>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <a class="link-white mat-caption" href="https://www.uni-due.de/de/impressum.shtml" target="_blank">{{ res.strings('footer_imprint')}}</a>
            <span class="mat-caption">&copy; 2018 {{ res.strings('footer_rights_reserved')}}</span>
        </div>
        <div fxFlex="1 1 auto"></div>
    </div>
    <div fxFlex="0 1 20px" fxLayoutAlign="end" style="margin-left:10px">
        <img *ngIf="res.strings('language')!='de'" src="assets/flags/de.svg" height="14px" (click)="res.setLanguage('de')">
        <img *ngIf="res.strings('language')!='en'" src="assets/flags/en.svg" height="14px" (click)="res.setLanguage('en')">
    </div>

</mat-toolbar>