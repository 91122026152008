<mat-toolbar mat-dialog-title style="vertical-align: middle;">
    <mat-toolbar-row>
    <mat-icon>pie_chart_outlined</mat-icon>
    <span style="margin-left: 10px">{{ res.strings('places_title') }}</span>
    <div style="padding-top:20px;" flexLayout="row" fxLayoutAlign="end center" fxFlex="1 1 auto" fxLayoutGap="10px">
        <form style="font-size: 12px;" fxLayoutAlign="center center" flexLayout="row">
            <mat-form-field fxFlex="1 1 180px">
                <mat-select [placeholder]="res.strings('places_order_placeholder')" [value]="order.getValue()" (selectionChange)="order.next($event.source.value)">
                    <mat-option *ngFor="let o of getOrders()" [value]="o">
                        <span>{{ getOrderName(o) }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
    <ng-container *ngIf="loading.getValue()">
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </ng-container>
    <mat-table #table [dataSource]="placesDataSource">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('name')"> {{ res.strings('places_name') }} </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle('name')"> {{ getWorkplaceName(element.place) }} </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['name'];" 
            (click)="onPlaceSelected(row)"
            [ngClass]="{ 'data-selected': row.isSelected }">
        </mat-row>
    </mat-table>
</mat-dialog-content>
<mat-dialog-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
    <mat-paginator #paginator [pageSizeOptions]="[6, 10, 20, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>
    <button mat-raised-button color="primary" (click)="onClose()" (keyup.enter)="onClose(); false" cdkFocusInitial>
        <mat-icon class="icon-button">close</mat-icon>
        <span>{{res.strings('button_close')}}</span>
    </button>
</mat-dialog-actions>
