import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SystemStatistic, EntityStatistic, LoadStatistic } from '../api';
import { DefaultService } from '../api';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { ResourceService } from '../services/resource.service';


class EntityDataSource implements DataSource<EntityStatistic> {

    entities = new BehaviorSubject<EntityStatistic[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<EntityStatistic[]> {
        return this.entities;
    }
    disconnect(collectionViewer: CollectionViewer): void {
        // do nothing
    }

}

@Component({
    selector: 'app-manage-system',
    templateUrl: './manage-system.component.html'
})
export class ManageSystemComponent implements OnInit, OnDestroy {

    private static readonly TIMEOUT_ERROR = 10000;
    private static readonly TIMEOUT_LOAD = 5000;
    private static readonly TIMEOUT_ENTITY = 60000;

    system: SystemStatistic;
    readonly entities = new EntityDataSource();
    load: LoadStatistic;
    columns = 1;

    private loadSubscription: Subscription;
    private entitySubscription: Subscription;
    private systemSubscription: Subscription;

    private loadTimer: any = null;
    private entityTimer: any = null;
    private systemTimer: any = null;

    constructor(public res: ResourceService, private apiClientService: DefaultService) { }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.updateColumns((event.target as Window).innerWidth);
    }

    updateColumns(width: number): void {
        if (width < 800) {
            this.columns = 1;
        } else {
            this.columns = 2;
        }
    }

    ngOnInit() {
        this.updateColumns(window.innerWidth);
        this.loadSubscription = this.updateLoad();
        this.entitySubscription = this.updateEntities();
        this.systemSubscription = this.updateSystem();
    }

    ngOnDestroy() {
        this.loadSubscription.unsubscribe();
        this.entitySubscription.unsubscribe();
        this.systemSubscription.unsubscribe();
        if (this.loadTimer != null) { clearTimeout(this.loadTimer); }
        if (this.entityTimer != null) { clearTimeout(this.entityTimer); }
        if (this.systemTimer != null) { clearTimeout(this.systemTimer); }
    }

    hasEntities(): boolean {
        return this.entities.entities.getValue().length === 0;
    }

    private updateLoad(): Subscription {
        return this.apiClientService.getLoadStatistic().subscribe((result) => {
            this.load = result;
            this.loadTimer = setTimeout(() => this.loadSubscription = this.updateLoad(), ManageSystemComponent.TIMEOUT_LOAD);
        }, (error) => {
            this.loadTimer = setTimeout(() => this.loadSubscription = this.updateLoad(), ManageSystemComponent.TIMEOUT_ERROR);
        });
    }

    private updateEntities(): Subscription {
        return this.apiClientService.getEntityStatistics().subscribe((result) => {
            this.entities.entities.next(result);
            this.entityTimer = setTimeout(() => this.entitySubscription = this.updateEntities(), ManageSystemComponent.TIMEOUT_ENTITY);
        }, (error) => {
            this.entityTimer = setTimeout(() => this.entitySubscription = this.updateEntities(), ManageSystemComponent.TIMEOUT_ERROR);
        });
    }

    private updateSystem(): Subscription {
        return this.apiClientService.getSystemStatistic().subscribe((result) => {
            this.system = result;
        }, (error) => {
            this.systemTimer = setTimeout(() => this.systemSubscription = this.updateSystem(), ManageSystemComponent.TIMEOUT_ERROR);
        });
    }

    canGetCpuLoad(): boolean {
        return this.system != null && this.load != null;
    }

    getCpuLoad(): number {
        return (this.load.cpuLoad / Math.max(1, this.system.processors) * 100);
    }

    getMemory(bytes: number): string {
        const megabytes = Math.floor(bytes / (1024 * 1024));
        const gb = Math.floor(megabytes / 1000);
        const mb = megabytes - (gb * 1000);
        let memory = '';
        if (gb > 0) {
            memory += gb + ' ' + this.res.strings(gb === 1 ? 'manage_system_gb' : 'manage_system_gbs');
        }
        memory += mb + ' ' +  this.res.strings(mb === 1 ? 'manage_system_mb' : 'manage_system_mbs');
        return memory;
    }

    getUptime(uptime: number): string {
        const time = this.getTime(uptime);
        let result = '';
        if (time.d > 0) {
            result += time.d + ' ' + this.res.strings(time.d > 1 ? 'manage_system_days' : 'manage_system_day');
        }
        if (time.d > 0 || time.h > 0) {
            result += time.h + ' ' + this.res.strings(time.h === 1 ? 'manage_system_hour' : 'manage_system_hours');
        }
        if (time.d > 0 || time.h > 0 || time.m > 0) {
            result += time.m + ' ' + this.res.strings(time.m === 1 ? 'manage_system_minute' : 'manage_system_minutes');
        }
        if (this.columns > 1) {
            result += time.s + ' ' + this.res.strings(time.s === 1 ? 'manage_system_second' : 'manage_system_seconds');
        }
        return result;
    }

    private getTime(ms: number): {d: number, h: number, m: number, s: number} {
        let d, h, m, s;
        s = Math.floor(ms / 1000);
        m = Math.floor(s / 60);
        s = s % 60;
        h = Math.floor(m / 60);
        m = m % 60;
        d = Math.floor(h / 24);
        h = h % 24;
        return { d: d, h: h, m: m, s: s };
    }


}
