import {Component, ViewChild, OnInit, OnDestroy, DoCheck, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultService, PlacePage, Place } from '../api';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { PaginationHandler } from '../utils/pagination';
import { PlaceService } from '../services/place.service';
import { NicknameDialogComponent } from '../widgets/nickname-dialog.component';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';
import { Snackbars } from '../utils/snackbars';
import { TableHandler, TableColumn } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-places',
  templateUrl: 'places.component.html'
})
export class PlacesComponent implements OnInit, OnDestroy, DoCheck {

  private static COLUMNS: TableColumn[] = [
    { name: 'id', dropPriority: 1, width: 130, fixedWidth: true },
    { name: 'address', dropPriority: 0, width: 200 },
    { name: 'nickname', dropPriority: 0, width: 200 },
    { name: 'longitude', dropPriority: 4, width: 100, fixedWidth: true  },
    { name: 'latitude', dropPriority: 4, width: 100, fixedWidth: true  },
    { name: 'creationTime', dropPriority: 2, width: 130, fixedWidth: true },
    { name: 'modificationTime', dropPriority: 3, width: 130, fixedWidth: true },
    { name: 'actions', dropPriority: 0, width: 130, fixedWidth: true }
  ];

  private static FALLBACK: TableColumn[] = [
    { name: 'name', dropPriority: 0, width: 130 },
    { name: 'actions', dropPriority: 0, width: 130, fixedWidth: true }
  ];

  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: DataSource<Place>;
  loading: BehaviorSubject<boolean>;
  order: BehaviorSubject<String>;
  deleting = new BehaviorSubject<boolean>(false);
  private pageHandler: PaginationHandler<Place, PlacePage>;
  private tableHandler: TableHandler;


  constructor(
    public res: ResourceService,
    private apiClientService: DefaultService,
    private placeService: PlaceService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog) {
      this.dataSource = placeService;
      this.loading = placeService.loading;
      this.order = placeService.order;
  }

  ngDoCheck(): void {
    this.tableHandler.onCheck();
  }

  getNumber(index: number): number {
    if (this.placeService.page.getValue() != null) {
      return this.placeService.page.getValue().offset + 1 + index;
    } else {
      return index + 1;
    }
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  getName(place: Place): string {
    if (place.nickname != null && place.nickname.length > 0) {
      return place.nickname;
    } else {
      return place.address;
    }
  }

  getOrderName(order: String) {
    return this.res.strings('places_order_value_' + order.toLowerCase());
  }

  getOrders(): string[] {
    return [ 'TIME', 'IMPORTANCE', 'DURATION', 'FREQUENCY', 'IDENTIFIER' ];
  }

  ngOnInit() {
    this.tableHandler = new TableHandler(this.table, PlacesComponent.COLUMNS, PlacesComponent.FALLBACK);
      this.pageHandler = new PaginationHandler(this.paginator, this.placeService);
      this.placeService.reload();
  }

  ngOnDestroy() {
    this.pageHandler.destroy();
  }

  onRename(id: number) {
    if (this.loading.getValue()) { return; }
    const p = this.placeService.page.getValue();
    for (const place of p.elements) {
      if (place.id === id) {
        NicknameDialogComponent.open(this.dialog, place).subscribe((result) => {
          if (result) { this.placeService.reload(); }
        });
        break;
      }
    }
  }

  onDelete(id: number): void {
    ConfirmationDialogComponent.open(this.dialog, {
      title: this.res.strings('places_delete_confirm_title'),
      message: this.res.strings('places_delete_confirm_message'),
      positive: this.res.strings('button_yes'),
      negative: this.res.strings('button_no')
    }).subscribe((result) => {
      if (result != null && result.confirmed) {
        this.doDelete(id);
      }
    });
  }

  private doDelete(id: number): void {
    this.deleting.next(true);
    this.apiClientService.deletePlace(id).subscribe((result) => {
      this.deleting.next(false);
      this.placeService.reload();
      Snackbars.showMessage(this.snackbar, this.res.strings('places_delete_success'), Snackbars.DURATION_SHORT);
    }, (error) => {
      this.deleting.next(false);
      this.placeService.reload();
      Snackbars.showError(this.snackbar, this.res.strings('places_delete_error'));
    });
  }



}
