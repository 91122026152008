<app-marker-map>
    <mat-accordion>
        <mat-expansion-panel class="mat-typography" style="width:280px;margin-right:10px;margin-top:10px">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">share</mat-icon>{{ res.strings('share_title') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="detail-row">
                <span class="detail-label">{{ res.strings('share_token') }}</span>
                <span class="detail-value" style="font-family: Roboto Mono">{{ token }}</span>
            </div>
            <ng-container *ngIf="info | async as in">
                <ng-container *ngIf="in.name">
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('share_name') }}</span>
                        <div class="detail-value">{{ in.name }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="in.message">
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('share_message') }}</span>
                        <div class="detail-value">{{ in.message }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="in.destination">
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('share_destination') }}</span>
                        <div class="detail-value">{{ in.destination }}</div>
                    </div>
                </ng-container>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('share_expiration') }}</span>
                    <span class="detail-value">{{ in.expirationTime | date:'short' }}</span>
                </div>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!isUpdatingInfo();else progressBarInfo">
                    <button mat-raised-button color="primary" (click)="updateInfo()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarInfo>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-typography" style="width:280px;margin-right:10px;margin-bottom:10px" [expanded]="false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">my_location</mat-icon>{{ res.strings('share_location') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="location | async as l">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('share_time') }}</span>
                    <span class="detail-value">{{ l.timestamp | date:'short' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('share_availability') }}</span>
                    <span class="detail-value">{{ l.availability }}</span>
                </div>
                <ng-container *ngIf="l.coordinate">
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('share_longitude') }}</span>
                        <span class="detail-value" style="font-family: Roboto Mono">{{ l.coordinate.longitude | number:'1.5-5' }}</span>
                    </div>
                    <div class="detail-row">
                        <span class="detail-label">{{ res.strings('share_latitude') }}</span>
                        <span class="detail-value" style="font-family: Roboto Mono">{{ l.coordinate.latitude | number:'1.5-5' }}</span>
                    </div>
                </ng-container>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!isUpdatingLocation();else progressBarLocation">
                    <button mat-raised-button color="primary" (click)="updateLocation()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarLocation>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-container class="fab" *ngIf="isOutside() && info | async as in">
        <div class="fab">
            <button mat-fab [matTooltip]="res.strings('share_add_tooltip')" (click)="addShare()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </ng-container>
</app-marker-map>