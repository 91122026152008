<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>pie_chart_outlined</mat-icon>
            <span>{{ res.strings('places_title') }}</span>
        </div>

        <div class="header-actions">
            <form style="font-size: 12px;">
                <mat-form-field>
                    <mat-select [placeholder]="res.strings('places_order_placeholder')" 
                        [value]="order.getValue()" 
                        (selectionChange)="order.next($event.source.value)">
                            <mat-option *ngFor="let o of getOrders()" [value]="o">
                                <span>{{ getOrderName(o) }}</span>
                            </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="loading.getValue() || deleting.getValue()">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('id')"> {{ res.strings('places_id') }} </mat-header-cell>
                <mat-cell *matCellDef="let i = index" [ngStyle]="getStyle('id')"> {{ getNumber(i) }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="address">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('address')"> {{ res.strings('places_address') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('address')"> {{element.address}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nickname">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('nickname')"> {{ res.strings('places_nickname') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('nickname')"> {{element.nickname}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('name')"> {{ res.strings('places_name') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngStyle]="getStyle('name')"> {{ getName(element) }} </mat-cell>
                </ng-container>
            <ng-container matColumnDef="longitude">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('longitude')"> {{ res.strings('places_longitude') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" style="font-family: Roboto Mono" [ngStyle]="getStyle('longitude')"> {{element.coordinate.longitude | number:'1.5-5' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="latitude">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('latitude')"> {{ res.strings('places_latitude') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" style="font-family: Roboto Mono" [ngStyle]="getStyle('latitude')"> {{element.coordinate.latitude | number:'1.5-5' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="creationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('creationTime')"> {{ res.strings('places_creation') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('creationTime')"> {{element.creationTime | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="modificationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('modificationTime')"> {{ res.strings('places_modification') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('modificationTime')"> {{element.modificationTime | date:'short'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="action-cell" [ngStyle]="getStyle('actions')"> Aktionen </mat-header-cell>
                <mat-cell *matCellDef="let element"  class="action-cell" [ngStyle]="getStyle('actions')">
                    <button mat-icon-button [matTooltip]="res.strings('places_rename_tooltip')" (click)="onRename(element.id)" [disabled]="loading.getValue() || deleting.getValue()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="res.strings('places_visits_tooltip')" [routerLink]="element.id" [disabled]="loading.getValue()  || deleting.getValue()">
                        <mat-icon>beenhere</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="res.strings('places_delete_tooltip')" (click)="onDelete(element.id)" [disabled]="loading.getValue() || deleting.getValue()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
        </mat-paginator>
    </main>
</app-content-layout>