<app-content-layout>
    <app-content-header [headerClass]="'header-transparent'">
        <div class="header-title">
            <mat-icon>access_time_icon</mat-icon>
            <span>{{ res.strings('time_sheet_title') }}</span>
        </div>

        <div class="header-actions">
            <button mat-icon-button 
                [matTooltip]="res.strings('time_sheet_update_btn_tooltip')"
                (click)="updateTimeSheetTable()">
                    <mat-icon>update_icon</mat-icon>
            </button>

            <button mat-icon-button 
                [matMenuTriggerFor]="exportMenu"
                [matTooltip]="res.strings('time_sheet_export_btn_tooltip')">
                    <mat-icon>import_export</mat-icon>
            </button>
            <mat-menu #exportMenu="matMenu"  class="mat-elevation-z1">
                <ng-template matMenuContent>
                    <button mat-menu-item 
                        (click)="exportTimeSheet('csv')" 
                        [disabled]="!timeSheetService.isTimeSheetReady">
                            {{ res.strings('time_sheet_export_as_csv_label') }}
                    </button>
                    <button mat-menu-item 
                        (click)="exportTimeSheet('json')" 
                        [disabled]="!timeSheetService.isTimeSheetReady">
                            {{ res.strings('time_sheet_export_as_json_label') }}
                    </button>
                </ng-template>
            </mat-menu>
        </div>
    </app-content-header>

    <!-- Configuration Side Panel -->
    <aside>
        <mat-list>
            <mat-list-item class="flex-vrt-center">
                <h3 mat-subheader>{{ res.strings('time_sheet_config_label') }}</h3>
            </mat-list-item>
            <mat-list-item class="flex-vrt-center">
                <mat-button-toggle-group 
                    [(value)]="timeSheetService.grouped"
                    name="groupingChoice" 
                    aria-label="Grouping Choice">
                        <mat-button-toggle [value]="1" [matTooltip]="res.strings('time_sheet_toggle_default_tooltip')">
                            {{ res.strings('time_sheet_toggle_default_label') }}</mat-button-toggle>
                        <mat-button-toggle [value]="2" [matTooltip]="res.strings('time_sheet_toggle_acc_tooltip')">
                            {{ res.strings('time_sheet_toggle_acc_label') }}</mat-button-toggle>
                        <mat-button-toggle [value]="3" [matTooltip]="res.strings('time_sheet_toggle_by_days_tooltip')">
                            {{ res.strings('time_sheet_toggle_by_days_label') }}</mat-button-toggle>
                        <mat-button-toggle [value]="4" [matTooltip]="res.strings('time_sheet_toggle_raw_tooltip')" hidden>
                            {{ res.strings('time_sheet_toggle_raw_label') }}</mat-button-toggle>
                </mat-button-toggle-group>
            </mat-list-item>
            <mat-list-item class="vrt-margin">
                <mat-form-field class="hrz-margin" [matTooltip]="res.strings('time_sheet_select_device_tooltip')">
                    <mat-label>{{ res.strings('time_sheet_select_device_label') }}</mat-label>
                    <mat-select
                        [(value)]="timeSheetService.device">
                        <mat-option [value]="null">{{ res.strings('time_sheet_no_device_selected') }}</mat-option>
                        <ng-container *ngIf="devices">
                            <mat-option *ngFor="let device of devices" [value]="device">
                                {{ device.model }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </mat-list-item>
            <mat-list-item class="vrt-margin">
                <mat-form-field class="hrz-margin" [matTooltip]="res.strings('time_sheet_break_time_tooltip')">
                    <mat-label>{{ res.strings('time_sheet_break_time_label') }}</mat-label>
                    <mat-select
                        [(value)]="timeSheetService.breakTime"
                        [disabled]="timeSheetService.grouped > 2">
                            <mat-option [value]="null">{{ res.strings('time_sheet_no_break_time_selected') }}</mat-option>
                            <mat-option *ngFor="let opt of breakTimeOptions" [value]="opt">
                                {{ opt + ' ' + res.strings('time_sheet_break_time_minutes') }}
                            </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-list-item>
            <mat-list-item class="vrt-margin">
                <mat-form-field class="hrz-margin" [matTooltip]="res.strings('time_sheet_time_period_tooltip')">
                    <mat-label>{{ res.strings('time_sheet_time_period_label') }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Start date" 
                        [value]="timeSheetService.dateRange.start" 
                        (dateChange)="setStart($event.target.value)">
                      <input matEndDate placeholder="End date" 
                        [value]="timeSheetService.dateRange.end"  
                        (dateChange)="setEnd($event.target.value)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </mat-list-item>

            <mat-divider class="divider"></mat-divider>

            <mat-list-item class="flex-vrt-center">
                <button mat-subheader mat-button (click)="openWorkPlacesDialog()">
                    <mat-icon class="icon-button">list_icon</mat-icon>
                    <span>{{ res.strings('time_sheet_workplace_button') }}</span>
                </button>
            </mat-list-item>
            <ng-container *ngIf="workPlaces.length > 0">
                <mat-divider class="divider"></mat-divider>

                <mat-list-item class="hrz-margin" *ngFor="let workPlace of workPlaces">
                    <button mat-icon-button (click)="deleteWorkPlaceEntry(workPlace)">
                        <mat-icon class="icon-button">clear_icon</mat-icon>
                    </button>
                    <span>{{ getWorkplaceName(workPlace) }}</span>
                </mat-list-item>

                <mat-divider class="divider"></mat-divider>

                <mat-list-item>
                    <button mat-button style="margin-bottom: 10px;" (click)="clearWorkPlaceList()" color="primary">
                        <mat-icon class="icon-button" style="margin-left: 12px">delete_icon</mat-icon>
                        <span>{{ res.strings('time_sheet_workplace_clear_list') }}</span>
                    </button>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </aside>

    <!-- Table Content -->
    <main class="mat-elevation-z2">
        <ng-container *ngIf="false">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <table mat-table #timeSheet class="main-table" [dataSource]="timeSheetDataSource">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_date') }}</th>
                <td mat-cell *matCellDef="let data">{{ getDate(data) }}</td>
            </ng-container>

            <ng-container matColumnDef="from-time">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_from') }}</th>
                <td mat-cell *matCellDef="let data">
                    {{ data.beginDate | date:'H:mm' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="to-time">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_to') }}</th>
                <td mat-cell *matCellDef="let data">
                    {{ data.endDate | date:'H:mm' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_working_time') }}</th>
                <td mat-cell *matCellDef="let data">{{ getWorkingTime(data.duration) }}</td>
            </ng-container>

            <ng-container matColumnDef="place">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_place') }}</th>
                <td mat-cell *matCellDef="let data">{{ getWorkplaceString(data) }}</td>
            </ng-container>

            <ng-container matColumnDef="device">
                <th mat-header-cell *matHeaderCellDef>{{ res.strings('time_sheet_table_device') }}</th>
                <td mat-cell *matCellDef="let data">{{ getDeviceNameString(data) }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showTimeSheetRowInfo(row)"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </main>
</app-content-layout>
