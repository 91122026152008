<mat-card class="mat-elevation-z0">
    <mat-card-title><mat-icon style="font-size: 18px;">subject</mat-icon>{{res.strings('log_dialog_title')}}</mat-card-title>
    <mat-card-content style="overflow: auto">
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_id')}}</span>
            <span class="detail-value">{{ message.id }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_thread')}}</span>
            <span class="detail-value">{{ message.thread }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_time')}}</span>
            <span class="detail-value">{{ message.time | date:'shortDate' }} {{ message.time | date:'H:mm:ss.SSS' }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_level')}}</span>
            <span class="detail-value">{{ message.level }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_logger')}}</span>
            <span class="detail-value">{{ message.loggerName }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_class')}}</span>
            <span class="detail-value">{{ message.className }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_method')}}</span>
            <span class="detail-value">{{ message.methodName }}</span>
        </div>

        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_message')}}</span>
            <span class="detail-value">{{ message.message }}</span>
        </div>
        <div class="detail-row">
            <span class="detail-label">{{res.strings('log_dialog_trace')}}</span>
            <span class="detail-value">{{ message.trace }}</span>
        </div>

    </mat-card-content>
    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
        <button mat-raised-button color="primary" (click)="onClose()">
            <mat-icon class="icon-button">done</mat-icon>
            <span>{{res.strings('button_close')}}</span>
        </button>
    </mat-card-actions>
</mat-card>