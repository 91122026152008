import { Component, OnInit, Input } from '@angular/core';

/**
 * Represents a single point in a coordinate system.
 * Used to draw the polylines onto the inline svg.
 */
export type Point = [number, number];

/**
 * Specifies the configuration of a single axis of the Chart.
 */
export interface AxisConfig { 
  label: string;
  from: number;
  to: number;
  stride: number;
  fontSize?: number;
  fill?: string;
};

/**
 * Chart component which draws an inline SVG with the specified configurations.
 */
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styles: [
    '.axis { fill: none; stroke: black; stroke-width: 3px; }',
    '.grid { fill: none; stroke: grey; stroke-width: 0.5px; }',
    '.flex-container { display: flex; flex-wrap: wrap; margin-top: 0px }'
  ]
})
export class ChartComponent implements OnInit {
  /**
   * Width of the entire inline svg. Mandatory!
   */
  @Input() width: number;
  /**
   * Height of the entire inline svg. Mandatory!
   */
  @Input() height: number;
  /**
   * Configuration for the X-Axis line. Mandatory!
   */
  @Input() xAxisConfig: AxisConfig;
  /**
   * Configuration for the Y-Axis line. Mandatory!
   */
  @Input() yAxisConfig: AxisConfig;
  /**
   * Factor which specifies the zoom of the whole chart.
   * Default value is 2.
   */
  @Input() zoomFactor: number;
  /**
   * The padding from the axes to the border of the svg.
   * Default value is 50px.
   */
  @Input() borderPadding: number;
  /**
   * If true, there are horizontally drawn lines along the
   * labeled Y-Axis line values.
   */
  @Input() horizontalGrid: boolean;
  /**
   * If true, there are vertically drawn lines along the
   * labeled X-Axis line values.
   */
  @Input() verticalGrid: boolean;
  /**
   * Specfies how the X-Axis line values are anchored.
   */
  readonly xtextAnchor = 'end';
  /**
   * Specfies how the Y-Axis line values are anchored.
   */
  readonly ytextAnchor = 'end';
  /**
   * Represents the actual length of each axis in pixels where the 
   * border padding is already taken in account.
   */
  private effectiveScreenLength: { xAxis: number, yAxis: number };
  /**
   * Represents the ratio in which one stride is converted into pixels.
   */
  private axisRatio: { x: number, y: number };
  /**
   * Represents the origin of the chart. So to speak, it's the 
   * position where x and y are 0.
   */
  private origin: { x: number, y: number };
  /**
   * The viewBox configuration of the inline svg. 
   */
  viewBox: ViewBox;
  /**
   * The X-Axis line object.
   */
  xAxis: AxisLine;
  /**
   * The Y-Axis line object.
   */
  yAxis: AxisLine;
  /**
   * The lines which are drawn onto the chart.
   */
  chartLines: ChartLine[];

  ngOnInit(): void {
    this.checkInput();
    this.chartLines = [];

    if (!this.zoomFactor) this.zoomFactor = 2;
    if (!this.borderPadding) this.borderPadding = 50;

    let xAxisAbsolutRange = Math.abs(this.xAxisConfig.from) + Math.abs(this.xAxisConfig.to);
    let yAxisAbsolutRange = Math.abs(this.yAxisConfig.from) + Math.abs(this.yAxisConfig.to);
    this.effectiveScreenLength = {
      xAxis: this.width * this.zoomFactor,
      yAxis: this.height * this.zoomFactor
    };
    this.axisRatio = {
      x: this.effectiveScreenLength.xAxis / xAxisAbsolutRange,
      y: this.effectiveScreenLength.yAxis / yAxisAbsolutRange
    };
    this.origin = {
      x: Math.abs(this.xAxisConfig.from) * this.axisRatio.x,
      // Needs to be 'to' because svg origin starts at the upper left corner.
      y: Math.abs(this.yAxisConfig.to) * this.axisRatio.y
    };
    this.viewBox = new ViewBox(
      -this.borderPadding, 
      -this.borderPadding,
      this.effectiveScreenLength.xAxis + this.borderPadding * 2,
      this.effectiveScreenLength.yAxis + this.borderPadding * 2
    );

    let labelPadding = this.borderPadding / 2;
    let markerPadding = this.borderPadding * 0.4;
    this.xAxis = new AxisLine(
      'X-Axis',
      this.xAxisConfig,
      this.origin.y,
      this.effectiveScreenLength.xAxis,
      this.axisRatio.x,
      labelPadding,
      markerPadding,
      true,
      this.verticalGrid
    );
    this.yAxis = new AxisLine(
      'Y-Axis',
      this.yAxisConfig,
      this.origin.x,
      this.effectiveScreenLength.yAxis,
      this.axisRatio.y,
      -labelPadding,
      -5,
      false,
      this.horizontalGrid
    );
  }

  /**
   * Draws a ChartLine object onto the chart.
   * 
   * @param points Points which are drawn onto the Chart and representing the ChartLine.
   * @param label The name which is associated with the ChartLine.
   * @param color The color of the ChartLine.
   * @param strokeWidth The stroke width of the Chartline
   */
  addChartLine(points: Point[], label: string, color: string, strokeWidth: number): void {
    let processed = this.processingPoints(points);
    this.chartLines.push(new ChartLine(
      label,
      processed, 
      { fill: 'none', stroke: color, 'stroke-width': `${strokeWidth}px` },
      label,
      { 'margin': '1px', 'padding-left': '10px', 'padding-top': '1px', color: color }
    ));
  }

  /**
   * Cleares the chart from all ChartLines.
   */
  clearChartLines(): void {
    this.chartLines = [];
  }

  /**
   * Returns the width of the legend box where the 
   * names of every single ChartLine is displayed.
   */
  getLegendWidth() {
    return { width: `${this.width}px` };
  }

  /**
   * Checks if the mandatory Input is specified.
   */
  private checkInput() {
    if (!this.width) throw new TypeError(`'width' is required!`);
    if (!this.height) throw new TypeError(`'height' is required!`);
    if (!this.xAxisConfig) throw new TypeError(`'xAxisConfig' is required!`);
    if (!this.yAxisConfig) throw new TypeError(`'yAxisConfig' is required!`);
  }

  /**
   * Processes the given Point array to fit onto the chart's svg ratio properly.
   *  
   * @param points The points that should be displayed as a polyline on the chart.
   */
  private processingPoints(points: Point[]): Point[] {
    if (points.length === 0) return;

    let res: Point[] = [];
    let curr: Point;
    for (let i = 0; i < points.length; i++) {
      curr = points[i];
      let processedPoints = [0, 0] as Point;
      processedPoints[0] = this.origin.x + curr[0] * this.axisRatio.x;
      processedPoints[1] = this.origin.y - curr[1] * this.axisRatio.y;
      res.push(processedPoints);
    }
    return res
  }
}

/**
 * A SVG element representation of <polyline>.
 * Mor infos on: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/polyline
 */
class Polyline {
  constructor(public readonly points: Point[]) { }

  public toString(): string {
    let res = '';
    for(let point of this.points)
      res += point[0] + ',' + point[1] + ' ';
    return res.trim();
  }
}

/**
 * A SVG attribute representation of viewBox.
 * More infos on: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox
 */
class ViewBox {
  constructor(
    public readonly x: number, 
    public readonly y: number, 
    public readonly width: number, 
    public readonly height: number
  ) { }

  public toString(): string {
    return `${this.x} ${this.y} ${this.width} ${this.height}`;
  }
}

/**
 * A SVG element representation of <text>.
 * More infos on: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/text
 */
class Text {
  readonly x: number;
  readonly y: number;

  constructor(
    public readonly text: string, 
    public readonly fontSize: number, 
    public readonly fill: string, 
    point: Point
  ) {
    this.x = point[0];
    this.y = point[1];
  }

  public toString(): string {
    return this.text;
  }
}

/**
 * A custom class representing a chart line which is drawn onto the chart.
 * While it is drawn as a polyline onto the inline SVG it contians furthermore
 * information about the line like the label or the style.
 */
class ChartLine extends Polyline {
  constructor(
    public readonly id: string,
    public readonly points: Point[], 
    public readonly pointsStyle: any,
    public readonly label: string,
    public readonly labelStyle: any
  ) { 
    super(points);
  }
}

/**
 * A custom class representing an axis line with all necessary informations.
 */
class AxisLine {

  static readonly FONT_SIZE = 25;

  readonly axisPolyline: Polyline;
  readonly gridPolylines: Polyline[];
  readonly axisLineLabels: Text[];
  readonly axisLabel: Text;

  constructor(
    readonly id: string,
    readonly config: AxisConfig,
    readonly fixture: number,
    readonly length: number,
    readonly ration: number,
    readonly labelPadding: number,
    readonly markerPadding: number,
    readonly isX: boolean,
    readonly withGrid?: boolean
  ) {
    this.axisLineLabels = [];
    this.gridPolylines = [];
    
    const fontSize = config.fontSize ? config.fontSize : AxisLine.FONT_SIZE;
    const fill = config.fill ? config.fill : 'black';
    
    let points: Point[] = [];
    let yMarkerDownShift: number;
    let axisPointIndex: number;
    let axisMarkerStart: number;
    let axisMarkerStep: number;
    let axisMarkerCheck: (val: number) => boolean;
    if (isX) {
      yMarkerDownShift  = 0;
      axisPointIndex    = 0;
      axisMarkerStart   = config.from;
      axisMarkerStep    = config.stride;
      axisMarkerCheck   = (val: number): boolean => val <= config.to;
    } else {
      yMarkerDownShift  = fontSize / 5;
      axisPointIndex    = 1;
      axisMarkerStart   = config.to;
      axisMarkerStep    = -config.stride;
      axisMarkerCheck   = (val: number): boolean => val >= config.from;
    } 
    
    for (
      let i = 0, axisMarkerLabel = axisMarkerStart; 
      i <= length && axisMarkerCheck(axisMarkerLabel);
      i += (ration * config.stride), axisMarkerLabel += axisMarkerStep
    ) {
      points.push(this.createPoint(axisPointIndex, fixture, i));
      if (axisMarkerLabel !== 0) {
        this.axisLineLabels.push(
          new Text(
            String(axisMarkerLabel), 
            fontSize * (3/4), 
            fill, 
            this.createPoint(axisPointIndex, fixture + markerPadding, i + yMarkerDownShift)
          )
        );
        if (withGrid) {
          let gridPoints: Point[] = [];
          gridPoints.push(this.createPoint(axisPointIndex, 0, i));
          gridPoints.push(this.createPoint(axisPointIndex, length, i))
          this.gridPolylines.push(new Polyline(gridPoints));
        }
      }
    }
    this.axisPolyline = new Polyline(points);

    let axisLineMarker: Text;
    let labelPos: number;
    let labelFixture: number;
    if (isX) {
      axisLineMarker = this.axisLineLabels[this.axisLineLabels.length - 1];
      labelPos = axisLineMarker.x - config.label.length;
      labelFixture = axisLineMarker.y + labelPadding;
    } else {
      axisLineMarker = this.axisLineLabels[0];
      labelPos = axisLineMarker.y + labelPadding;
      labelFixture = axisLineMarker.x;
    }
    this.axisLabel = new Text(
      config.label, fontSize, fill,
      this.createPoint(axisPointIndex, labelFixture, labelPos)
    );
  }

  /**
   * Creates a point which is part of the points array of the drawn polyline
   * representing the axis or the location of the axis label. 
   * 
   * @param axisPointIndex Determines the index of the Point tuple to which 
   *                       the variable value is written. 
   * @param fixture The fixture value that determinse on which axis the Point relates to. 
   *                -> e.g. When writing on the X-Axis the fixture value is y = maximal height.
   * @param value The value on the axis.
   * @return A Point object.
   */
  private createPoint(axisPointIndex: number, fixture: number, value: number): Point {
    let p: Point = [0, 0];
    p[axisPointIndex++] = value;
    p[axisPointIndex % 2] = fixture;
    return p;
  }
}
