<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>forum</mat-icon>
            <span>{{ res.strings('manage_notices_title') }}</span>
        </div>
        <div class="header-actions flex-row-nowrap align-center">
            <app-language-select [value]="language" (change)="language = $event"></app-language-select>
            <button mat-icon-button matTooltip="Hinweis hinzufügen" routerLink="0" [disabled]="loading.getValue()">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="loading.getValue()">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <mat-table #table [dataSource]="dataSource">

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('id')">{{ res.strings('manage_notices_id') }}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('id')"> {{element.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="languages">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('languages')"> {{ res.strings('manage_notices_languages') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('languages')">
                    <ng-container *ngFor="let lang of languages">
                        <ng-container *ngIf="hasLanguage(element, lang.value)">
                            <img [src]='lang.flag' height="15px">
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('creationTime')"> {{ res.strings('manage_notices_creation') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('creationTime')"> {{element.creationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="modificationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('modificationTime')"> {{ res.strings('manage_notices_modification') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('modificationTime')"> {{element.modificationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expirationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('expirationTime')"> {{ res.strings('manage_notices_expiration') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('expirationTime')"> {{element.expirationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('title')"> {{ res.strings('manage_notices_header') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('title')">{{ getTitle(element) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="published">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('published')"> {{ res.strings('manage_notices_published') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('published')">
                    <mat-icon class="icon-button">{{ element.published?"check_box":"check_box_outline_blank" }}</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="announced" >
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('announced')"> {{ res.strings('manage_notices_announced') }}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('announced')">
                    <mat-icon class="icon-button">{{ element.announced?"notifications_active":"notifications_none" }}</mat-icon>
                    ({{ element.minimumVersion}}/{{element.maximumVersion}})
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="action-cell" [ngStyle]="getStyle('actions')"> {{ res.strings('manage_notices_actions') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action-cell" [ngStyle]="getStyle('actions')">
                    <ng-container *ngIf="element.deleted; else active">
                        <button mat-icon-button [matTooltip]="res.strings('manage_notices_restore_tooltip')" (click)="onRestore(element)" [disabled]="loading.getValue()">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </ng-container>
                    <ng-template #active>
                        <button mat-icon-button [matTooltip]="res.strings('manage_notices_delete_tooltip')" (click)="onDelete(element)" [disabled]="loading.getValue()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-template>
                    <button mat-icon-button [matTooltip]="res.strings('manage_notices_edit_tooltip')" [routerLink]="element.id" [disabled]="loading.getValue()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
        </mat-paginator>
    </main>
</app-content-layout>