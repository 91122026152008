import { MatSnackBar } from '@angular/material/snack-bar';

export class Snackbars {

    public static readonly DURATION_SHORT = 5000;

    public static readonly DURATION_LONG = 10000;

    /**
     * Shows an error message that disappears after a short period of time.
     *
     * @param snackbar The snackbar service.
     * @param message The message.
     */
    public static showError(snackbar: MatSnackBar, message: string, duration?: number) {
      if (duration == null) {
        snackbar.open(message, null, { panelClass: ['snackbar-accent'], duration: Snackbars.DURATION_SHORT});
      } else {
        snackbar.open(message, null, { panelClass: ['snackbar-accent'], duration: duration});
      }
    }

    /**
     * Shows a permanent information message that does not disapper automatically.
     *
     * @param snackbar The snackbar service.
     * @param message The message.
     */
    public static showMessage(snackbar: MatSnackBar, message: string, duration?: number) {
        if (duration != null) {
          snackbar.open(message, null, { panelClass: ['snackbar-primary'], duration: duration});
        } else {
          snackbar.open(message, null, { panelClass: ['snackbar-primary']});
        }
    }
}
