<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>

    <mat-toolbar color="primary" class="mat-elevation-z4" style="z-index: 10;position:relative">
         <ng-container *ngIf="compact; else noMenuButton">
            <button mat-icon-button (click)="toggleDrawer()"><mat-icon>{{ opened?'arrow_back':'menu' }}</mat-icon></button>
         </ng-container>
         <ng-template #noMenuButton><img src="assets/icons/home.svg" width="24px" height="24px" style="padding-right: 8px;padding-left:8px"></ng-template>
         <span>Maptology</span>
         <div flexLayout="row" fxLayoutAlign="end center" fxFlex="1 1 auto" fxLayoutGap="10px"> 
           <app-profile-button></app-profile-button> 
           <app-toolbar-button [title]="res.strings('button_logout')" color="accent" icon="lock" (click)="onLogout()"></app-toolbar-button>
         </div>
    </mat-toolbar>
  
    <mat-sidenav-container style="flex:1;" autosize>
      <mat-sidenav [mode]="compact?'over':'side'" [opened]="opened">
        <app-drawer></app-drawer> 
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  
    <app-footer></app-footer>
  
  </div>