import { Component, Input } from '@angular/core';

/**
 * Simple content header component which is containing the name of the page on
 * the left side and the action buttons on the right side.
 *
 * @author Adriano
 */
@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html'
})
export class ContentHeaderComponent {

  /* Attributes which are forwarding the styles and classes
   * to the mat-toolbar which where specified on the app-content-header.*/
  @Input() headerStyle?: { [klass: string]: any; };
  @Input() headerClass?: string | string[] | Set<string> | { [klass: string]: any; };

  constructor() { }

}
