import { Component, Input } from '@angular/core';
import { FormErrorEncoder } from '../utils/formcodes';
import { ResourceService } from '../services/resource.service';

/**
 * A validated field that enables a user to enter the
 * login name. The name will be available through the
 * components login which also acts as an input.
 */
@Component({
  selector: 'app-login-field',
  templateUrl: './login-field.component.html'
})
export class LoginFieldComponent extends FormErrorEncoder {

  /**
   * The login name
   */
  @Input() login: string;
  /**
   * Disables the field.
   */
  @Input() disabled: boolean;

  constructor(public res: ResourceService) {
    super();
   }
}
