/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate';


/**
 * Represents a location.
 */
export interface Location { 
    /**
     * The timestamp of the location.
     */
    timestamp: number;
    /**
     * The coordinate of the location.
     */
    coordinate?: Coordinate;
    /**
     * The availability information of the location.
     */
    availability: Location.AvailabilityEnum;
}
export namespace Location {
    export type AvailabilityEnum = 'FRESH' | 'CACHED' | 'UNABLE' | 'PRIVATE' | 'IDLE';
    export const AvailabilityEnum = {
        FRESH: 'FRESH' as AvailabilityEnum,
        CACHED: 'CACHED' as AvailabilityEnum,
        UNABLE: 'UNABLE' as AvailabilityEnum,
        PRIVATE: 'PRIVATE' as AvailabilityEnum,
        IDLE: 'IDLE' as AvailabilityEnum
    };
}
