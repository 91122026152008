import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, DoCheck, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DefaultService, Place, VisitPage, Visit } from '../api';
import { Snackbars } from '../utils/snackbars';
import { MarkerMapComponent } from '../widgets/marker-map.component';
import { PlaceVisitService } from '../services/place-visit.service';
import { PaginationHandler } from '../utils/pagination';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';
import { DataSource } from '@angular/cdk/table';
import { TableColumn, TableHandler } from '../utils/tables';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-place-visits',
    templateUrl: './place-visits.component.html'
})
export class PlaceVisitsComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

    private static COLUMNS: TableColumn[] = [
        { name: 'device', dropPriority: 0, width: 120 },
        { name: 'begin', dropPriority: 0, width: 130 },
        { name: 'end', dropPriority: 0, width: 130 },
        { name: 'actions', dropPriority: 0, width: 90, fixedWidth: true }
    ];

    private static FALLBACK: TableColumn[] = [
        { name: 'visit', dropPriority: 0, width: 120 },
        { name: 'actions', dropPriority: 0, width: 90, fixedWidth: true }
    ];

    @ViewChild(MarkerMapComponent, { static: true }) map: MarkerMapComponent;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;

    id: string;
    readonly loading: BehaviorSubject<boolean>;
    readonly place: BehaviorSubject<Place>;
    readonly page: BehaviorSubject<VisitPage>;
    readonly deleting = new BehaviorSubject(false);
    readonly dataSource: DataSource<Visit>;
    private paginationHandler: PaginationHandler<Visit, VisitPage>;
    private placeSubscription: Subscription;
    private tableHandler: TableHandler;
    compact = false;


    constructor(
        public res: ResourceService,
        private apiClientService: DefaultService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private placeService: PlaceVisitService,
        private route: ActivatedRoute
    ) {
        this.loading = placeService.loading;
        this.page = placeService.page;
        this.place = placeService.place;
        this.dataSource = placeService;
    }

    getPanelWidth(): any {
        if (this.compact) {
            return { width: '280px'};
        } else {
            return { width: '520px' };
        }
    }

    getDevice(id: number): string {
        const device = this.placeService.getReference(id);
        if (device != null) {
            return device.model;
        } else {
            return '' + id;
        }
    }

    reloadPlace() {
        this.placeService.setPlace(null);
        this.placeService.setPlace(+this.id);
    }
    getStyle(column: string): any {
        return this.tableHandler.getColumnStyle(column);
    }

    getColumns(): string[] {
        return this.tableHandler.getDisplayColumns();
    }

    ngDoCheck(): void {
        this.tableHandler.onCheck();
        this.compact = (this.map.getWidth() < 900);
    }

    ngOnInit() {
        this.tableHandler = new TableHandler(this.table, PlaceVisitsComponent.COLUMNS, PlaceVisitsComponent.FALLBACK);
        this.id = this.route.snapshot.paramMap.get('id');
        this.placeService.setPlace(+this.id);
        this.paginationHandler = new PaginationHandler<Visit, VisitPage>(this.paginator, this.placeService);
    }

    ngAfterViewInit() {
        this.placeSubscription = this.place.subscribe((place) => {
            if (place) {
                this.map.setPinPosition(place.coordinate);
            } else {
                this.map.setPinPosition(null);
            }
        });
    }

    ngOnDestroy() {
        this.paginationHandler.destroy();
        this.placeSubscription.unsubscribe();
    }

    onDelete(id: number): void {
        ConfirmationDialogComponent.open(this.dialog, {
            title: this.res.strings('visits_delete_confirm_title'),
            message: this.res.strings('visits_delete_confirm_message'),
            positive: this.res.strings('button_yes'),
            negative: this.res.strings('button_no')
        }).subscribe((result) => {
            if (result != null && result.confirmed) {
                this.doDelete(id);
            }
        });
    }

    private doDelete(id: number): void {
        this.deleting.next(true);
        this.apiClientService.deleteVisit(id).subscribe((result) => {
            this.deleting.next(false);
            this.placeService.reload();
            Snackbars.showMessage(this.snackbar, this.res.strings('visits_delete_success'), Snackbars.DURATION_SHORT);
        }, (error) => {
            this.deleting.next(false);
            this.placeService.reload();
            Snackbars.showError(this.snackbar, this.res.strings('visits_delete_error'));
        });
    }

}
