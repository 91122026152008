import { Inject, Injectable } from '@angular/core';
import { ConsoleService } from './console.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { LogMessage } from '../api';
import { CollectionViewer } from '@angular/cdk/collections';
import { CredentialService } from './credential.service';

@Injectable()
export class ManageLogService implements DataSource<LogMessage> {

    private static readonly MAXIMUM_MESSAGES = 250;
    private static readonly CONSOLE_OUTPUT = false;

    readonly connecting: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private messages: BehaviorSubject<LogMessage[]> = new BehaviorSubject([]);
    private subscriptions = 0;
    private socket: WebSocket;
    private timeout = null;
    private buffered: LogMessage[] = [];

    constructor(
        @Inject('wsdomain') private domain: string,
        private consoleService: ConsoleService,
        private credentialService: CredentialService) {
    }

    clear(): void {
        this.messages.next([]);
        this.buffered = [];
        if (this.timeout != null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    connect(collectionViewer: CollectionViewer): Observable<LogMessage[]> {
         return this.messages;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        // do nothing
    }


    open(): void {
        if (this.connecting.getValue() || this.socket) { return; }
        this.clear();
        this.consoleService.log('Connecting to server.');
        this.connecting.next(true);
        this.socket = new WebSocket(this.domain + '/log/stream');
        this.socket.addEventListener<'open'>('open', (event) => {
            this.create('Websocket connection opened.');
            this.socket.send(this.credentialService.createHeader());
        });
        this.socket.addEventListener<'message'>('message', (message) => {
            if (this.connecting.getValue()) { this.connecting.next(false); }
            const logMessage: LogMessage = JSON.parse(message.data);
            // send to console, if setup
            if (ManageLogService.CONSOLE_OUTPUT) { this.print(logMessage); }
            this.add(logMessage);
        });
        this.socket.addEventListener<'close'>('close', (close) => {
            this.connecting.next(false);
            this.create('Websocket connection closed.');
        });
    }

    private create(message: string) {
        this.add({
            id: 0, className: 'LogService', methodName: '',
            loggerName: '', level: 'INFO', thread: 0,
            time: new Date().getTime(), trace: null,
            message: message
        });
    }

    private add(logMessage: LogMessage): void {
        this.buffered.push(logMessage);
        if (this.timeout == null) {
            this.timeout = setTimeout(() => { this.submit(); }, 500);
        }
    }

    private submit(): void {
        // create new array and announce
        const end = this.messages.getValue().length;
        let start = 0;
        if (end >= ManageLogService.MAXIMUM_MESSAGES - this.buffered.length) {
            start = end - ManageLogService.MAXIMUM_MESSAGES + this.buffered.length;
        }
        const newMessages = this.messages.getValue().slice(start, end).concat(this.buffered);
        this.buffered = [];
        this.messages.next(newMessages);
        this.timeout = null;
    }

    private print(logMessage: LogMessage): void {
        let text = '[' + logMessage.id + '@' + logMessage.time
          + ' (TRD-' + logMessage.thread + ') ' + logMessage.className
          + '.' + logMessage.methodName + '] ' + logMessage.message;
        if (logMessage.trace != null) {
            text += '\n' + logMessage.trace;
        }
        this.consoleService.log(text);
    }

    close(): void {
        if (this.socket != null) {
            this.consoleService.log('Disconnecting from server.');
            this.socket.close();
            this.connecting.next(false);
            this.socket = null;
        }
    }

}
