<app-content-layout>
    <app-content-header [headerClass]="'header-transparent'">
        <div class="header-title">
            <mat-icon>cloud_download</mat-icon>
            <span>{{ res.strings('export_title') }}</span>
        </div>
    </app-content-header>

    <main class="flex-row-wrap justify-space-evenly">
        <mat-card class="mat-typography user-card">
            <mat-card-title-group>
                <mat-card-title>{{ res.strings('export_explain_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('export_explain_subtitle') }}</mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                <p class="mat-body">{{ res.strings('export_explain_content') }}</p>
                <p class="mat-body">{{ res.strings('export_explain_process') }}</p>
            </mat-card-content>
            <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="exportService.downloading | async; else exportButton">
                    <button mat-raised-button color="accent" (click)="exportService.stopDownload()">
                        <mat-icon class="icon-button">cancel</mat-icon>
                        <span>{{ res.strings('button_cancel') }}</span>
                    </button>        
                </ng-container>
                <ng-template #exportButton>
                    <button mat-raised-button color="primary" (click)="exportService.startDownload()">
                        <mat-icon class="icon-button">import_export</mat-icon>
                        <span>{{ res.strings('button_export') }}</span>
                    </button>
                </ng-template>
            </mat-card-actions>
        </mat-card>

        <mat-card class="mat-typography user-card">
            <mat-card-title-group>
                <mat-card-title>{{ res.strings('export_download_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('export_download_subtitle') }}</mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                    <mat-list>
                            <mat-divider></mat-divider>
                            <mat-list-item>                                    
                                <mat-label class="progress-label" matLine>{{ res.strings('export_devices') }}<div class="progress-float">{{ (exportService.devices | async)?.size }}</div></mat-label>
                                <mat-progress-bar mode="determinate" [value]="progressValue(exportService.devices | async)" matLine></mat-progress-bar>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <mat-label class="progress-label" matLine>{{ res.strings('export_places') }}<div class="progress-float">{{ (exportService.places | async)?.size }}</div></mat-label>
                                <mat-progress-bar mode="determinate" [value]="progressValue(exportService.places | async)" matLine></mat-progress-bar>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <mat-label class="progress-label" matLine>{{ res.strings('export_visits') }}<div class="progress-float">{{ (exportService.visits | async)?.size }}</div></mat-label>
                                <mat-progress-bar mode="determinate" [value]="progressValue(exportService.visits | async)" matLine></mat-progress-bar>
                            </mat-list-item>
                    </mat-list>
            </mat-card-content>
        </mat-card>
    </main>
</app-content-layout>