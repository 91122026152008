import { Injectable } from '@angular/core';

/**
 * A service to manage the user's credentials. This service only
 * stores the password and login name and provides methods to
 * access them. The actual user-related logic is contained in
 * the UserService. Application components should not use this
 * service, instead they should rely on the functions of the
 * UserService.
 *
 * To let the stored credentials survive a page reload (without
 * actually having a session on the server), the credentials are
 * stored in the browser's session storage (if available). According
 * to the docs, the session storage is "secure" enough to do this.
 * However, since Safari does not support session storage in the
 * private browsing mode, we also store the credentials in memory.
 */
@Injectable()
export class CredentialService {

    /**
     * The key to store the password in the session store.
     */
    private static KEY_PASSWORD = 'password';
    /**
     * The key to store the login in the session store.
     */
    private static KEY_LOGIN = 'login';

    /**
     * The login name.
     */
    private login: string;

    /**
     * The password.
     */
    private password: string;

    constructor() {
        this.loadCredentials();
    }

    /** Loads the credentials from the session store, if available. */
    private loadCredentials(): void {
        if (window.sessionStorage) {
            this.login = window.sessionStorage.getItem(CredentialService.KEY_LOGIN);
            this.password = window.sessionStorage.getItem(CredentialService.KEY_PASSWORD);
        }
    }

    /** Saves the credentials to the session store, if available. */
    private saveCredentials(): void {
        if (window.sessionStorage) {
            if (this.isSet(this.login)) {
                window.sessionStorage.setItem(CredentialService.KEY_LOGIN, this.login);
            } else {
                window.sessionStorage.removeItem(CredentialService.KEY_LOGIN);
            }
            if (this.isSet(this.password)) {
                window.sessionStorage.setItem(CredentialService.KEY_PASSWORD, this.password);
            } else {
                window.sessionStorage.removeItem(CredentialService.KEY_PASSWORD);
            }
        }
    }

    /**
     * Returns the login name, if set.
     *
     * @returns The login name.
     */
    getLogin(): string {
        return this.login;
    }

    /**
     * Sets the credentials contained in the
     * service to the specified login and password.
     *
     * @param login The login.
     * @param password The password.
     */
    setCredential(login: string, password: string): void {
        this.login = login;
        this.password = password;
        this.saveCredentials();
    }

    /**
     * Clears the credentials stored in the service
     */
    clearCredential(): void {
       this.setCredential(null, null);
    }

    /**
     * Determines whether the specified password
     * matches the stored password.
     *
     * @param password The password to check.
     * @returns True if the password matches.
     */
    hasPassword(password: string): boolean {
        return (this.password != null && this.password === password);
    }

    /** Determines whether the password and login name are set.
     *
     * @returns True if the password and the username are set.
     */
    hasCredential() {
        return (this.isSet(this.login) && this.isSet(this.password));
    }

    /**
     * Returns the auth header for the current username and password
     * or null, if none are set or the service is not authorizing or
     * authorized.
     *
     * @returns A basic auth header or null.
     */
    createHeader(): string {
        if (this.hasCredential()) {
            return 'Basic ' + btoa(this.login + ':' + this.password);
        } else {
            return null;
        }
    }


    /**
     * Determines whether the value is set by
     * checking that it is not null and has a
     * length > 0.
     *
     * @param value The value.
     */
    private isSet(value: string) {
        return value != null && value.length > 0;
    }


}
