import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LoginFieldComponent } from '../widgets/login-field.component';
import { PasswordFieldComponent } from '../widgets/password-field.component';
import { HttpStatusCode } from '../utils/httpcodes';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { NameFieldComponent } from '../widgets/name-field.component';
import { EmailFieldComponent } from '../widgets/email-field.component';
import { DefaultService, UserRegistration } from '../api';
import { HttpErrorResponse } from '@angular/common/http';
import { Snackbars } from '../utils/snackbars';
import { MessageDialogComponent } from '../widgets/message-dialog.component';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild(LoginFieldComponent, { static: true }) loginField: LoginFieldComponent;
  @ViewChild(PasswordFieldComponent, { static: true }) passwordField: PasswordFieldComponent;
  @ViewChild(NameFieldComponent, { static: true }) nameField: NameFieldComponent;
  @ViewChild(EmailFieldComponent, { static: true }) emailField: EmailFieldComponent;
  @ViewChild('registerForm', { static: true }) registerForm: NgForm;

  readonly idle = new BehaviorSubject<boolean>(true);

  columns = 2;
  registration: UserRegistration = { login: '', name: '', email: '', password: '' };

  termsAccepted: true;

  private registrationSubscription: Subscription;

  constructor(public res: ResourceService, private apiClientService: DefaultService,
    private snackbar: MatSnackBar, private router: Router, private dialog: MatDialog) { }


  ngOnInit(): void {
    this.updateColumns(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateColumns((event.target as Window).innerWidth);
  }

  private updateColumns(width: number): void {
      if (width < 800) {
          this.columns = 1;
      } else {
          this.columns = 2;
      }
  }

  onRegister(): void {
    this.snackbar.dismiss();
    if (!this.idle || !this.registerForm.valid) { return; }
    if (!this.termsAccepted) {
      this.showTermsMessage();
    } else {
      this.sendRegister();
    }
  }

  private showTermsMessage(): void {
    MessageDialogComponent.open(this.dialog, {
      title: this.res.strings('register_accept_terms_title'),
      message: this.res.strings('register_accept_terms_message'),
      button: this.res.strings('button_gotit') });
  }

  private sendRegister(): void {
    this.setIdle(false);
    this.registration = {
      login: this.loginField.login,
      name: this.nameField.name,
      password: this.passwordField.password,
      email: this.emailField.email
    };
    this.registrationSubscription = this.apiClientService.registerUser(this.registration).subscribe((response) => {
      this.setIdle(true);
      MessageDialogComponent.open(this.dialog, {
        title: this.res.strings('register_success_title'),
        message: this.res.strings('register_success_message'),
        button: this.res.strings('button_gotit') }).subscribe((e) => {
        this.router.navigate(['/']);
      });
      this.registrationSubscription = null;
    }, (error) => {
    this.registrationSubscription = null;
      if (error instanceof HttpErrorResponse) {
        let message = '';
        switch (error.status) {
          case HttpStatusCode.CONFLICT:
            message = this.res.strings('register_conflict_error');
            break;
          default:
            message = this.res.strings('register_network_error');
            break;
        }
        this.setIdle(true);
        Snackbars.showError(this.snackbar, message);
      }
    });
  }

  private setIdle(idle: boolean): void {
    this.loginField.disabled = !idle;
    this.emailField.disabled = !idle;
    this.passwordField.disabled = !idle;
    this.nameField.disabled = !idle;
    this.idle.next(idle);
  }

  ngOnDestroy(): void {
    if (this.registrationSubscription) { this.registrationSubscription.unsubscribe(); }
    this.snackbar.dismiss();
  }


}
