import { Injectable } from '@angular/core';
import { Subject ,  Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { DefaultService, Place, PlacePage } from '../api';
import { AbstractPageService } from '../utils/pagination';
import { HttpResponse } from '@angular/common/http';

/**
 * A service that handles privileges.
 */
@Injectable()
export class PlaceService extends AbstractPageService<Place, PlacePage> {

    public readonly order = new BehaviorSubject<"TIME" | "IDENTIFIER" | "IMPORTANCE" | "DURATION" | "FREQUENCY">('TIME');
    private loaded = this.order.getValue();

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
        this.order.subscribe(order => {
            if (this.loaded !== order) {
                if (this.loading.getValue()) {
                    const subscription = this.loading.subscribe(loading => {
                        if (!loading) {
                            if (this.loaded !== this.order.getValue()) {
                                this.reload();
                            }
                            subscription.unsubscribe();
                        }
                    });
                } else {
                    this.reload();
                }
            }
        });
    }

    protected loadPage(offset: number, size: number): Observable<PlacePage> {
        this.loaded = this.order.getValue();
        return this.apiClientService.getCurrentUserPlaces(this.order.getValue(), offset, size);
    }

}
