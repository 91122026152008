import { Injectable } from '@angular/core';
import { ConsoleService } from './console.service';
import { BehaviorSubject ,  Subject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DefaultService, User, UserPassword } from '../api';
import { CredentialService } from './credential.service';
import { HttpStatusCode } from '../utils/httpcodes';

/**
 * The possible states of the session.
 */
export enum UserState {
  /**
   * The user has not tried to login or
   * got logged out, e.g. by a password
   * change in some other part of the app.
   */
  Unavailable,
  /**
   * The state is currently changing,
   * e.g. due to an ongoing login or
   * some change that affects the session.
   */
  Changing,
  /**
   * The user has logged in and is not
   * changing.
   */
  Available,

}

/**
 * The possible roles assigned by the service.
 */
export enum UserRole {
  /** The role of a regular user. */
  User = 'user',
  /** The role of an admin user. */
  Admin = 'admin',
  /** The role of a scientist user. */
  Scientist = 'scientist'
}

/**
 * A service to manage the user's conceptual session state. The
 * service can be in one of the SessionStates. While the user is
 * Authorized, the service will maintain a reference to the
 * user object from the server. If the user is unauthorized,
 * a possible authorization failure is available.
 */
@Injectable()
export class UserService {

  /**
   * The current user, available whilte the state is Authorized.
   */
  public readonly user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  /**
   * The current authorization state.
   */
  public readonly state: BehaviorSubject<UserState> = new BehaviorSubject<UserState>(UserState.Unavailable);
  /**
   * The last error response, if state is Unauthorized.
   */
  public readonly error: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  /**
   * An url to redirect to after login.
   */
  public redirectUrl: string;

  /**
   * Creates a new service.
   *
   * @param consoleService The console for logging.
   * @param apiClientService The api client for remote requests.
   * @param credentailService The credential service to manage the username and password.
   */
  constructor(private consoleService: ConsoleService,
    private apiClientService: DefaultService,
    private credentailService: CredentialService) {
      if (credentailService.hasCredential()) {
      this.login();
    }
  }

  /** Returns an error handler. */
  createErrorHandler(): (e: any) => void {
    return (e: any): void => {
      if (e instanceof HttpErrorResponse) {
        if (e.status === HttpStatusCode.FORBIDDEN) {
          this.credentailService.setCredential(this.credentailService.getLogin(), null);
        }
        this.error.next(e);
        this.state.next(UserState.Unavailable);
      } else {
        this.consoleService.log('Unknown error in user service.');
        console.dir(e);
      }
      this.state.next(UserState.Unavailable);
    };
  }

  /** Returns the current login, if any.
   *
   * @returns The current login.
   */
  getLogin(): string {
    return this.credentailService.getLogin();
  }

   /**
   * Asks the service to update the credentials. Similar to
   * request login and request logout, this method will only
   * do something while there is no ongoing service interaction.
   *
   * @param login The login name.
   * @param password The password.
   * @returns True if the change has been applied.
   */
  setUser(login: string, password: string): boolean {
    if (this.state.getValue() === UserState.Changing) { return false; }
    this.consoleService.log('Setting user credentials.');
    this.credentailService.setCredential(login, password);
    this.state.next(UserState.Unavailable);
    this.user.next(null);
    return true;
  }

  /**
   * Asks the service to perform a login. If there is no
   * on-going server interacton, the service will try to
   * retrieve the current user's account which validates
   * the credential as a side-effect. If there is an
   * ongoing interaction, the call to this method will
   * be ignored.
   *
   * @returns True if the login request has been issued.
   */
  login(): boolean {
    if (this.state.getValue() === UserState.Changing) { return false; }
    this.consoleService.log('Issuing login request.');
    this.state.next(UserState.Changing);
    this.user.next(null);
    this.apiClientService.getCurrentUser().subscribe(result => {
      this.user.next(result);
      this.state.next(UserState.Available);
    }, this.createErrorHandler());
    return true;
  }

  /**
   * Requests a logout, that will be executed unless the
   * service is currently interacting with the server.
   *
   * @returns True if the change has been applied.
   */
  logout(): boolean {
    return this.setUser(null, null);
  }

  /**
   * Requests a password change.
   *
   * @param oldPassword The old password.
   * @param newPassword The new password.
   * @returns True if the change has been requested.
   */
  changePassword(oldPassword: string, newPassword: string): boolean {
    if (!this.credentailService.hasPassword(oldPassword)) { return false; }
    if (this.state.getValue() === UserState.Changing) { return false; }
    this.state.next(UserState.Changing);
    this.user.next(null);
    this.apiClientService.setCurrentPassword({ password: newPassword }).subscribe(result => {
      this.credentailService.setCredential(this.credentailService.getLogin(), newPassword);
      this.user.next(result);
      this.state.next(UserState.Available);
    }, this.createErrorHandler());
    return true;
  }

  /**
   * Requests the deletion of the user account.
   *
   * @param password The password to confirm the action.
   * @returns An observable to observe the change.
   */
  deleteUser(password: string): Observable<boolean> {
    if (!this.credentailService.hasPassword(password)) { return new BehaviorSubject<boolean>(false); }
    if (this.state.getValue() === UserState.Changing) {  return new BehaviorSubject<boolean>(false); }
    this.state.next(UserState.Changing);
    const user = this.user.getValue();
    user.deleted = true;
    const res = new Subject<boolean>();
    this.apiClientService.setCurrentUser(user).subscribe(result => {
      this.credentailService.clearCredential();
      this.user.next(null);
      this.state.next(UserState.Unavailable);
      res.next(true);
    }, error => {
      this.createErrorHandler()(error);
    });
    return res;
  }

  /**
   * Requests a change to the user.
   *
   * @param user The user to change.
   */
  changeUser(user: User): boolean {
    if (this.state.getValue() === UserState.Changing) { return false; }
    this.state.next(UserState.Changing);
    this.user.next(null);
    this.apiClientService.setCurrentUser(user).subscribe(result => {
      this.user.next(result);
      this.state.next(UserState.Available);
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === HttpStatusCode.CONFLICT) {
          this.user.next(error.error);
          this.error.next(error);
          this.state.next(UserState.Available);
        } else {
          this.createErrorHandler()(error);
        }
      } else {
        this.createErrorHandler()(error);
      }
    });
    return true;
  }

  /**
   * If the serivce's user is available, this method will
   * return whether the specified role is assigned to the
   * user. If the user is not available or changing, the
   * result will always be false.
   *
   * @param role The role to test.
   * @returns True if the user is available and the role
   *  is assigned.
   */
  hasRole(role: UserRole): boolean {
    if (this.state.getValue() !== UserState.Available) { return false; }
    for (const r of this.user.getValue().roles) {
      if (role === r) { return true; }
    }
    return false;
  }
}
