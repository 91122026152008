import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ResourceService } from '../services/resource.service';

export interface ConfirmationDialogData {
    title: string;
    message: string;
    positive?: string;
    negative?: string;
}

export interface ConfirmationDialogResult {
  confirmed: boolean;
}


@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'confirmation-dialog.component.html',
  })
  export class ConfirmationDialogComponent {

    title: String;
    message: String;
    positive: String;
    negative: String;

    public static open(dialog: MatDialog, d: ConfirmationDialogData, w?: string): Observable<ConfirmationDialogResult> {
      if (w == null) { w = '450px'; }
      const ref = dialog.open(ConfirmationDialogComponent, {
          width: w,
          data: d
      });
      return ref.afterClosed();
    }

    constructor(public dialog: MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData, public res: ResourceService) {
        this.title = data.title;
        this.message = data.message;
        this.positive = data.positive;
        this.negative = data.negative;
        if (!this.positive) { this.positive = res.strings('button_ok'); }
        if (!this.negative) { this.negative = res.strings('button_cancel'); }
      }

    onClose(): void {
      this.dialog.close({ confirmed: false});
    }

    onAccept(): void {
      this.dialog.close({confirmed: true});
    }

  }
