/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BuildVersion } from '../model/buildVersion';
import { Device } from '../model/device';
import { DeviceHandle } from '../model/deviceHandle';
import { DeviceInfo } from '../model/deviceInfo';
import { DevicePage } from '../model/devicePage';
import { EntityStatistic } from '../model/entityStatistic';
import { LoadStatistic } from '../model/loadStatistic';
import { Location } from '../model/location';
import { LocationUpdate } from '../model/locationUpdate';
import { LogMessage } from '../model/logMessage';
import { Notice } from '../model/notice';
import { NoticePage } from '../model/noticePage';
import { Place } from '../model/place';
import { PlacePage } from '../model/placePage';
import { Position } from '../model/position';
import { PositionPage } from '../model/positionPage';
import { PositionUpdate } from '../model/positionUpdate';
import { Privilege } from '../model/privilege';
import { PrivilegePage } from '../model/privilegePage';
import { Share } from '../model/share';
import { ShareInfo } from '../model/shareInfo';
import { ShareRequest } from '../model/shareRequest';
import { SystemStatistic } from '../model/systemStatistic';
import { User } from '../model/user';
import { UserPage } from '../model/userPage';
import { UserPassword } from '../model/userPassword';
import { UserRegistration } from '../model/userRegistration';
import { Visit } from '../model/visit';
import { VisitPage } from '../model/visitPage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'http://www.maptology.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a privilege for the current user.
     * 
     * @param share The token of the share.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCurrentUserPrivilege(share: string, observe?: 'body', reportProgress?: boolean): Observable<Privilege>;
    public addCurrentUserPrivilege(share: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Privilege>>;
    public addCurrentUserPrivilege(share: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Privilege>>;
    public addCurrentUserPrivilege(share: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (share === null || share === undefined) {
            throw new Error('Required parameter share was null or undefined when calling addCurrentUserPrivilege.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (share !== undefined && share !== null) {
            queryParameters = queryParameters.set('share', <any>share);
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.post<Privilege>(`${this.basePath}/users/current/privileges`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds all positions for the device.
     * 
     * @param id The device id.
     * @param body The batch position update to add.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDevicePositions(id: number, body?: PositionUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addDevicePositions(id: number, body?: PositionUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addDevicePositions(id: number, body?: PositionUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addDevicePositions(id: number, body?: PositionUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addDevicePositions.');
        }


        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // authentication (scientist) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/positions`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the locations of the current device.
     * 
     * @param body The location update with the locations to add.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLocations(body: LocationUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addLocations(body: LocationUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addLocations(body: LocationUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addLocations(body: LocationUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addLocations.');
        }

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devices/current/locations`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new notice and returns the notice as well as its location.
     * 
     * @param body The new notice to add.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNotice(body: Notice, observe?: 'body', reportProgress?: boolean): Observable<Notice>;
    public addNotice(body: Notice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Notice>>;
    public addNotice(body: Notice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Notice>>;
    public addNotice(body: Notice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addNotice.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Notice>(`${this.basePath}/notices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a user account with the specified roles and emails.
     * 
     * @param body The data for the new user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUser(body: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public addUser(body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public addUser(body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public addUser(body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/users`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the privileges for a particular user.
     * 
     * @param id The user id.
     * @param share The token of the share.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserPrivilege(id: number, share: string, observe?: 'body', reportProgress?: boolean): Observable<Privilege>;
    public addUserPrivilege(id: number, share: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Privilege>>;
    public addUserPrivilege(id: number, share: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Privilege>>;
    public addUserPrivilege(id: number, share: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addUserPrivilege.');
        }

        if (share === null || share === undefined) {
            throw new Error('Required parameter share was null or undefined when calling addUserPrivilege.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (share !== undefined && share !== null) {
            queryParameters = queryParameters.set('share', <any>share);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.post<Privilege>(`${this.basePath}/users/${encodeURIComponent(String(id))}/privileges`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new device and returns its handle.
     * The response will include the auth token to be stored on the device and supplied as authentication on all subsequent api calls. Note that the auth token can not be requested after creation, so when losing the auth token you may lose access to the device data.
     * @param body Necessary data from to create a device.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDevice(body: DeviceInfo, observe?: 'body', reportProgress?: boolean): Observable<DeviceHandle>;
    public createDevice(body: DeviceInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceHandle>>;
    public createDevice(body: DeviceInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceHandle>>;
    public createDevice(body: DeviceInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createDevice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DeviceHandle>(`${this.basePath}/devices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new share.
     * 
     * @param body Necessary data from to create a share.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShare(body: ShareRequest, observe?: 'body', reportProgress?: boolean): Observable<Share>;
    public createShare(body: ShareRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Share>>;
    public createShare(body: ShareRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Share>>;
    public createShare(body: ShareRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createShare.');
        }

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Share>(`${this.basePath}/shares`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the current device.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCurrentDevice(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCurrentDevice(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCurrentDevice(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCurrentDevice(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/devices/current`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a device and it&#39;s visits.
     * 
     * @param id The device id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDevice(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDevice(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDevice(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDevice(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDevice.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a place.
     * 
     * @param id The place id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePlace(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePlace(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePlace(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePlace(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletePlace.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/places/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a particular privilege.
     * 
     * @param id The privilege id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePrivilege(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePrivilege(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePrivilege(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePrivilege(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletePrivilege.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/privileges/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a share.
     * 
     * @param id The share id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShare(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShare(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShare(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShare(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteShare.');
        }

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/shares/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a visit.
     * 
     * @param id The visit id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteVisit(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteVisit(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteVisit(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteVisit(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteVisit.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/visits/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a page of notices.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveNotices(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<NoticePage>;
    public getActiveNotices(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoticePage>>;
    public getActiveNotices(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoticePage>>;
    public getActiveNotices(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<NoticePage>(`${this.basePath}/notices/active`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the build version.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBuildVersion(observe?: 'body', reportProgress?: boolean): Observable<BuildVersion>;
    public getBuildVersion(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BuildVersion>>;
    public getBuildVersion(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BuildVersion>>;
    public getBuildVersion(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BuildVersion>(`${this.basePath}/versions/build`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current device.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentDevice(observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public getCurrentDevice(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public getCurrentDevice(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public getCurrentDevice(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Device>(`${this.basePath}/devices/current`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the user that is associated with a device.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentDeviceUser(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getCurrentDeviceUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getCurrentDeviceUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getCurrentDeviceUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<User>(`${this.basePath}/devices/current/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the user currently authorized.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<User>(`${this.basePath}/users/current`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the list of devices for the current user.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentUserDevices(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<DevicePage>;
    public getCurrentUserDevices(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevicePage>>;
    public getCurrentUserDevices(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevicePage>>;
    public getCurrentUserDevices(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<DevicePage>(`${this.basePath}/users/current/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the places for the current user.
     * 
     * @param order The sorting criterion.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentUserPlaces(order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<PlacePage>;
    public getCurrentUserPlaces(order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlacePage>>;
    public getCurrentUserPlaces(order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlacePage>>;
    public getCurrentUserPlaces(order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling getCurrentUserPlaces.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PlacePage>(`${this.basePath}/users/current/places`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the privileges for the current user.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentUserPrivileges(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<PrivilegePage>;
    public getCurrentUserPrivileges(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrivilegePage>>;
    public getCurrentUserPrivileges(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrivilegePage>>;
    public getCurrentUserPrivileges(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PrivilegePage>(`${this.basePath}/users/current/privileges`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the visits for the current user.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentUserVisits(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<VisitPage>;
    public getCurrentUserVisits(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitPage>>;
    public getCurrentUserVisits(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitPage>>;
    public getCurrentUserVisits(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<VisitPage>(`${this.basePath}/users/current/visits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a device.
     * 
     * @param id The device id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevice(id: number, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public getDevice(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public getDevice(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public getDevice(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDevice.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Device>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the last device location.
     * 
     * @param id The device id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceLocation(id: number, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public getDeviceLocation(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public getDeviceLocation(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    public getDeviceLocation(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDeviceLocation.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Location>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all positions for a particular device.
     * 
     * @param id The device id.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param metadata Controls whether metadata is included, if available.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevicePositions(id: number, pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PositionPage>;
    public getDevicePositions(id: number, pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionPage>>;
    public getDevicePositions(id: number, pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionPage>>;
    public getDevicePositions(id: number, pageOffset?: number, pageSize?: number, metadata?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDevicePositions.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (metadata !== undefined && metadata !== null) {
            queryParameters = queryParameters.set('metadata', <any>metadata);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (scientist) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PositionPage>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/positions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the position for the device in a certain time range.
     * 
     * @param id The device id.
     * @param start The start time in Epoch milliseconds.
     * @param duration The duration in milliseconds.
     * @param metadata Controls whether metadata is included, if available.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevicePositionsRange(id: number, start: number, duration: number, metadata?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Position>>;
    public getDevicePositionsRange(id: number, start: number, duration: number, metadata?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Position>>>;
    public getDevicePositionsRange(id: number, start: number, duration: number, metadata?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Position>>>;
    public getDevicePositionsRange(id: number, start: number, duration: number, metadata?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDevicePositionsRange.');
        }

        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getDevicePositionsRange.');
        }

        if (duration === null || duration === undefined) {
            throw new Error('Required parameter duration was null or undefined when calling getDevicePositionsRange.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }
        if (metadata !== undefined && metadata !== null) {
            queryParameters = queryParameters.set('metadata', <any>metadata);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (scientist) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Array<Position>>(`${this.basePath}/device/${encodeURIComponent(String(id))}/positions/range`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  Returns the visits of a device for a particular time span.
     * 
     * @param id The device id.
     * @param start The start time in Epoch milliseconds.
     * @param duration The duration in milliseconds.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceVisitsRange(id: number, start: number, duration: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Visit>>;
    public getDeviceVisitsRange(id: number, start: number, duration: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Visit>>>;
    public getDeviceVisitsRange(id: number, start: number, duration: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Visit>>>;
    public getDeviceVisitsRange(id: number, start: number, duration: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDeviceVisitsRange.');
        }

        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getDeviceVisitsRange.');
        }

        if (duration === null || duration === undefined) {
            throw new Error('Required parameter duration was null or undefined when calling getDeviceVisitsRange.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Array<Visit>>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/visits/range`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all devices.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevices(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<DevicePage>;
    public getDevices(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevicePage>>;
    public getDevices(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevicePage>>;
    public getDevices(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<DevicePage>(`${this.basePath}/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of entity statistics.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntityStatistics(observe?: 'body', reportProgress?: boolean): Observable<Array<EntityStatistic>>;
    public getEntityStatistics(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntityStatistic>>>;
    public getEntityStatistics(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntityStatistic>>>;
    public getEntityStatistics(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EntityStatistic>>(`${this.basePath}/log/entities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current load statistic.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoadStatistic(observe?: 'body', reportProgress?: boolean): Observable<LoadStatistic>;
    public getLoadStatistic(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoadStatistic>>;
    public getLoadStatistic(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoadStatistic>>;
    public getLoadStatistic(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LoadStatistic>(`${this.basePath}/log/load`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of log messages starting from a particular id.
     * 
     * @param from The id of the first message to retrieve.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogMessages(from?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LogMessage>>;
    public getLogMessages(from?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LogMessage>>>;
    public getLogMessages(from?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LogMessage>>>;
    public getLogMessages(from?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LogMessage>>(`${this.basePath}/log/messages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the notice with the specified id.
     * 
     * @param id The id of the notice.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotice(id: number, observe?: 'body', reportProgress?: boolean): Observable<Notice>;
    public getNotice(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Notice>>;
    public getNotice(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Notice>>;
    public getNotice(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getNotice.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Notice>(`${this.basePath}/notices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a page of notices.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotices(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<NoticePage>;
    public getNotices(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoticePage>>;
    public getNotices(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoticePage>>;
    public getNotices(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<NoticePage>(`${this.basePath}/notices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the place with the specified id.
     * 
     * @param id The place id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlace(id: number, observe?: 'body', reportProgress?: boolean): Observable<Place>;
    public getPlace(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Place>>;
    public getPlace(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Place>>;
    public getPlace(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPlace.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Place>(`${this.basePath}/places/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the visits for a particular place.
     * 
     * @param id The place id.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlaceVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<VisitPage>;
    public getPlaceVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitPage>>;
    public getPlaceVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitPage>>;
    public getPlaceVisits(id: number, pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPlaceVisits.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<VisitPage>(`${this.basePath}/places/${encodeURIComponent(String(id))}/visits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all positions.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param metadata Controls whether metadata is included, if available.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPositions(pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PositionPage>;
    public getPositions(pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionPage>>;
    public getPositions(pageOffset?: number, pageSize?: number, metadata?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionPage>>;
    public getPositions(pageOffset?: number, pageSize?: number, metadata?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (metadata !== undefined && metadata !== null) {
            queryParameters = queryParameters.set('metadata', <any>metadata);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PositionPage>(`${this.basePath}/positions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the privileges for a particular user.
     * 
     * @param id The privilege id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrivilege(id: number, observe?: 'body', reportProgress?: boolean): Observable<Privilege>;
    public getPrivilege(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Privilege>>;
    public getPrivilege(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Privilege>>;
    public getPrivilege(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPrivilege.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Privilege>(`${this.basePath}/privileges/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the location for a particular privilege.
     * 
     * @param id The privilege id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrivilegeLocation(id: number, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public getPrivilegeLocation(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public getPrivilegeLocation(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    public getPrivilegeLocation(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPrivilegeLocation.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Location>(`${this.basePath}/privileges/${encodeURIComponent(String(id))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a share.
     * 
     * @param id The share id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShare(id: number, observe?: 'body', reportProgress?: boolean): Observable<Share>;
    public getShare(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Share>>;
    public getShare(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Share>>;
    public getShare(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getShare.');
        }

        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Share>(`${this.basePath}/shares/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a share info by its secret token.
     * 
     * @param token The secret token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShareInfo(token: string, observe?: 'body', reportProgress?: boolean): Observable<ShareInfo>;
    public getShareInfo(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShareInfo>>;
    public getShareInfo(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShareInfo>>;
    public getShareInfo(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getShareInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<ShareInfo>(`${this.basePath}/shares/${encodeURIComponent(String(token))}/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a shared location by its secret token.
     * 
     * @param token The secret token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShareLocation(token: string, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public getShareLocation(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public getShareLocation(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    public getShareLocation(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getShareLocation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Location>(`${this.basePath}/shares/${encodeURIComponent(String(token))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the system statistic.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemStatistic(observe?: 'body', reportProgress?: boolean): Observable<SystemStatistic>;
    public getSystemStatistic(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SystemStatistic>>;
    public getSystemStatistic(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SystemStatistic>>;
    public getSystemStatistic(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SystemStatistic>(`${this.basePath}/log/system`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all active notices modified after the date.
     * 
     * @param since The earlies time in epoch milliseconds.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUpdatedNotices(since?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Notice>>;
    public getUpdatedNotices(since?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Notice>>>;
    public getUpdatedNotices(since?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Notice>>>;
    public getUpdatedNotices(since?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (since !== undefined && since !== null) {
            queryParameters = queryParameters.set('since', <any>since);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<Array<Notice>>(`${this.basePath}/notices/updated`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the user account with the specified id.
     * 
     * @param id The id of the user to retrieve.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(id: number, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<User>(`${this.basePath}/users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the places for a particular user.
     * 
     * @param id The user id.
     * @param order The sorting criterion.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPlaces(id: number, order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<PlacePage>;
    public getUserPlaces(id: number, order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlacePage>>;
    public getUserPlaces(id: number, order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlacePage>>;
    public getUserPlaces(id: number, order: 'IDENTIFIER' | 'IMPORTANCE' | 'DURATION' | 'FREQUENCY' | 'TIME', pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserPlaces.');
        }

        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling getUserPlaces.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PlacePage>(`${this.basePath}/users/${encodeURIComponent(String(id))}/places`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the privileges for a particular user.
     * 
     * @param id The user id.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPrivileges(id: number, pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<PrivilegePage>;
    public getUserPrivileges(id: number, pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrivilegePage>>;
    public getUserPrivileges(id: number, pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrivilegePage>>;
    public getUserPrivileges(id: number, pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserPrivileges.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<PrivilegePage>(`${this.basePath}/users/${encodeURIComponent(String(id))}/privileges`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the visits for a particular user.
     * 
     * @param id The user id.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<VisitPage>;
    public getUserVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitPage>>;
    public getUserVisits(id: number, pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitPage>>;
    public getUserVisits(id: number, pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserVisits.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<VisitPage>(`${this.basePath}/users/${encodeURIComponent(String(id))}/visits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of users.
     * 
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<UserPage>;
    public getUsers(pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPage>>;
    public getUsers(pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPage>>;
    public getUsers(pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<UserPage>(`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registers a new user and sends an email account verification.
     * 
     * @param body The data of the new user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(body: UserRegistration, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public registerUser(body: UserRegistration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public registerUser(body: UserRegistration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public registerUser(body: UserRegistration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/users/register`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends a password reset email to the correspondence email account of a user.
     * 
     * @param login The login of the user to reset.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUser(login: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetUser(login: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetUser(login: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetUser(login: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling resetUser.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.post<any>(`${this.basePath}/users/reset`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the password of a user account using a token received via email.
     * 
     * @param token The secret token received via email.
     * @param body The new user password.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUserPassword(token: string, body: UserPassword, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public resetUserPassword(token: string, body: UserPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public resetUserPassword(token: string, body: UserPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public resetUserPassword(token: string, body: UserPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling resetUserPassword.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetUserPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/users/password/${encodeURIComponent(String(token))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of users whose login name or name contains the term.
     * 
     * @param term The search term.
     * @param pageOffset The offset in terms of elements.
     * @param pageSize The maximum number of elements on the page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsers(term: string, pageOffset?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<UserPage>;
    public searchUsers(term: string, pageOffset?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPage>>;
    public searchUsers(term: string, pageOffset?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPage>>;
    public searchUsers(term: string, pageOffset?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (term === null || term === undefined) {
            throw new Error('Required parameter term was null or undefined when calling searchUsers.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageOffset !== undefined && pageOffset !== null) {
            queryParameters = queryParameters.set('pageOffset', <any>pageOffset);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (term !== undefined && term !== null) {
            queryParameters = queryParameters.set('term', <any>term);
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.get<UserPage>(`${this.basePath}/users/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the password of the currently authorized user.
     * 
     * @param body The new password to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCurrentPassword(body: UserPassword, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public setCurrentPassword(body: UserPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public setCurrentPassword(body: UserPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public setCurrentPassword(body: UserPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setCurrentPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/users/current/password`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the currently authorized user.
     * 
     * @param body The new user values to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCurrentUser(body: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public setCurrentUser(body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public setCurrentUser(body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public setCurrentUser(body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setCurrentUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/users/current`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connects a device created without user account with a user account.
     * Executing this is operation requires a valid device and a valid user authentication to be transmitted simultaneously. 
     * @param body The device values.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCurrentUserDevice(body?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public setCurrentUserDevice(body?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public setCurrentUserDevice(body?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public setCurrentUserDevice(body?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Device>(`${this.basePath}/users/current/devices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the values of a device.
     * 
     * @param id The device id.
     * @param body The device values.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDevice(id: number, body?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public setDevice(id: number, body?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public setDevice(id: number, body?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public setDevice(id: number, body?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setDevice.');
        }


        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Device>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing notice.
     * 
     * @param id The id of the notice.
     * @param body The data of the notice to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setNotice(id: number, body: Notice, observe?: 'body', reportProgress?: boolean): Observable<Notice>;
    public setNotice(id: number, body: Notice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Notice>>;
    public setNotice(id: number, body: Notice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Notice>>;
    public setNotice(id: number, body: Notice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setNotice.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setNotice.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Notice>(`${this.basePath}/notices/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the place with the specified id.
     * 
     * @param id The place id.
     * @param body The place to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPlace(id: number, body: Place, observe?: 'body', reportProgress?: boolean): Observable<Place>;
    public setPlace(id: number, body: Place, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Place>>;
    public setPlace(id: number, body: Place, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Place>>;
    public setPlace(id: number, body: Place, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setPlace.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setPlace.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (user) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Place>(`${this.basePath}/places/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing share.
     * 
     * @param id The share id.
     * @param body The new share to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setShare(id: number, body?: Share, observe?: 'body', reportProgress?: boolean): Observable<Share>;
    public setShare(id: number, body?: Share, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Share>>;
    public setShare(id: number, body?: Share, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Share>>;
    public setShare(id: number, body?: Share, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setShare.');
        }


        let headers = this.defaultHeaders;

        // authentication (device) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Share>(`${this.basePath}/shares/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the values of a particular user account.
     * 
     * @param id The id of the user to set.
     * @param body The new user data to set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUser(id: number, body: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public setUser(id: number, body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public setUser(id: number, body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public setUser(id: number, body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setUser.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/users/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the password of a user.
     * 
     * @param id The id of the user to change.
     * @param body The new password.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserPassword(id: number, body: UserPassword, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public setUserPassword(id: number, body: UserPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public setUserPassword(id: number, body: UserPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public setUserPassword(id: number, body: UserPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setUserPassword.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setUserPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (admin) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/users/${encodeURIComponent(String(id))}/password`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifies an email address using a token received via email.
     * 
     * @param token The secret token received via email.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyUserEmail(token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public verifyUserEmail(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public verifyUserEmail(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public verifyUserEmail(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling verifyUserEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/xml'
        ];

        return this.httpClient.put<any>(`${this.basePath}/users/verify/${encodeURIComponent(String(token))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
