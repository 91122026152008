<mat-form-field [hintLabel]="hint" fxFlex="1 1 100%" appUseParentForm>
    <input matInput #passwordField="ngModel" [name]="name" [placeholder]="label" [(ngModel)]="password" minlength="5" required [disabled]="disabled" [type]="hide ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hide = !hide" [matTooltip]="res.strings(hide?'password_field_show':'password_field_show')">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
    <mat-hint align="end">{{passwordField.value?.length || 0}}</mat-hint>
    <mat-error *ngIf="passwordField.invalid">
        <ng-container [ngSwitch]="encode(passwordField.errors)"> 
            <ng-container *ngSwitchCase="FormErrorCode.REQUIRED">{{ res.strings('password_field_required') }}</ng-container>
            <ng-container *ngSwitchCase="FormErrorCode.MINLENGTH">{{ res.strings('password_field_minlength') }}</ng-container>
        </ng-container>
    </mat-error>
</mat-form-field>