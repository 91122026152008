import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultService, Device, DevicePage } from '../api';
import { AbstractPageService } from '../utils/pagination';

/**
 * A service that handles devices.
 */
@Injectable()
export class DeviceService extends AbstractPageService<Device, DevicePage> {

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
    }

    protected loadPage(offset: number, size: number): Observable<DevicePage> {
        return this.apiClientService.getCurrentUserDevices(offset, size);
    }

}
