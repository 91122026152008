<ng-container *ngIf="visible">
    <div *ngIf="color != null; then raised else flat"></div>
    <ng-template #raised>
        <button mat-raised-button [color]="color" [routerLink]="link" [attr.disabled]="disabled">
            <div fxHide.lt-sm="true">
                <mat-icon class="icon-button">{{icon}}</mat-icon>
                <span>{{title}}</span>
            </div>
            <div fxHide.gt-xs="true" [matTooltip]="title">
                <mat-icon class="icon-button">{{icon}}</mat-icon>
            </div>
        </button>
    </ng-template>
    <ng-template #flat>
        <button mat-button [routerLink]="link" [attr.disabled]="disabled">
            <div fxHide.lt-sm="true">
                <mat-icon class="icon-button">{{icon}}</mat-icon>
                <span>{{title}}</span>
            </div>
            <div fxHide.gt-xs="true" [matTooltip]="title">
                <mat-icon class="icon-button">{{icon}}</mat-icon>
            </div>
        </button>
    </ng-template>
</ng-container>