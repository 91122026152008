import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ResourceService } from '../services/resource.service';

export interface MessageDialogData {
    title: string;
    message: string;
    button?: string;
}

@Component({
    selector: 'app-message-dialog',
    templateUrl: 'message-dialog.component.html',
  })
  export class MessageDialogComponent {

    title: String;
    message: String;
    button: String;

    public static open(dialog: MatDialog, d: MessageDialogData, w?: string): Observable<any> {
      if (w == null) { w = '450px'; }
      const ref = dialog.open(MessageDialogComponent, {
          width: w,
          data: d
      });
      return ref.afterClosed();
    }

    constructor(public dialog: MatDialogRef<MessageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: MessageDialogData, public res: ResourceService) {
        this.title = data.title;
        this.message = data.message;
        this.button = data.button;
        if (!this.button) { this.button = res.strings('button_ok'); }
      }

    onClose(): void {
      this.dialog.close();
    }

  }
