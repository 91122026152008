import { Injectable } from '@angular/core';
import { DefaultService, Device, Place, Visit, VisitPage } from '../api';
import { AbstractReferencePageService } from '../utils/pagination';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * A service that handles visits for a particular place.
 */
@Injectable()
export class PlaceVisitService extends AbstractReferencePageService<Visit, VisitPage, Device> {

    readonly place = new BehaviorSubject<Place>(null);
    private id: number;

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
    }

    setPlace(id: number) {
        if (this.id === id || this.loading.getValue()) { return; }
        this.id = null;
        this.place.next(null);
        this.reload();
        if (id != null) {
            this.id = id;
            this.loading.next(true);
            this.apiClientService.getPlace(id).subscribe((result) => {
                this.place.next(result);
                this.loading.next(false);
                this.reload();
            }, (error) => {
                this.id = null;
                this.loading.next(false);
                this.reload();
            });
        }
    }

    getPage(offset: number, size: number) {
        if (this.loading.getValue()) { return; }
        if (this.id == null) { this.page.next(this.emptyPage()); } else { super.getPage(offset, size); }
    }

    protected loadPage(offset: number, size: number): Observable<VisitPage> {
        return this.apiClientService.getPlaceVisits(this.id, offset, size);
    }

    protected getReferences(page: VisitPage): number[] {
        const result: number[] = new Array();
        for (const visit of page.elements) {
            if (result.indexOf(visit.device) === -1) {
                result.push(visit.device);
            }
        }
        return result;
    }

    protected loadReference(id: number): Observable<Device> {
        return this.apiClientService.getDevice(id);
    }

}
