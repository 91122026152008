<mat-card-actions #panel fxFlexLayout="row" fxLayoutAlign="space-around center" style="margin:0px">
    <ng-container *ngIf="!processing;else progressBar">
        <ng-container *ngIf="!compact;else dense">
            <button mat-raised-button color="accent" (click)="onPositive()" [disabled]="disabled">
                <mat-icon class="icon-button">{{ positiveIcon }} </mat-icon>
                <span>{{ positiveName }}</span>
            </button>
            <button mat-raised-button color="primary" (click)="onNegative()">
                <mat-icon class="icon-button">{{ negativeIcon }}</mat-icon>
                <span>{{ negativeName }}</span>
            </button>
        </ng-container>
        <ng-template #dense>
            <button mat-raised-button [matTooltip]="positiveName" color="accent" (click)="onPositive()" [disabled]="disabled">
                <mat-icon class="icon-button">{{ positiveIcon }} </mat-icon>
            </button>
            <button mat-raised-button [matTooltip]="negativeName" color="primary" (click)="onNegative()">
                <mat-icon class="icon-button">{{ negativeIcon }}</mat-icon>
            </button>
        </ng-template>
    </ng-container>
    <ng-template #progressBar>
        <mat-spinner [diameter]="36"></mat-spinner>
    </ng-template>
</mat-card-actions>