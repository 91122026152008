import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { UserService, UserRole } from '../services/user.service';
import { BehaviorSubject, Subscription ,  Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PasswordFieldComponent } from '../widgets/password-field.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Snackbars } from '../utils/snackbars';
import { User, Email } from '../api';
import { NameFieldComponent } from '../widgets/name-field.component';
import { EmailFieldComponent } from '../widgets/email-field.component';
import { MessageDialogComponent } from '../widgets/message-dialog.component';
import { ResourceService } from '../services/resource.service';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit, OnDestroy {

  @ViewChild(NameFieldComponent) nameField: NameFieldComponent;
  @ViewChild(MatSelect) emailSelect: MatSelect;

  // add email
  @ViewChild(EmailFieldComponent) emailField: EmailFieldComponent;

  // change passwords
  @ViewChild('oldPassword') oldPasswordField: PasswordFieldComponent;
  @ViewChild('newPassword') newPasswordField: PasswordFieldComponent;

  // delete account
  @ViewChild('deletePassword') deletePasswordField: PasswordFieldComponent;

  readonly idle = new BehaviorSubject<boolean>(true);

  columns = 1;
  correspondence: Email;
  emails: Email[] = [];

  user: BehaviorSubject<User>;
  private userSubscription: Subscription;

  constructor(public res: ResourceService, private userService: UserService,
      private snackbar: MatSnackBar, private dialog: MatDialog, private router: Router) {
    this.user = userService.user;
  }

  ngOnInit(): void {
    this.updateColumns(window.innerWidth);
    this.userSubscription = this.userService.user.subscribe((user) => {
        this.idle.next(user != null);
        this.correspondence = this.getCorrespondence();
        this.emails = this.getEmails();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateColumns((event.target as Window).innerWidth);
  }

  updateColumns(width: number): void {
    if (width < 800) {
        this.columns = 1;
    } else if (width < 1400) {
        this.columns = 2;
    } else if (width < 2000) {
        this.columns = 3;
    } else {
        this.columns = 4;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private getEmails(): Email[] {
    if (this.user.getValue() == null) { return []; }
    return this.user.getValue().emails.sort((a, b) => {
      return a.address.localeCompare(b.address);
    });
  }

  private getCorrespondence(): Email {
    if (this.user.getValue() == null) { return null; }
    for (const email of this.user.getValue().emails) {
        if (email.correspondence) { return email; }
    }
    return null;
  }

  private doDeleteUser(): void {
    if (this.user.getValue() == null) { return; }
    this.userService.deleteUser(this.deletePasswordField.password).subscribe(result => {
      if (!result) {
        this.deletePasswordField.password = '';
        MessageDialogComponent.open(this.dialog,
            {title: this.res.strings('user_password_wrong_title'), message: this.res.strings('user_password_wrong_message')});
      } else {
        Snackbars.showMessage(this.snackbar, this.res.strings('user_delete_success'), Snackbars.DURATION_LONG);
        this.router.navigate(['/']);
      }
    });
  }

  onAddEmail(): void {
    if (this.user.getValue() == null) { return; }
    const u = this.user.getValue();
    const email = this.emailField.email;
    for (let i = 0, s = u.emails.length; i < s; i++) {
      if (u.emails[i].address === email) {
        Snackbars.showError(this.snackbar, this.res.strings('user_email_registered_already'), Snackbars.DURATION_SHORT);
        this.emailField.email = '';
        return;
      }
    }
    u.emails.push({address: email, id: 0, correspondence: false, creationTime: 0, modificationTime: 0, deleted: false, verified: false});
    this.userService.changeUser(u);
  }

  onChangePassword(): void {
    if (this.user.getValue() == null) { return; }
    if (!this.userService.changePassword(this.oldPasswordField.password, this.newPasswordField.password)) {
      this.oldPasswordField.password = '';
      this.newPasswordField.password = '';
      MessageDialogComponent.open(this.dialog,
        {title: this.res.strings('user_password_wrong_title'), message: this.res.strings('user_password_wrong_message')});
    }
  }

  onChangeUser(): void {
    if (this.user.getValue() == null) { return; }
    const u = this.user.getValue();
    u.name = this.nameField.name;
    const id = this.correspondence.id;
    for (let i = 0, s = u.emails.length; i < s; i++) {
      u.emails[i].correspondence = (u.emails[i].id === id);
    }
    this.userService.changeUser(u);
  }

  onDeleteEmail(id: number): void {
    if (this.user.getValue() == null) { return; }
    const u = this.user.getValue();
    for (let i = 0, s = u.emails.length; i < s; i++) {
      if (u.emails[i].id === id) {
        u.emails[i].deleted = true;
        this.userService.changeUser(u);
        return;
      }
    }
  }

  onDeleteUser(): void {
    if (this.user.getValue() == null) { return; }
    if (this.userService.hasRole(UserRole.Admin)) {
        this.deletePasswordField.password = '';
        MessageDialogComponent.open(this.dialog,
            {title: this.res.strings('user_delete_admin_title'), message: this.res.strings('user_delete_admin_message')});
    } else {
      ConfirmationDialogComponent.open(this.dialog, {
        title: this.res.strings('user_delete_confirm_title'),
        message: this.res.strings('user_delete_confirm_message'),
        positive: this.res.strings('button_yes'),
        negative: this.res.strings('button_no')
      }).subscribe((result) => {
        if (result != null && result.confirmed) {
          this.doDeleteUser();
        } else {
          this.deletePasswordField.password = '';
        }
      });
    }
  }

}
