import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DefaultService } from '../api';
import { MatPaginator } from '@angular/material/paginator';

export interface Page<E> {
    offset: number;
    size: number;
    total: number;
    elements: E[];
}

export class PaginationHandler<D, P extends Page<D>> {

    private pageSubscription: Subscription;
    private paginatorSubscription: Subscription;

    constructor(private paginator: MatPaginator, private service: AbstractPageService<D, P>) {
        this.pageSubscription = this.service.page.subscribe((page) => {
            this.paginator.length = page.total;
            this.paginator.pageSize = page.size;
            this.paginator.pageIndex = page.offset / page.size;
        });
        this.paginatorSubscription = this.paginator.page.subscribe((page) => {
            const offset = page.pageIndex * page.pageSize;
            const size = page.pageSize;
            this.service.getPage(offset, size);
        });
    }

    destroy() {
        this.pageSubscription.unsubscribe();
        this.paginatorSubscription.unsubscribe();
    }


}

export abstract class AbstractPageService<D, P extends Page<D>> extends DataSource<D> {

    private elements = new BehaviorSubject<D[]>([]);
    readonly page = new BehaviorSubject<P>({ offset: 0, size: 20, total: 0, elements: []} as P);
    private subscriptions = 0;
    readonly loading = new BehaviorSubject<boolean>(false);

    constructor(protected apiClientService: DefaultService) {
        super();
        this.page.subscribe((page) => {
            this.elements.next(page.elements);
        });
    }

    getPage(offset: number, size: number) {
        if (this.loading.getValue()) { return; }
        this.loading.next(true);
        this.loadPage(offset, size).subscribe((result) => {
            this.pageLoaded(result);
        }, (error) => {
            this.pageLoaded(null);
        });
    }

    protected abstract loadPage(offset: number, size: number): Observable<P>;

    protected pageLoaded(page: P): void {
        this.page.next(page);
        this.loading.next(false);
    }

    reload(): void {
        this.getPage(this.page.getValue().offset, this.page.getValue().size);
    }

    connect(collectionViewer: CollectionViewer): Observable<D[]> {
        this.subscriptions += 1;
        return this.elements;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subscriptions -= 1;
    }

    protected emptyPage(): P {
        return { offset: 0, size: this.page.getValue().size, total: 0, elements: []} as P;
    }

}

export abstract class AbstractReferencePageService<D, P extends Page<D>, R> extends AbstractPageService<D, P> {

    readonly references: Map<number, R> = new Map<number, R>();
    private referenceRequests = 0;

    constructor(protected apiClientService: DefaultService) {
        super(apiClientService);
    }

    protected abstract getReferences(page: P): number[];

    protected abstract loadReference(id: number): Observable<R>;

    protected pageLoaded(page: P): void {
        this.page.next(page);
        if (page != null) {
            for (const id of this.getReferences(page)) {
                if (this.getReference(id) == null) {
                    this.referenceRequests += 1;
                    this.loadReference(id).subscribe(result => {
                        this.referenceLoaded(id, result);
                    }, error => {
                        this.referenceLoaded(id, null);
                    });
                }
            }
        }
        if (this.referenceRequests === 0) {
            this.loading.next(false);
        }
    }

    protected referenceLoaded(id: number, reference: R): void {
        this.referenceRequests -= 1;
        this.references.set(id, reference);
        if (this.referenceRequests === 0) {
            this.loading.next(false);
        }
    }

    getReference(id: number): R {
        return this.references.get(id);
    }
}
