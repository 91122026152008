/**
 * The error codes for standard validations.
 */
export enum FormErrorCode {
    NONE = 0,
    REQUIRED = 1,
    PATTERN = 2,
    MINLENGTH = 3,
    MAXLENGTH = 4
}

/**
 * A class that can act as base class for
 * components that require error encoding.
 */
export class FormErrorEncoder {

    public readonly FormErrorCode = FormErrorCode;

    public encode(errors: any): number {
        if (errors.required) {
            return FormErrorCode.REQUIRED;
        } else if (errors.pattern) {
            return FormErrorCode.PATTERN;
        } else if (errors.minlength) {
            return FormErrorCode.MINLENGTH;
        } else if (errors.maxlength) {
            return FormErrorCode.MAXLENGTH;
        } else {
            return FormErrorCode.NONE;
        }
    }
}

