import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Observable, BehaviorSubject } from 'rxjs';

import { ResourceService } from '../services/resource.service';
import { PlaceService } from '../services/place.service';
import { Place, PlacePage } from '../api';
import { PaginationHandler } from '../utils/pagination';
import { TableHandler, TableColumn } from '../utils/tables';


interface PlaceWrapper {
  isSelected: boolean;
  place: Place;
}

@Component({
    selector: 'app-places-dialog',
    templateUrl: 'places-dialog.component.html',
    styles: [
      '.data-selected { background-color: #0085ed40; border-radius: 25px; }'
    ]
})
export class PlacesDialogComponent implements OnInit, OnDestroy {

  private static COLUMNS: TableColumn[] = [
    { name: 'name', dropPriority: 0, width: 130 }
  ];

  @ViewChild('table', { read: ElementRef, static: true }) table: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  placesDataSource: MatTableDataSource<PlaceWrapper>;
  loading: BehaviorSubject<boolean>;
  order: BehaviorSubject<String>;
  selectedPlaces: Place[];
  
  private pageHandler: PaginationHandler<Place, PlacePage>;
  private tableHandler: TableHandler;

  constructor(
    public dialog: MatDialogRef<PlacesDialogComponent, Place[]>,
    @Inject(MAT_DIALOG_DATA) private data: Place[],
    public res: ResourceService,
    private placeService: PlaceService
  ) { }

  public static open<T>(dialog: MatDialog, d: T[], w?: string, maxH?: string)
   : Observable<Place[]> {
    if (w == null) w = '500px';
    if (maxH == null) maxH = '700px';
    const ref = dialog.open(PlacesDialogComponent, {
        data: d,
        width: w,
        maxHeight: maxH
    });
    return ref.afterClosed();
  }

  ngOnInit(): void {
    this.selectedPlaces = [];
    this.selectedPlaces.push(...this.data);
    this.placesDataSource = new MatTableDataSource([]);
    this.loading = this.placeService.loading;
    this.order = this.placeService.order;
    this.tableHandler = new TableHandler(this.table, PlacesDialogComponent.COLUMNS);
    this.pageHandler = new PaginationHandler(this.paginator, this.placeService);
    
    this.placeService.page.next({ offset: 0, size: 6, total: 0, elements: [] } as PlacePage);
    this.placeService.page.subscribe((page: PlacePage) => {
      const tmp: PlaceWrapper[] = [];
      page.elements.forEach((place: Place) => {
        tmp.push({  
          isSelected: this.selectedPlaces.some((p: Place) => p.id === place.id), 
          place: place
        })
      });
      this.placesDataSource.data = tmp;
    })
    this.placeService.reload();
  }

  ngOnDestroy(): void {
    this.placeService.page.next({ offset: 0, size: 20, total: 0, elements: [] } as PlacePage);
    this.pageHandler.destroy();
  }

  onPlaceSelected(wrapper: PlaceWrapper): void {
    if (!wrapper.isSelected)
      this.selectedPlaces.push(wrapper.place);
    else
      this.selectedPlaces = this.selectedPlaces.filter((p: Place) => p.id !== wrapper.place.id);
    wrapper.isSelected = !wrapper.isSelected;
  }

  onClose(): void {
    this.dialog.close(this.selectedPlaces);
  }

  getStyle(column: string): any {
    return this.tableHandler.getColumnStyle(column);
  }

  getColumns(): string[] {
    return this.tableHandler.getDisplayColumns();
  }

  getWorkplaceName(place: Place): string {
    return (place.nickname != null && place.nickname.length > 0)? place.nickname : place.address;
  }

  getOrderName(order: String): string {
    return this.res.strings('places_order_value_' + order.toLowerCase());
  }

  getOrders(): string[] {
    return [ 'TIME', 'IMPORTANCE', 'DURATION', 'FREQUENCY', 'IDENTIFIER' ];
  }

}
