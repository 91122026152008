import { Notice, Message } from '../api';
import { Input, Component } from '@angular/core';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-notice-card',
    templateUrl: './notice-card.component.html'
  })
  export class NoticeCardComponent  {

    /**
     * The notice bound to the card.
     */
    @Input() notice: Notice;

    /**
     * The preferred language to display.
     */
    @Input() language: string;

    /** The label for the modificatin time. */
    @Input() modifiedLabel: string;

    /** The label for the creation time. */
    @Input() createdLabel: string;

    constructor(public res: ResourceService) {
        this.modifiedLabel = res.strings('notice_card_creation');
        this.createdLabel = res.strings('notice_card_modification');
    }

    getTimeLabel(): string {
        if (this.notice.modificationTime === this.notice.creationTime) {
            return this.createdLabel;
        } else {
            return this.modifiedLabel;
        }
    }

    getTitle(): string {
        const m = this.getMessage();
        if (m == null) {
            return '';
        } else {
            return m.title;
        }
    }

    getBody(): string {
        const m = this.getMessage();
        if (m == null) {
            return '';
        } else {
            return m.body;
        }
    }

    private getMessage(): Message {
        for (const m of this.notice.messages) {
            if (m.language === this.language) {
                return m;
            }
        }
        return null;
    }

}

