import { Component, OnInit, ElementRef, ViewChild, DoCheck } from '@angular/core';
import * as L from 'leaflet';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-tile-map',
    templateUrl: './tile-map.component.html'
})
export class TileMapComponent implements OnInit, DoCheck {

    private static readonly DEFAULT_LOCATION = new L.LatLng(51.461663, 7.016645);
    private static readonly DEFAULT_ZOOM = 13;
    private static readonly DEFAULT_TILE_URL = 'https://www.maptology.com/carta/tile/{z}/{x}/{y}.png';
    private static readonly DEFAULT_ICON_SCALE = 0.2;


    private static readonly DEFAULT_PIN_URL = 'assets/markers/marker_pin.png';
    private static readonly DEFAULT_NORMAL_URL = 'assets/markers/marker_normal.png';
    private static readonly DEFAULT_SELECTED_URL = 'assets/markers/marker_selected.png';
    private static readonly DEFAULT_SHADOW_URL = 'assets/markers/marker_shadow.png';

    @ViewChild('map', { read: ElementRef, static: true }) mapElement: ElementRef;

    map: L.Map;
    layer: L.TileLayer;
    attribution: L.Control;

    readonly iconNormal: L.Icon;
    readonly iconSelected: L.Icon;
    readonly iconPin: L.Icon;

    constructor(public res: ResourceService) {
        this.iconNormal = this.createMarkerIcon(TileMapComponent.DEFAULT_NORMAL_URL);
        this.iconSelected = this.createMarkerIcon(TileMapComponent.DEFAULT_NORMAL_URL);
        this.iconPin = this.createPinIcon(TileMapComponent.DEFAULT_PIN_URL);

    }

    ngDoCheck() {
        this.map.removeControl(this.attribution);
        this.attribution = L.control.attribution({ position: 'bottomleft' });
        this.map.addControl(this.attribution);
        this.map.invalidateSize();
    }

    getWidth(): number {
        if (this.mapElement) {
            return this.mapElement.nativeElement.clientWidth;
        } else {
            return 0;
        }
    }

    getHeight(): number {
        if (this.mapElement) {
            return this.mapElement.nativeElement.clientHeight;
        } else {
            return 0;
        }
    }

    ngOnInit() {
        const object = this;
        this.map = new L.Map('map', { attributionControl: false });
        this.map.setView(TileMapComponent.DEFAULT_LOCATION, TileMapComponent.DEFAULT_ZOOM);
        this.attribution = L.control.attribution({ position: 'bottomleft' });
        this.map.addControl(this.attribution);
        this.layer = new L.TileLayer(TileMapComponent.DEFAULT_TILE_URL, {
          maxZoom: 18
        }).addTo(this.map);
        this.layer.getAttribution = function(): string {
            return object.res.strings('tile_map_attribution');
        };
    }

    private createMarkerIcon(url: string): L.Icon {
        const href = this.getBaseHref();
        return new L.Icon({
            iconUrl: href + url,
            shadowUrl: href + TileMapComponent.DEFAULT_SHADOW_URL,
            iconAnchor: [this.scale(100), this.scale(293)],
            iconSize: [this.scale(200), this.scale(293)],
            shadowAnchor: [this.scale(140), this.scale(330)],
            shadowSize: [this.scale(280), this.scale(379)]
        });
    }

    private createPinIcon(url: string): L.Icon {
        const href = this.getBaseHref();
        return new L.Icon({
            iconUrl: href + url,
            iconAnchor: [this.scale(100), this.scale(100)],
            iconSize: [this.scale(200), this.scale(200)],
        });
    }

    private getBaseHref(): string {
        const bases = document.getElementsByTagName('base');
        let baseHref = null;
        if (bases.length > 0) { baseHref = bases[0].href; }
        if (baseHref == null) { baseHref = '/'; }
        return baseHref;
    }

    private scale(v: number): number {
        return v * TileMapComponent.DEFAULT_ICON_SCALE;
    }



}
