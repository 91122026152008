import { Component, OnInit, HostListener, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Time, formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DateRange } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { ResourceService } from '../services/resource.service';
import { DeviceService } from '../services/device.service';
import { TimeSheetService, TimeSheetData, Grouping } from '../services/time-sheet.service';
import { PlacesDialogComponent } from '../widgets/places-dialog.component';
import { Device, Place } from '../api';
import { MessageDialogComponent } from '../widgets/message-dialog.component';


/**
 * A view that offers the functionality to generate a timesheet by filtering on the
 * time period and the places a device has been.
 * It offers three modes:
 * 1. Default -> Shows the time entries per day processed in terms of legal working requirements.
 *               It also offers the functionality of defining a filter at which point the
 *               absence from a defined "working place" should be booked as a break.
 * 2. Summed  -> Shows the time entries per day without any processing at all.
 * 3. By Day  -> Shows every single time entry. Due to that a single day can occure frequently.
 *
 * @author Adriano
 */
@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styles: [
    `mat-list .hrz-margin { width: 100%; margin-left: 32px; margin-right: 32px; }`,
    `mat-list .vrt-margin { margin-top: 20px; margin-bottom: 20px; }`,
    `mat-list .divider { margin: 16px; }`,
    `.main-table { width: 100%; }`,
    `.main-table .mat-column-date { width: 16% !important; }`,
    `.main-table .mat-column-to-time, .mat-column-from-time { width: 7% !important; }`,
    `.main-table .mat-column-time { width: 10% !important; }`,
    `.main-table .mat-column-device { width: 20% !important; }`,
    `.main-table .mat-column-place { width: 40% !important; }`
  ],
})
export class TimeSheetComponent implements OnInit, OnDestroy, AfterViewInit {

  private static readonly LAYOUT_BREAKPOINT = 960;
  static readonly normalDisplayCol = ['date', 'from-time', 'to-time', 'time', 'device', 'place'];
  static readonly compactDisplayCol = ['date', 'time'];
  readonly breakTimeOptions = [5, 10, 15, 20, 30, 45, 60, 90, 120, 150, 180];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  compact = false;
  displayedColumns = TimeSheetComponent.normalDisplayCol;

  workPlaces: Place[];
  devices: Device[];
  timeSheetDataSource: MatTableDataSource<TimeSheetData>;

  private subscriptions: Subscription;

  constructor(
    public res: ResourceService,
    private deviceService: DeviceService,
    public timeSheetService: TimeSheetService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.workPlaces = this.timeSheetService.workPlaces;
    this.timeSheetDataSource = this.timeSheetService;
    this.updateDisplayedColumns(window.innerWidth);

    this.subscriptions = this.deviceService.connect(null)
        .subscribe((devices: Device[]) => this.devices = devices);

    this.deviceService.reload();
  }

  ngAfterViewInit(): void {
    this.timeSheetService.setup(this.paginator);
  }

  ngOnDestroy(): void {
    this.deviceService.disconnect(null);
    this.timeSheetService.reset();
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateDisplayedColumns((event.target as Window).innerWidth);
  }

  updateTimeSheetTable(): void {
    if (!this.timeSheetService.fetching$.getValue()) {
      this.timeSheetService.fetchData();
    }
  }

  exportTimeSheet(type: string): void {
    if (!this.timeSheetService.exportTimeSheet(type)) {
      this.openMessageDialog(
        this.res.strings('time_sheet_export_error_msg_title'),
        this.res.strings('time_sheet_export_error_msg')
      );
    }
  }

  showTimeSheetRowInfo(data: TimeSheetData): void {
    if (!this.compact) { return; }

    const res_values = [
      'time_sheet_table_date', 'time_sheet_table_from', 'time_sheet_table_to',
      'time_sheet_table_working_time', 'time_sheet_table_place', 'time_sheet_table_device'
    ];
    const data_values = [
      `${this.getDate(data)}`, `${formatDate(data.beginDate, 'H:mm', 'de')}`,
      `${formatDate(data.endDate, 'H:mm', 'de')}`, `${this.getWorkingTime(data.duration)}`,
      `${this.getWorkplaceString(data)}`, `${this.getWorkplaceString(data)}`
    ];
    let msg = '';
    res_values.forEach((val: string, idx: number, _) => msg += `${this.res.strings(val)}: ${data_values[idx]}; `);
    this.openMessageDialog(this.res.strings('time_sheet_table_row_dialog_title'), msg.slice(0, msg.length - 2));
  }

  openWorkPlacesDialog(): void {
    const dialogSubscription = PlacesDialogComponent.open(this.dialog, this.timeSheetService.workPlaces)
      .subscribe((selectedPlaces: Place[]) => {
        this.workPlaces = selectedPlaces;
        this.timeSheetService.workPlaces = selectedPlaces;
        dialogSubscription.unsubscribe();
      });
  }

  getWorkplaceName(place: Place): string {
    return this.timeSheetService.getWorkplaceName(place);
  }

  getWorkplaceString(data: TimeSheetData): string {
    return this.timeSheetService.getWorkplaceString(data);
  }

  getDeviceNameString(data: TimeSheetData): string {
    let res = '';
    if (data.deviceId) {
      const device = this.devices.find(d => d.id === data.deviceId);
      res = device.model;
    } else {
      data.devicesSet.forEach(id => {
        const device = this.devices.find(place => place.id === id);
        res += device.model + '; ';
      });
      res = res.slice(0, res.length - 2);
    }
    return res;
  }

  getWorkingTime(time: Time): string {
    let res = '';
    if (time.hours !== 0) { res += String(time.hours) + ' h '; }
    if (time.minutes !== 0) { res += String(time.minutes) + ' m'; }
    if (res === '') { res = '-'; }
    return res;
  }

  deleteWorkPlaceEntry(workPlace: Place): void {
    this.workPlaces = this.workPlaces.filter(place => workPlace !== place);
    this.timeSheetService.workPlaces = this.workPlaces;
  }

  clearWorkPlaceList(): void {
    this.workPlaces = [];
    this.timeSheetService.workPlaces = [];
  }

  getDate(data: TimeSheetData): string {
    return this.timeSheetService.getDate(data);
  }

  private openMessageDialog(title: string, message: string): void {
    const dialogSubscription = MessageDialogComponent.open(this.dialog, { title, message })
      .subscribe(() => dialogSubscription.unsubscribe());
  }

  private updateDisplayedColumns(width: number): void {
    if (width < TimeSheetComponent.LAYOUT_BREAKPOINT && !this.compact) {
      this.compact = true;
      this.displayedColumns = TimeSheetComponent.compactDisplayCol;
      this.timeSheetService.grouped = Grouping.BY_DAY_ACC;
    } else if (width >= TimeSheetComponent.LAYOUT_BREAKPOINT && this.compact) {
      this.compact = false;
      this.displayedColumns = TimeSheetComponent.normalDisplayCol;
    }
  }

  setStart(date: Date) {
    this.timeSheetService.dateRange = new DateRange<Date>(date, this.timeSheetService.dateRange.end);
  }

  setEnd(date: Date) {
    this.timeSheetService.dateRange = new DateRange<Date>(this.timeSheetService.dateRange.start, date);
  }
}
