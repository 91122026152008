import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService, UserRole } from '../services/user.service';
import { ResourceService } from '../services/resource.service';

/**
 * Shows the contents of the navigation drawer.
 */
@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html'
})
export class DrawerComponent implements OnInit, OnDestroy {

    admin = false;

    private subscription: Subscription;

    constructor(public res: ResourceService, private userService: UserService) { }

    ngOnInit(): void {
        this.subscription = this.userService.state.subscribe((state) => {
            this.admin = this.userService.hasRole(UserRole.Admin);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}

