import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * A console that can be used to log debug output
 * throughout the application.
 */
@Injectable()
export class ConsoleService {

  private static LOG = false;

  private data: string[] = [];
  public messages: Subject<string[]> = new Subject<string[]>();

  constructor() { }

  log(message: string) {
    if (!ConsoleService.LOG) { return; }
    const date: Date = new Date();
    message = date.toISOString() + ' ' + message;
    console.log(message);
    this.data.push(message);
    this.messages.next(this.data);
  }

  size() {
    return this.data.length;
  }

  clear() {
    this.data = [];
    this.messages.next(this.data);
  }

}
