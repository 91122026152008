<mat-nav-list class="mat-typography" style="display: flex; flex: 1 1 auto; flex-direction: column;">
    <h3 mat-subheader>{{res.strings('drawer_navigation')}}</h3>
    <a mat-list-item routerLink="/notices">
        <mat-icon class="icon-button">message</mat-icon>
        <span class="mat-button">{{ res.strings('notices_title') }}</span>
    </a>
    <a mat-list-item routerLink="/privileges">
        <mat-icon class="icon-button">share</mat-icon>
        <span class="mat-button">{{ res.strings('privileges_title') }}</span>
    </a>
    <a mat-list-item routerLink="/timesheet">
        <mat-icon class="icon-button">access_time_icon</mat-icon>
        <span class="mat-button">{{ res.strings('time_sheet_title') }}</span>
    </a>
    <a mat-list-item routerLink="/devices">
        <mat-icon class="icon-button">devices</mat-icon>
        <span class="mat-button">{{ res.strings('devices_title') }}</span>
    </a>
    <a mat-list-item routerLink="/places">
        <mat-icon class="icon-button">pie_chart_outlined</mat-icon>
        <span class="mat-button">{{ res.strings('places_title') }}</span>
    </a>
    <a mat-list-item routerLink="/export">
        <mat-icon class="icon-button">cloud_download</mat-icon>
        <span class="mat-button">{{ res.strings('export_title') }}</span>
    </a>
    <h3 mat-subheader>{{res.strings('drawer_settings')}}</h3>
    <a mat-list-item routerLink="/profile">
        <mat-icon class="icon-button">perm_identity</mat-icon>
        <span class="mat-button">{{ res.strings('user_title') }}</span>
    </a>
    <ng-container *ngIf="admin">
        <h3 mat-subheader>{{res.strings('drawer_management')}}</h3>
        <a mat-list-item routerLink="/manage/system">
            <mat-icon class="icon-button">equalizer</mat-icon>
            <span class="mat-button">{{ res.strings('manage_system_title') }}</span>
        </a>
        <a mat-list-item routerLink="/manage/logs">
            <mat-icon class="icon-button">subject</mat-icon>
            <span class="mat-button">{{ res.strings('manage_logs_title') }}</span>
        </a>
        <a mat-list-item routerLink="/manage/users">
            <mat-icon class="icon-button">people</mat-icon>
            <span class="mat-button">{{ res.strings('manage_users_title') }}</span>
        </a>
        <a mat-list-item routerLink="/manage/notices">
            <mat-icon class="icon-button">forum</mat-icon>
            <span class="mat-button">{{ res.strings('manage_notices_title') }}</span>
        </a>
    </ng-container>
</mat-nav-list>