import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LogMessage } from '../api';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-log-dialog',
    templateUrl: 'log-dialog.component.html',
  })
  export class LogDialogComponent {

    message: LogMessage;

    public static open(dialog: MatDialog, d: LogMessage, w?: string): Observable<any> {
      const ref = dialog.open(LogDialogComponent, {
          width: w,
          data: d
      });
      return ref.afterClosed();
    }

    constructor(public dialog: MatDialogRef<LogDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: LogMessage,
      public res: ResourceService) {
        this.message = data;
      }

    onClose(): void {
      this.dialog.close();
    }

  }
