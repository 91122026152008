<app-content-layout [layoutClass]="'table-page'">
    <app-content-header>
        <div class="header-title">
            <mat-icon>devices</mat-icon>
            <span>{{ res.strings('devices_title') }}</span>
        </div>
    </app-content-header>

    <main class="mat-elevation-z2">
        <ng-container *ngIf="loading.getValue()">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </ng-container>
        <mat-table #table [dataSource]="dataSource">

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('id')"> {{ res.strings('devices_id') }} </mat-header-cell>
                <mat-cell *matCellDef="let i = index" [ngStyle]="getStyle('id')"> {{ getNumber(i) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="os">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('os')"> {{ res.strings('devices_os') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('os')">
                    <mat-icon class="icon-button">{{getOsIcon(element.os)}}</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="manufacturer">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('manufacturer')"> {{ res.strings('devices_manufacturer') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('manufacturer')"> {{element.manufacturer}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('model')"> {{ res.strings('devices_model') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('model')"> {{element.model}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('creationTime')"> {{ res.strings('devices_creation') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('creationTime')"> {{element.creationTime | date:'short' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="modificationTime">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('modificationTime')" > {{ res.strings('devices_modification') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('modificationTime')" > {{element.modificationTime | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('actions')" class="action-cell"> {{ res.strings('devices_actions') }} </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="getStyle('actions')" class="action-cell">
                    <button mat-icon-button [matTooltip]="res.strings('devices_location_tooltip')" [routerLink]="element.id + '/location'" [disabled]="loading.getValue()">
                        <mat-icon>my_location</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="res.strings('devices_history_tooltip')" [routerLink]="element.id + '/visits'" [disabled]="loading.getValue()">
                        <mat-icon>bubble_chart</mat-icon>
                    </button>
                    <button *ngIf="canShowPositions()" mat-icon-button [matTooltip]="res.strings('devices_position_tooltip')" [routerLink]="element.id + '/positions'" [disabled]="loading.getValue()">
                        <mat-icon>linear_scale</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="res.strings('devices_delete_tooltip')" (click)="onDelete(element.id)" [disabled]="loading.getValue()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumns();"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
        </mat-paginator>
    </main>
</app-content-layout>