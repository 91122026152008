import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notice, Message } from '../api';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Snackbars } from '../utils/snackbars';
import { FormErrorEncoder } from '../utils/formcodes';
import { ManageNoticeService } from '../services/manage-notice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService, Language } from '../services/resource.service';

@Component({
  selector: 'app-edit-notice',
  templateUrl: 'edit-notice.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: ['.mat-tab-label { width: 65px !important; min-width: 0px !important } ']
})
export class EditNoticeComponent extends FormErrorEncoder implements OnInit, OnDestroy {

  readonly languages: Language[];

  readonly loading: BehaviorSubject<boolean>;
  id: string;
  notice: Notice;
  private loadSubscription: Subscription;
  messages: Map<string, Message> = new Map();
  expirationDate: Date;
  expirationTime: string;

  constructor(
    public res: ResourceService,
    private mangeNoticeService: ManageNoticeService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog) {
    super();
    this.loading = mangeNoticeService.loading;
    this.languages = res.languages;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.isNew()) {
      this.loadSubscription = this.mangeNoticeService.getNotice(+this.id).subscribe((notice) => {
        if (notice == null) {
          Snackbars.showError(this.snackbar, this.res.strings('edit_notice_load_error'));
          this.router.navigate(['/manage/notices']);
        } else {
          this.setNotice(notice);
        }
      });
    } else {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date.setMonth(date.getMonth() + 12);
      this.setNotice({
        announced: false,
        creationTime: 0,
        deleted: false,
        expirationTime: date.getTime(),
        id: 0,
        maximumVersion: 0,
        minimumVersion: 0,
        messages: [],
        modificationTime: 0,
        published: false
      });
    }
  }

  isNew(): boolean {
    return +this.id === 0;
  }

  ngOnDestroy() {
    if (this.loadSubscription != null) { this.loadSubscription.unsubscribe(); }
  }

  setNotice(notice: Notice): void {
    if (notice.id !== +this.id) {
      this.router.navigate(['/manage/notices/' + notice.id]);
    }
    this.notice = notice;
    this.messages = new Map<string, Message>();
    for (const m of notice.messages) {
      this.messages.set(m.language, m);
    }
    const date = new Date(notice.expirationTime);
    this.expirationDate = date;
    const min = date.getMinutes();
    this.expirationTime = ((min < 10) ? '0' : '') + date.getHours() + ':' + ((min < 10) ? '0' : '') + min;
  }

  getOtherLanguages(): Language[] {
    const missing: Language[] = [];
    for (const lang of this.languages) {
      if (!this.messages.has(lang.value)) {
        missing.push(lang);
      }
    }
    return missing;
  }

  hasAllLanguages(): boolean {
    for (const lang of this.languages) {
      if (!this.messages.has(lang.value)) { return false; }
    }
    return true;
  }

  onAddLanguage(lang: string): void {
    let message: Message = { language: lang, title: '', body: '' } as Message;
    for (const m of this.notice.messages) {
      if (m.language === lang) {
        message = m;
      }
    }
    this.messages.set(lang, message);
  }

  onDeleteLanguage(lang: string): void {
    this.messages.delete(lang);
  }

  onSave(): void {
    this.notice.messages = [];
    this.messages.forEach((m: Message, k: string) => {
      this.notice.messages.push(m);
    });
    if (this.notice.minimumVersion == null) { this.notice.minimumVersion = 0; }
    if (this.notice.maximumVersion == null) { this.notice.maximumVersion = 0; }
    const exp = new Date(this.expirationDate);
    const time = this.getExpirationTime();
    exp.setHours(time.h);
    exp.setMinutes(time.m);
    exp.setSeconds(0);
    exp.setMilliseconds(0);
    this.notice.expirationTime = exp.getTime();
    this.updateNotice(this.notice);
  }

  private getExpirationTime(): { h: number, m: number } {
    const result = { h: 0, m: 0 };
    if (this.expirationTime == null || this.expirationTime.indexOf(':') === -1) {
      this.expirationTime = '0:00';
    }
    const idx = this.expirationTime.indexOf(':');
    result.h = +this.expirationTime.substring(0, idx);
    result.m = +this.expirationTime.substring(idx + 1);
    return result;
  }


  private updateNotice(notice: Notice) {
    this.mangeNoticeService.setNotice(notice).subscribe((result) => {
      if (result != null) {
        Snackbars.showMessage(this.snackbar, this.res.strings('edit_notice_save_success'), Snackbars.DURATION_SHORT);
        this.setNotice(result);
      } else {
        Snackbars.showError(this.snackbar, this.res.strings('edit_notice_save_error'));
      }
    });

  }

}
