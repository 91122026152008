<div style="min-height:100%;width:100%;display:table">
    <div style="display:table-cell;vertical-align: middle">
        <mat-grid-list [cols]="columns" gutterSize="10" rowHeight="300" class="grid-layout">
            <mat-grid-tile rowspan="2">
                <mat-card class="mat-typography" class="register-card">
                    <mat-card-title-group>
                        <mat-card-title>{{ res.strings('register_title') }}</mat-card-title>
                        <mat-card-subtitle>{{ res.strings('register_subtitle') }}</mat-card-subtitle>
                    </mat-card-title-group>
                    <mat-card-content>
                        <form #registerForm="ngForm">
                            <app-name-field [name]="registration.name"></app-name-field>
                            <app-email-field [email]="registration.email"></app-email-field>
                            <app-login-field [login]="registration.login"></app-login-field>
                            <app-password-field [password]="registration.password" (keyup.enter)="registerForm.valid && onRegister()"></app-password-field>
                            <mat-checkbox class="long-checkbox" [(ngModel)]="termsAccepted" name="termsAccepted">
                                {{ res.strings('register_terms_pre') }}
                                <a class="register-link" href="https://www.maptology.com/privacy-policy" target="_blank">
                                    {{ res.strings('register_terms_policy') }}
                                </a> 
                                {{ res.strings('register_terms_mid') }}
                                <a class="register-link" href="https://www.maptology.com/terms-of-service" target="_blank">
                                    {{ res.strings('register_terms_service') }}
                                </a> 
                                {{ res.strings('register_terms_end') }}                                
                            </mat-checkbox>
                        </form>
                    </mat-card-content>
                    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
                        <ng-container *ngIf="idle | async as submittable;else progressBar">
                            <button #registerButton mat-raised-button color="accent" [disabled]="!submittable || registerForm.invalid" (click)="onRegister()">
                                <mat-icon class="icon-button">arrow_forward</mat-icon>
                                <span>{{ res.strings('button_register') }}</span>
                            </button>
                        </ng-container>
                        <ng-template #progressBar>
                            <mat-spinner [diameter]="36"></mat-spinner>
                        </ng-template>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="mat-typography" style="flex: 1; max-width:500px;min-height:180px;margin:10px;">
                    <mat-card-title>{{ res.strings('register_already_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('register_already_subtitle') }}</mat-card-subtitle>
                    <mat-card-content class="caption" style="flex:1">
                        <p>{{ res.strings('register_already_paragraph') }}</p>
                    </mat-card-content>
                    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center" style="min-height:60px">
                        <button mat-raised-button color="primary" routerLink="/login">
                            <mat-icon class="icon-button">lock_open</mat-icon>
                            <span>{{ res.strings('button_login') }}</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="mat-typography" style="flex: 1; max-width:500px;min-height:180px;margin:10px;">
                    <mat-card-title>{{ res.strings('register_why_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('register_why_subtitle') }}</mat-card-subtitle>
                    <mat-card-content class="caption" style="flex:1">
                        <p>{{ res.strings('register_why_paragraph') }}</p>
                    </mat-card-content>
                    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center" style="min-height:60px">
                        <a href="https://www.maptology.com">
                            <button mat-raised-button color="primary">
                                <mat-icon class="icon-button">help_outline</mat-icon>
                                <span>{{ res.strings('register_why_moreinfo') }}</span>
                            </button>
                        </a>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>