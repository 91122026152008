<app-marker-map>
    <mat-accordion class="sidebar">
        <mat-expansion-panel class="mat-typography" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-top:10px">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">place</mat-icon>{{ res.strings('visits_place') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="place | async as p">
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_nickname') }}</span>
                    <span class="detail-value">{{ p.nickname }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_address') }}</span>
                    <span class="detail-value">{{ p.address }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_longitude') }}</span>
                    <span class="detail-value" style="font-family: Roboto Mono">{{ p.coordinate.longitude | number:'1.5-5' }}</span>
                </div>
                <div class="detail-row">
                    <span class="detail-label">{{ res.strings('visits_latitude') }}</span>
                    <span class="detail-value" style="font-family: Roboto Mono">{{ p.coordinate.latitude | number:'1.5-5' }}</span>
                </div>
            </ng-container>
            <mat-action-row fxFlexLayout="row" fxLayoutAlign="space-around center">
                <ng-container *ngIf="!loading.getValue();else progressBarDevice">
                    <button mat-raised-button color="primary" (click)="reloadPlace()">
                        <mat-icon class="icon-button">update</mat-icon>
                        <span>{{ res.strings('button_update') }}</span>
                    </button>
                </ng-container>
                <ng-template #progressBarDevice>
                    <mat-spinner [diameter]="36"></mat-spinner>
                </ng-template>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-typography" [ngClass]="{ 'narrow-panel': compact }" [ngStyle]="getPanelWidth()" style="margin-right:10px;margin-bottom:10px;padding:0px"
            [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="icon-button">beenhere</mat-icon>{{ res.strings('visits_visits') }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="loading.getValue() || deleting.getValue()">
                <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </ng-container>
            <mat-table #table [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> {{ res.strings('visits_id') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="begin">
                    <mat-header-cell *matHeaderCellDef> {{ res.strings('visits_begin') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.beginTime | date:'short' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="end">
                    <mat-header-cell *matHeaderCellDef> {{ res.strings('visits_end') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.endTime | date:'short' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="device">
                    <mat-header-cell *matHeaderCellDef> {{ res.strings('visits_device') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ getDevice(element.device) }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="visit">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('visit')"> {{ res.strings('visits_visit') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngStyle]="getStyle('visit')">
                        <mat-list>
                            <mat-list-item style="height:24px"> 
                                    <div class="narrow-label">{{ res.strings('visits_device') }}</div>
                                    <div class="narrow-value">{{ getDevice(element.device) }}</div>
                            </mat-list-item>
                            <mat-list-item style="height:24px"> 
                                    <div class="narrow-label">{{ res.strings('visits_from') }}</div>
                                    <div class="narrow-value">{{element.beginTime | date:'short' }}</div>                                
                            </mat-list-item>
                            <mat-list-item style="height:24px"> 
                                    <div class="narrow-label">{{ res.strings('visits_to') }}</div>
                                    <div class="narrow-value">{{element.endTime | date:'short' }}</div>
                            </mat-list-item>
                        </mat-list>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions" >
                    <mat-header-cell *matHeaderCellDef [ngStyle]="getStyle('actions')" class="action-cell"> {{ res.strings('visits_actions') }} </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngStyle]="getStyle('actions')" class="action-cell">
                        <button mat-icon-button [matTooltip]="res.strings('visits_device_tooltip')" [routerLink]="'/devices/' + element.device + '/visits'" [disabled]="loading.getValue() || deleting.getValue()">
                            <mat-icon>smartphone</mat-icon>
                        </button>
                        <button mat-icon-button [matTooltip]="res.strings('visits_delete_tooltip')" (click)="onDelete(element.id)" [disabled]="loading.getValue() || deleting.getValue()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="getColumns()" [ngClass]="{ 'narrow-header' : compact }"></mat-header-row>
                <mat-row *matRowDef="let row; columns: getColumns();" [ngClass]="{ 'narrow-row' : compact }"></mat-row>
            </mat-table>

            <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 10, 20, 50]" [showFirstLastButtons]="true">
            </mat-paginator>

        </mat-expansion-panel>
    </mat-accordion>
</app-marker-map>