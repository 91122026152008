import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import custom pages
import { InsideComponent } from './layouts/inside.component';
import { OutsideComponent } from './layouts/outside.component';
import { LoginComponent } from './views/login.component';
import { RegisterComponent } from './views/register.component';
import { ActivateComponent } from './views/activate.component';
import { ResetComponent } from './views/reset.component';
import { ChangeComponent } from './views/change.component';
import { DevicesComponent } from './views/devices.component';
import { DeviceLocationComponent } from './views/device-location.component';
import { PrivilegesComponent } from './views/privileges.component';
import { ShareComponent } from './views/share.component';
import { PlacesComponent } from './views/places.component';
import { UserComponent } from './views/user.component';
import { NoticesComponent } from './views/notices.component';
import { PlaceVisitsComponent } from './views/place-visits.component';
import { DeviceVisitsComponent } from './views/device-visits.component';
import { AuthGuard } from './guards/auth.guard';
import { ManageLogComponent } from './views/manage-log.component';
import { ManageSystemComponent } from './views/manage-system.component';
import { ManageUsersComponent } from './views/manage-users.component';
import { ManageNoticesComponent } from './views/manage-notices.component';
import { EditNoticeComponent } from './views/edit-notice.component';
import { ExportComponent } from './views/export.component';
import { DevicePositionsComponent } from './views/device-positions.component';
import { TimeSheetComponent } from "./views/time-sheet.component";

export const routes: Routes = [
  { path: '', canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
    // inside (logged in)
    { path: 'devices', component: InsideComponent, children: [
        { path: '', component: DevicesComponent },
        { path: ':id', children: [
          { path: 'location', component: DeviceLocationComponent },
          { path: 'visits', component: DeviceVisitsComponent },
          { path: 'positions', component: DevicePositionsComponent }
        ] },
      ]},
    { path: 'notices', component: InsideComponent, children: [
      { path: '', component: NoticesComponent }
    ] },
    { path: 'places', component: InsideComponent, children: [
      { path: '', component: PlacesComponent },
      { path: ':id', component: PlaceVisitsComponent }
    ] },
    { path: 'privileges', component: InsideComponent, children: [
      { path: '', component: PrivilegesComponent },
      { path: ':token', component: ShareComponent }
    ] },
    { path: 'profile', component: InsideComponent, children: [
      { path: '', component: UserComponent }
    ]},
    { path: 'export', component: InsideComponent, children: [
      { path: '', component: ExportComponent }
    ]},
    { path: 'timesheet', component: InsideComponent, children: [
      { path: '', component: TimeSheetComponent }
    ]},
    /// inside (admin)
    { path: 'manage', component: InsideComponent, children: [
      { path: 'logs', component: ManageLogComponent },
      { path: 'system', component: ManageSystemComponent },
      { path: 'users', component: ManageUsersComponent },
      { path: 'notices', children: [
        { path: '', component: ManageNoticesComponent },
        { path: ':id', component: EditNoticeComponent }
      ]}
    ]},
    // outside (logged out)
    { path: 'login', component: OutsideComponent, children: [{ path: '', component: LoginComponent }] },
    { path: 'register', component: OutsideComponent, children: [{ path: '', component: RegisterComponent }] },
    { path: 'reset', component: OutsideComponent, children: [
        { path: '', component: ResetComponent },
        { path: ':token', component: ChangeComponent }
      ] },
    { path: 'activate', component: OutsideComponent, children: [{ path: ':token', component: ActivateComponent }] },
    { path: 'shares', component: OutsideComponent, children: [{ path: ':token', component: ShareComponent }] },
    // catch all
    { path: '**', redirectTo: '/login' }
  ]}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
