/**
 * Maptology Service
 * The application programming interface of Maptology.
 *
 * OpenAPI spec version: 1.0.7
 * Contact: nes-admin@uni-due.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents information about a device.
 */
export interface DeviceInfo { 
    /**
     * The operating system.
     */
    os: DeviceInfo.OsEnum;
    /**
     * The manufacturer name, see android.os.Build.MANUFACTURER.
     */
    manufacturer: string;
    /**
     * The device name, see android.os.Build.DEVICE.
     */
    device: string;
    /**
     * The model name, see android.os.Build.MODEL.
     */
    model: string;
}
export namespace DeviceInfo {
    export type OsEnum = 'ANDROID' | 'IOS';
    export const OsEnum = {
        ANDROID: 'ANDROID' as OsEnum,
        IOS: 'IOS' as OsEnum
    };
}
