import { Injectable } from '@angular/core';
import { AbstractPageService } from '../utils/pagination';
import { DefaultService, User, UserPage } from '../api';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

/**
 * A service that handles users for admins.
 */
@Injectable()
export class ManageUserService extends AbstractPageService<User, UserPage> {

    /**
     * A search term that might be set to filter the
     * results.
     */
    private term: string = null;

    constructor(apiClientService: DefaultService) {
        super(apiClientService);
    }

    protected loadPage(offset: number, size: number): Observable<UserPage> {
        if (this.term != null) {
            return this.apiClientService.searchUsers(this.term, offset, size);
        } else {
            return this.apiClientService.getUsers(offset, size);
        }
    }

    setTerm(term: string): boolean {
        if (this.loading.getValue()) { return false; }
        if (term != null && term.trim().length > 0) {
            this.term = term.trim();
        } else {
            this.term = null;
        }
        this.getPage(0, this.page.getValue().size);
        return true;
    }

    getTerm(): string {
        return this.term;
    }


    setUser(user: User): Observable<boolean> {
        if (this.loading.getValue()) { return new BehaviorSubject<boolean>(false); }
        this.loading.next(true);
        const result = new Subject<boolean>();
        this.apiClientService.setUser(user.id, user).subscribe((response) => {
            result.next(true);
            this.loading.next(false);
            this.reload();
        }, (error) => {
            result.next(false);
            this.loading.next(false);
            this.reload();
        });
        return result;
    }

}
