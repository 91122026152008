import { Component, Input } from '@angular/core';
import { FormErrorEncoder } from '../utils/formcodes';
import { ResourceService } from '../services/resource.service';

/**
 * A validated field that enables a user to enter the
 * email. The email will be available through the
 * components name which also acts as an input.
 */
@Component({
  selector: 'app-email-field',
  templateUrl: './email-field.component.html'
})
export class EmailFieldComponent extends FormErrorEncoder {

  /**
   * The email address.
   */
  @Input() email: string;
  /**
   * Disables the field.
   */
  @Input() disabled: boolean;

  constructor(public res: ResourceService) {
    super();
  }
}
