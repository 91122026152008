import { Component, OnInit, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NoticeService } from '../services/notice.service';
import { NoticePage, Notice } from '../api';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-notices',
    templateUrl: './notices.component.html'
})
export class NoticesComponent implements OnInit {

    loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    page: BehaviorSubject<NoticePage>;
    columns = 1;

    constructor(public res: ResourceService, private noticeService: NoticeService) {
        this.page = noticeService.page;
        this.loading = noticeService.loading;
    }

    public getLanguage(): string {
        return this.res.strings('language');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.updateColumns((event.target as Window).innerWidth);
    }

    updateColumns(width: number): void {
        if (width < 800) {
            this.columns = 1;
        } else if (width < 1400) {
            this.columns = 2;
        } else if (width < 2000) {
            this.columns = 3;
        } else {
            this.columns = 4;
        }
    }

    ngOnInit() {
        this.noticeService.reload();
        this.updateColumns(window.innerWidth);
    }

    canShow(notice: Notice, language: String) {
        for (const m of notice.messages) {
            if (m.language === language) { return true; }
        }
        console.dir(notice);
        return false;
    }

}
