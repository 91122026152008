import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxGaugeModule } from 'ngx-gauge';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CdkTableModule } from '@angular/cdk/table';

import { LocationStrategy, PathLocationStrategy, registerLocaleData, DatePipe } from '@angular/common';
import LocaleDE from '@angular/common/locales/de';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { InsideComponent } from './layouts/inside.component';
import { OutsideComponent } from './layouts/outside.component';
import { FooterComponent } from './layouts/footer.component';
import { ContentLayoutComponent } from './layouts/content-layout.component';
import { ContentHeaderComponent } from './layouts/content-header.component';

import { ToolbarButtonComponent } from './widgets/toolbar-button.component';
import { LoginComponent } from './views/login.component';
import { DelayInterceptor } from './interceptors/delay.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DefaultService, Configuration} from './api';
import { ConsoleService } from './services/console.service';
import { UserService } from './services/user.service';
import { FormsModule } from '@angular/forms';
import { CredentialService } from './services/credential.service';
import { LoginFieldComponent } from './widgets/login-field.component';
import { UseParentFormDirective } from './directives/use-parent-form.directive';
import { PasswordFieldComponent } from './widgets/password-field.component';
import { NameFieldComponent } from './widgets/name-field.component';
import { EmailFieldComponent } from './widgets/email-field.component';
import { MessageDialogComponent } from './widgets/message-dialog.component';
import { RegisterComponent } from './views/register.component';
import { ActivateComponent } from './views/activate.component';
import { ResetComponent } from './views/reset.component';
import { ChangeComponent } from './views/change.component';
import { ProfileButtonComponent } from './widgets/profile-button.component';
import { DrawerComponent } from './layouts/drawer.component';
import { PrivilegeService } from './services/privilege.service';
import { DevicesComponent } from './views/devices.component';
import { GermanPaginatorProvider } from './providers/paginator.provider';
import { DeviceLocationComponent } from './views/device-location.component';
import { TileMapComponent } from './widgets/tile-map.component';
import { PrivilegesComponent } from './views/privileges.component';
import { DeviceService } from './services/device.service';
import { MarkerMapComponent } from './widgets/marker-map.component';
import { ShareComponent } from './views/share.component';
import { PlaceService } from './services/place.service';
import { PlacesComponent } from './views/places.component';
import { ConfirmationDialogComponent } from './widgets/confirmation-dialog.component';
import { ShareDialogComponent } from './widgets/share-dialog.component';
import { UserComponent } from './views/user.component';
import { NicknameDialogComponent } from './widgets/nickname-dialog.component';
import { NoticeCardComponent } from './widgets/notice-card.component';
import { NoticeService } from './services/notice.service';
import { NoticesComponent } from './views/notices.component';
import { PlaceVisitService } from './services/place-visit.service';
import { PlaceVisitsComponent } from './views/place-visits.component';
import { DeviceVisitService } from './services/device-visit.service';
import { DeviceVisitsComponent } from './views/device-visits.component';
import { AuthGuard } from './guards/auth.guard';
import { ManageLogComponent } from './views/manage-log.component';
import { ManageLogService } from './services/manage-log.service';
import { LogDialogComponent } from './widgets/log-dialog.component';
import { ManageSystemComponent } from './views/manage-system.component';
import { ManageUsersComponent } from './views/manage-users.component';
import { ManageUserService } from './services/manage-user.service';
import { ManageNoticeService } from './services/manage-notice.service';
import { ManageNoticesComponent } from './views/manage-notices.component';
import { EditNoticeComponent } from './views/edit-notice.component';
import { LanguageSelectComponent } from './widgets/language-select.component';
import { DecisionPanelComponent } from './widgets/decision-panel.component';
import { VersionService } from './services/version.service';
import { ResourceService } from './services/resource.service';
import { ExportComponent } from './views/export.component';
import { ExportService } from './services/export.service';
import { DevicePositionService } from './services/device-position.service';
import { DevicePositionsComponent } from './views/device-positions.component';
import { ChartComponent } from './widgets/chart.component';
import { TimeSheetComponent } from './views/time-sheet.component';
import { PlacesDialogComponent } from './widgets/places-dialog.component';


/**
 * Computes the api domain from the browser location.
 */
const apidomain = (): { http: string, ws: string } => {
  const host = document.location.hostname;
  const port = document.location.port;
  const protocol = document.location.protocol;
  let hostportpath = '';
  if (port.length > 0) {
    hostportpath = '://' + host + ':' + port + '/api';
  } else {
    hostportpath = '://' + host + '/api';
  }
  const secure = (protocol !== 'http:') ? 's' : '';
  return { http: 'http' + secure + hostportpath, ws: 'ws' + secure + hostportpath };
};

/**
 * Registers the locale data for DE.
 */
registerLocaleData(LocaleDE);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    InsideComponent,
    OutsideComponent,
    ExportComponent,
    ToolbarButtonComponent,
    ProfileButtonComponent,
    LanguageSelectComponent,
    DrawerComponent,
    LoginFieldComponent,
    PasswordFieldComponent,
    EmailFieldComponent,
    NameFieldComponent,
    DecisionPanelComponent,
    MessageDialogComponent,
    ConfirmationDialogComponent,
    TileMapComponent,
    MarkerMapComponent,
    LoginComponent,
    RegisterComponent,
    ShareComponent,
    ActivateComponent,
    ResetComponent,
    ChangeComponent,
    DevicesComponent,
    DeviceLocationComponent,
    PrivilegesComponent,
    PlacesComponent,
    ShareDialogComponent,
    UserComponent,
    NicknameDialogComponent,
    NoticeCardComponent,
    NoticesComponent,
    PlaceVisitsComponent,
    DeviceVisitsComponent,
    DevicePositionsComponent,
    ManageLogComponent,
    LogDialogComponent,
    ManageSystemComponent,
    ManageUsersComponent,
    ManageNoticesComponent,
    EditNoticeComponent,
    UseParentFormDirective,
    ChartComponent,
    TimeSheetComponent,
    PlacesDialogComponent,
    ContentLayoutComponent,
    ContentHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    NgxGaugeModule,
    HttpClientModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatSliderModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatGridListModule,
    MatMenuModule,
    CdkTableModule,
    AppRoutingModule,
    MatButtonToggleModule
  ],
  providers: [
    // injection token for http client
    { provide: Configuration, useValue: new Configuration({ basePath: apidomain().http }) },
    { provide: 'wsdomain', useValue: apidomain().ws },
    // location stategy for passing that with the router
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // add two global interceptors (one two slow down the server and one for authentication)
    // {provide: HTTP_INTERCEPTORS, useClass: DelayInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    // change the locale to DE and add custom paginator provider for tables
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MatPaginatorIntl, useClass: GermanPaginatorProvider },
    DatePipe,
    // add our application services (api access, logging, authentication)
    DefaultService, ConsoleService, AuthGuard,
    ResourceService, CredentialService, UserService, VersionService, ExportService,
    DeviceService, PrivilegeService, PlaceService, NoticeService, PlaceVisitService, DeviceVisitService, DevicePositionService,
    ManageLogService, ManageUserService, ManageNoticeService
  ],
  entryComponents: [
    MessageDialogComponent,
    ConfirmationDialogComponent,
    ShareDialogComponent,
    NicknameDialogComponent,
    LogDialogComponent,
    PlacesDialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
