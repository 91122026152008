<div style="min-height:100%;width:100%;display:flex">
    <div style="display:flex;flex: 1;justify-content: center;align-items: center">
            <mat-card class="mat-typography" style="width:400px">
                <mat-card-title-group>
                    <mat-card-title>{{ res.strings('reset_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('reset_subtitle') }}</mat-card-subtitle>
                </mat-card-title-group>
                <mat-card-content>
                    <form #resetForm="ngForm">
                        <app-login-field [login]="login" (keyup.enter)="onReset()"></app-login-field>
                    </form>
                </mat-card-content>
                <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
                    <ng-container *ngIf="idle | async as submittable;else progressBar">
                        <button #loginButton mat-raised-button color="accent"
                            [disabled]="!submittable || resetForm.invalid" (click)="onReset()">
                            <mat-icon class="icon-button">lock_open</mat-icon>
                            <span>{{ res.strings('button_reset') }}</span>
                        </button>
                    </ng-container>
                    <ng-template #progressBar>
                        <mat-spinner [diameter]="36"></mat-spinner>
                    </ng-template>
                </mat-card-actions>
            </mat-card>
    </div>
</div>