<ng-container *ngIf="loading | async;else content">
    <div fxFlexFill fxLayoutAlign="space-around center">
        <mat-spinner [diameter]="100"></mat-spinner>
    </div>
</ng-container>
<ng-template #content>
    <app-content-layout>
        <app-content-header [headerClass]="'header-transparent'">
            <div class="header-title">
                <mat-icon>message</mat-icon>
                <span>{{ res.strings('notices_title') }}</span>
            </div>
        </app-content-header>

        <main class="flex-row-wrap justify-space-evenly">
            <ng-container *ngFor="let n of page.getValue().elements">
                <ng-container *ngIf="canShow(n, getLanguage())">
                        <app-notice-card 
                            [language]="getLanguage()" 
                            [notice]="n" 
                            class="flex-notice-card">
                        </app-notice-card>
                </ng-container>
            </ng-container>
        </main>
    </app-content-layout>
</ng-template> 