<mat-card class="mat-elevation-z0">
    <mat-card-title><mat-icon style="font-size: 18px;">warning</mat-icon>{{title}}</mat-card-title>
    <mat-card-content style="margin-left:10px">{{message}}</mat-card-content>
    <mat-card-actions fxFlexLayout="row" fxLayoutAlign="space-around center">
        <button mat-raised-button color="accent" (click)="onAccept()" (keyup.enter)="onAccept();false">
            <mat-icon class="icon-button">done</mat-icon>
            <span>{{positive}}</span>
        </button>
        <button mat-raised-button color="primary" (click)="onClose()" (keyup.enter)="onClose(); false" cdkFocusInitial>
                <mat-icon class="icon-button">close</mat-icon>
                <span>{{negative}}</span>
            </button>
    </mat-card-actions>
</mat-card>