<div fxLayout="column" fxFlexFill>
    <mat-toolbar style="margin-top:20px;background:transparent">
        <mat-icon style="margin-left:20px;">message</mat-icon>
        <span style="margin-left: 10px">{{ res.strings(isNew()?"edit_notice_title_new":"edit_notice_title_edit") }}</span>
        <div flexLayout="row" fxLayoutAlign="end center" fxFlex="1 1 auto" fxLayoutGap="10px">
            <button mat-icon-button [matTooltip]="res.strings('edit_notice_save_tooltip')" (click)="onSave()" [disabled]="loading.getValue()">
                <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="res.strings('edit_notice_close_tooltip')" routerLink="/manage/notices" [disabled]="loading.getValue()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <ng-container *ngIf="loading | async;else content">
        <div fxFlex="row" fxLayoutAlign="space-around center">
            <mat-spinner [diameter]="100"></mat-spinner>
        </div>
    </ng-container>
    <ng-template #content>
        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-around start" fxLayoutAlign.lt-md="start stretch">
            <mat-card class="mat-typography" fxFlex="grow" style="margin:20px">
                <mat-card-title-group>
                    <mat-card-title>{{ res.strings('edit_notice_content_title') }}</mat-card-title>
                    <mat-card-subtitle>{{ res.strings('edit_notice_content_subtitle') }}</mat-card-subtitle>
                </mat-card-title-group>
                <mat-card-content>
                    <mat-tab-group>
                        <ng-container *ngFor="let lang of languages">
                            <ng-container *ngIf="messages.has(lang.value)">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <img [src]='"assets/flags/" + lang.value + ".svg"' height="10px" [matTooltip]="lang.title">
                                    </ng-template>
                                    <div style="padding-top: 40px">
                                        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="20px">
                                            <mat-form-field fxFlex="1 1 100%">
                                                <input matInput [placeholder]="res.strings('edit_notice_title_placeholder')" name="title" [(ngModel)]="messages.get(lang.value).title">
                                            </mat-form-field>
                                            <button mat-icon-button (click)="onDeleteLanguage(lang.value);">
                                                <mat-icon class="icon-button">delete</mat-icon>
                                            </button>
                                        </div>
                                        <mat-form-field style="width:100%">
                                            <textarea matInput [placeholder]="res.strings('edit_notice_body_placeholder')" name="body" [(ngModel)]="messages.get(lang.value).body" matTextareaAutosize
                                                matAutosizeMinRows="7"></textarea>
                                        </mat-form-field>
                                    </div>
                                </mat-tab>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!hasAllLanguages()">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon [matTooltip]="res.strings('edit_notice_add_language_tooltip')" class="icon-button">add_circle_outline</mat-icon>
                                </ng-template>
                                <div style="margin:30px" fxLayout="column" fxLayoutAlign="center center">
                                    <app-language-select #langSelect style="margin-top:50px" [languages]="getOtherLanguages()" [value]="getOtherLanguages()[0].value"></app-language-select>
                                    <button style="margin-top:50px" mat-raised-button color="primary" (click)="onAddLanguage(langSelect.value)">
                                        <mat-icon class="button-icon">playlist_add</mat-icon>
                                        <span>{{ res.strings('button_add') }}</span>
                                    </button>
                                </div>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-typography" style="margin:20px">
                <mat-card-title>{{ res.strings('edit_notice_settings_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('edit_notice_settings_subtitle') }}</mat-card-subtitle>
                <mat-card-content>
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="20px">
                            <mat-form-field fxFlex="1 1 130px">
                                <input type="time" matInput [placeholder]="res.strings('edit_notice_settings_time_placeholder')" name="expirationTime" [(ngModel)]="expirationTime">
                            </mat-form-field>
                            <mat-form-field fxFlex="1 1 130px">
                                <input style="padding-top:4px" name="expirationDate" matInput [matDatepicker]="picker" [(ngModel)]="expirationDate" [placeholder]="res.strings('edit_notice_settings_date_placeholder')"
                                    disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker disabled="false" #picker touchUi="true"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-around" fxFlexFill>

                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" style="min-height:140px">
                                <mat-checkbox [(ngModel)]="notice.published" style="min-width:140px">
                                    <span>{{ res.strings('edit_notice_settings_publish') }}</span>
                                </mat-checkbox>
                                <mat-checkbox [(ngModel)]="notice.announced" style="min-width:140px">
                                    <span>{{ res.strings('edit_notice_settings_announce') }}</span>
                                </mat-checkbox>
                            </div>

                            <div [fxHide]="notice.announced" style="min-width:134px"></div>
                            <div [fxShow]="notice.announced" fxLayout="column" fxLayoutAlign="right start">
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                                    <mat-icon color="accent">notifications_active</mat-icon>
                                    <mat-form-field style="width:100px !important">
                                        <input type="number" matInput [placeholder]="res.strings('edit_notice_settings_min_version')" min="0" name="minVersion" [(ngModel)]="notice.minimumVersion">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                                    <mat-icon color="accent">notifications_none</mat-icon>
                                    <mat-form-field style="width:100px !important">
                                        <input type="number" matInput [placeholder]="res.strings('edit_notice_settings_max_version')" min="0" name="maxVersion" [(ngModel)]="notice.maximumVersion">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer *ngIf="!isNew()" style="padding:10px" class="mat-typography">
                        <mat-divider></mat-divider>
                        <div style="flex:1;flex-wrap:nowrap;flex-direction:row;display:flex;margin-top:10px">
                            <div class="detail-label">{{ res.strings('edit_notice_creation') }}</div>
                            <div class="detail-value" style="flex: 1;text-align: right">{{ notice.creationTime | date:'short' }}</div>
                        </div>
                        <div style="flex:1;flex-wrap:nowrap;flex-direction:row;display:flex">
                            <span class="detail-label">{{ res.strings('edit_notice_modification') }}</span>
                            <div class="detail-value" style="flex: 1;text-align: right">{{ notice.modificationTime | date:'short' }}</div>
                        </div>
                </mat-card-footer>
            </mat-card>
        </div>
    </ng-template>

</div>