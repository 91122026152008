import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, DoCheck } from '@angular/core';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-decision-panel',
    templateUrl: 'decision-panel.component.html'
})
export class DecisionPanelComponent implements DoCheck {

    @ViewChild('panel', { read: ElementRef, static: true }) panel: ElementRef;

    @Input() positiveName: string;
    @Input() negativeName: string;
    @Input() positiveIcon = 'done';
    @Input() negativeIcon = 'close';
    @Input() processing = false;
    @Input() disabled = false;
    @Output() positive: EventEmitter<any> = new EventEmitter();
    @Output() negative: EventEmitter<any> = new EventEmitter();
    compact = false;

    constructor(public res: ResourceService) {
        this.positiveName = res.strings('button_ok');
        this.negativeName = res.strings('button_cancel');
    }

    onPositive() {
        this.positive.emit();
    }

    onNegative() {
        this.negative.emit();
    }

    ngDoCheck() {
        const width = this.panel.nativeElement.clientWidth;
        this.compact = (width < 250);
    }

}
