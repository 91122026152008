<div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="center start" fxLayoutGap="10px" style="margin: 20px;" >
        <mat-card *ngIf="showMessage" class="mat-typography" style="width:400px;min-height:230px">
            <mat-card-title-group>
                <mat-card-title>{{ res.strings('activate_title') }}</mat-card-title>
                <mat-card-subtitle>{{ res.strings('activate_subtitle') }}</mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content fxFlexLayout="row" fxLayoutAlign="space-around center">
                    <mat-spinner></mat-spinner>
            </mat-card-content>
        </mat-card>
</div>