import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LoginFieldComponent } from '../widgets/login-field.component';
import { HttpStatusCode } from '../utils/httpcodes';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { Snackbars } from '../utils/snackbars';
import { DefaultService } from '../api';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageDialogComponent } from '../widgets/message-dialog.component';
import { ResourceService } from '../services/resource.service';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html'
})
export class ResetComponent implements OnInit, OnDestroy {

    @ViewChild(LoginFieldComponent, { static: true }) loginField: LoginFieldComponent;
    @ViewChild('resetForm', { static: true }) resetForm: NgForm;

    readonly idle = new BehaviorSubject<boolean>(true);

    private resetSubscription: Subscription;

    login: string;

    constructor(public res: ResourceService, private userService: UserService,
        private apiClientService: DefaultService, private snackbar: MatSnackBar,
        private dialog: MatDialog, private router: Router) { }

    ngOnInit(): void {
        this.login = this.userService.getLogin();
    }

    onReset(): void {
        this.snackbar.dismiss();
        if (!this.idle || !this.resetForm.valid) { return; }
        this.setIdle(false);
        this.resetSubscription = this.apiClientService.resetUser(this.loginField.login).subscribe((result) => {
            this.setIdle(true);
            MessageDialogComponent.open(this.dialog, {
                title: this.res.strings('reset_confirm_title'),
                message: this.res.strings('reset_confirm_message'),
                button: this.res.strings('button_gotit') }).subscribe((close) => {
                this.router.navigate(['/']);
            });
        }, (error) => {
            this.setIdle(true);
            if (error instanceof HttpErrorResponse) {
                if (error.status === HttpStatusCode.NOT_FOUND) {
                    Snackbars.showError(this.snackbar, this.res.strings('reset_account_error') );
                } else {
                    Snackbars.showError(this.snackbar, this.res.strings('reset_network_error') );
                }
            } else {
                Snackbars.showError(this.snackbar, this.res.strings('reset_network_error') );
            }
        });
    }

    private setIdle(value: boolean) {
        this.loginField.disabled = !value;
        this.idle.next(value);
    }

    ngOnDestroy(): void {
        this.snackbar.dismiss();
        if (this.resetSubscription) { this.resetSubscription.unsubscribe(); }
    }
}
